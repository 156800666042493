import { IonModal } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { getWindowWidth, hideComponentLoader, showComponentLoader, updateLoaderProgress } from "../../../../../utils/UI_methods/global";
import { captureAndSaveScreenshot } from "../../../../../utils/cache/indexdb";
import { isPotraitMode } from "../../../../../utils/customizein3d/common";
import { openSummary } from "../../../../../utils/customizein3d/modules/UI_methods";
import { addToShortlist, configuration, debugScene, sceneBackgrounds, sprites } from "../../../../../utils/customizein3d/modules/customizein3d";
import { getTotalPrice } from '../../../../../utils/customizein3d/modules/helper';
import { enableTakeRenderMode } from "../../../../../utils/customizein3d/modules/render2d";
import { selectWardrobeLines } from "../../../../../utils/customizein3d/modules/wardrobe";
import { applicationConfig, getUser, waitFor } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Summary from "../../../../common/modules/customizein3d/common/Summary";
import Modal from "../../../../common/shared-components/Modals/Modal";
import Share from '../../../../common/shared-components/Share/Share';
import Button2 from "../../../ui-components/buttons/Button2";
import SceneSelector from "../common/SceneSelector";
import Gallery from "../controls/Gallery";
import BackgroundModeOptions from "../common/BackgroundModeOptions";
  
  
  const RightBottomActions: React.FC<{
    modulesConfigurtion:any
    currProduct:any
    isFloorplanMode:any
    isSpacePlannerMode:any
    isViewProductMode:boolean
    isElevateMode:boolean
  }> = (props) => {

    const[showFinalSummary,setShowFinalSummary]:any = useState(false)
    const[isOpenGallery,setIsOpenGallery]:any = useState(false)
    const[showShareDetails,setshowShareDetails] = useState(false)
    const[totalPrice,setTotalPrice] = useState("0")
    const[isShowBackground] = useState(applicationConfig?.isMasterLogin() || true)

    useEffect(()=>{
      let totalPrice = getTotalPrice(configuration.modules,applicationConfig)
      setTotalPrice(totalPrice)
      applicationConfig.setFunctionRef("customizein3d",{refreshGallery:refreshGallery})
    },[])   

    function refreshGallery(){
      setIsOpenGallery(true)
    }

    useEffect(()=>{
      if(showFinalSummary){
        // logger?.info("customizein3d","V-sum")
      }
    },[showFinalSummary])

 
  function shareAction(){
    let canvas = document.querySelector("canvas")
    if(canvas){
      let image = canvas.toDataURL("image/png");
      captureAndSaveScreenshot(image,"customizeIn3D",props.currProduct?.productName)
      setshowShareDetails(true)
    }
}

 
function shortListAction(){
  // props.setShowFinalSummary(false)
  addToShortlist()
}


function SummaryFooter() {
  return(
    <>
        <div className="modal-footer"style={{justifyContent:"space-between"}}>
            <div className="ion-padding-end">
                <div><span className="heading color-black font-medium">Total:</span>  <span className="heading color-black"><span className="color-danger rupee-sign">₹ </span> {totalPrice}</span></div>
            </div>

            <div>
                <button className="op-btn op-btn-info" onClick={shareAction}><i className="fas fa-share-alt-square"></i> Share</button>
                <button className="op-btn op-btn-info marginleft5" onClick={shortListAction}><i className="fas fa-cart-plus"></i> <span id="shortlistButtonName" className="color-white">Shortlist</span> </button>
            </div>

        </div>
    </>
  )
}

async function confirmAction() {
  showComponentLoader("mainLoader")
  updateLoaderProgress("mainLoader",1,1,"Please wait")
  openSummary()
  await waitFor(700)
  $("#confirmationWarningWrapper").removeClass("display-none")
  hideComponentLoader("mainLoader")
}



function takeRenderAction() {
  setIsOpenGallery(false)
  enableTakeRenderMode()
}



async function openBackgroundModelOptions() {   
  $("#backgroundModeOptions").fadeIn()
}



async function customizeBackground(){
  sceneBackgrounds.setBackgroundCustomizeMode()
  await waitFor(200)
  sprites.enable()
}


    return (
      <React.Fragment>
        <Modal
          classNames="project-action-modal"
          onDismiss={setShowFinalSummary}
          isOpen={showFinalSummary}
          heading="Summary"
          id=""
          FooterContent={SummaryFooter}
          Content={() => (
            <Summary
              configObj={props.modulesConfigurtion}
              currProduct={props.currProduct}
              configuration={configuration?.modules}
              setShowFinalSummary={setShowFinalSummary}
            />
          )}>

          </Modal>

        <BackgroundModeOptions/>

        <IonModal
          id=""
          cssClass="share-modal"
          onDidDismiss={() => setshowShareDetails(false)}
          isOpen={showShareDetails}>
          <Share
            configuration={configuration?.modules}
            areas={[{ area_name: "Default" }]}
            configObj={applicationConfig}
            moduleName="Customizein3D"
            closeModal={setshowShareDetails}
          />
        </IonModal>

        <Modal
          isOpen = {isOpenGallery}
          onDismiss = {()=>setIsOpenGallery(false)}
          classNames="medium-x-modal"
          id=""
          heading="Gallery"
          FooterContent={()=>(<></>)}
          Content={() => (
            <div className="overflow-y-scroll full-height-width padding10">
            <div className="marginBottom5">
              <Button2
                configObj={{id:"",classNames:"width"}}
                action={takeRenderAction}
                label={"Take New render"}
                fontAwesomeIcon={"fas fa-camera color-white"}
                iconPos="left"
                classNames={"shadow-medium no-border button-small bg-color-primary hide-button-text-in-mobile"}
                svgUrl="assets/icon/svg/camera_white.svg"
              />
            </div>
            <Gallery/>
            </div>
          )}>
        </Modal>

       
          

          
      

        <div className="pointer hide-in-take-render hide-on-select hide-on-sprites action-link-container padding10 bottom-right"
          id="customizein3dRightBottom">
          {/* <div className="center-aligned-column">
            <div className="white-card center-aligned-column click-effect color-black heading" style={{width:"28px",height:"28px"}} onClick={shortListAction}> 
              <i className="center-aligned-column fas fa-plus color-dark"></i>
            </div>
          </div> */}

        {/* {!props.isElevateMode?
        <div className='marginTop5'>
          <Layouts/>
        </div>
        :null} */}

        {STORE?.getCurrCategory() === "Wardrobe"?
          <div className='marginBottom5 hide-on-select'>
            <Button2
              configObj={{id:""}}
              action={()=>{selectWardrobeLines()}}
              label={"Select Lines"}
              fontAwesomeIcon={"fas fa-mouse-pointer color-black"}
              iconPos="left"
              classNames={"shadow-medium marginTop5 no-border button-small"}
          />
          </div>
        
        :null}

        {getUser()?.username?.includes("Shreedhar")?
        <div className='marginBottom5'>
          <Button2
            configObj={{id:""}}
            action={()=>{debugScene.open()}}
            label={"Debugger"}
            fontAwesomeIcon={"fas fa-cog color-danger"}
            iconPos="left"
            classNames={"shadow-medium marginTop5  hide-in-mobile no-border button-small"}
        />
        </div>
        :null}

        {isShowBackground?
        <div className="hide-on-select hide-on-sprites">
        <Button2
            configObj={{id:""}}
            action={openBackgroundModelOptions}
            label={"background"}
            fontAwesomeIcon={"fas fa-camera color-danger"}
            iconPos="left"
            classNames={"shadow-medium marginTop5 no-border button-small"}
        />
      </div>
        :null}


          {!props.isSpacePlannerMode && (applicationConfig?.user?.username?.includes("Shreedhar") || applicationConfig?.user?.username === "ovl_2000")?
          <>
            <Button2
              configObj={{id:"galleryButtonConfigurator",classNames:"width"}}
              action={()=>setIsOpenGallery(true)}
              label={"Gallery"}
              fontAwesomeIcon={"fas fa-image color-danger"}
              iconPos="left"
              classNames={"shadow-medium no-border button-small  hide-button-text-in-mobile marginTop5 fill-available-width"}
              svgUrl="assets/icon/svg/gallery_danger.svg"
            />
            <SceneSelector
            />
          </>
             
          :null}


          

          {!props.isFloorplanMode?
            <Button2
              configObj={{id:"addToCartMobile",classNames:"width"}}
              action={addToShortlist}
              label={"Add to shortList"}
              fontAwesomeIcon={"fas fa-cart-plus color-"}
              iconPos="left"
              classNames={"shadow-medium bg-color-primary display-none marginTop5 fill-available-width"}
            />
          :null}

          
          
          {/* {getUserType().toLowerCase() !== "customer"?
          <div className="shadow-medium bg-color-active display-flex-row op-btn op-btn-light hide-in-mobile font-small letter-spacing"
            style={{
              padding: "0.7rem 1.2rem",
              borderRadius: "20px",
              marginLeft: "1rem",
            }}
            id="addToCartDesktop"
            onClick={addToShortlist}>
            <span className="center-aligned-column">
              <IonIcon icon={cartSharp} className="color-black font-medium" />
            </span>
            <span className="color-black heading2 font-normal center-aligned-column marginleft5">
              Add to Shortlist
            </span>
          </div>
          :null} */}

          {/* <div className="hide-in-desktop">
          <div className="display-flex-row space-between">
            <div></div>
            <div id="addToCartMobile" className="white-card pointer click-effect add-to-cart-mobile position-relative shadow-medium center-aligned-column ion-margin-start"
            
            onClick={addToShortlist}>
              <i className="fas fa-cart-plus color-primary font-medium"></i>
            </div>
          </div>
          </div> */}

        </div>
      </React.Fragment>
    );
  };
  
  export default RightBottomActions;
  