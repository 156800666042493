import { addItemToLocalStorage, getItemFromLocalStorage } from "../cache/localstorage"
import $ from "jquery"
import { applicationConfig, waitFor } from "../methods"
import { STORE } from "./storeConfiguration"
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../UI_methods/global"
import { getRandomNumber } from "../products/productviewer_shopify.js"
import { getTodayTimestampOnlyDate } from "./crm/calendar"

export class CartItems{
    items:Array<CartItem> = []

    constructor(){
        let items = getItemFromLocalStorage(`product-cart-items`)
    }

    addItem(productName:any,configuration:any,price:number,imageUrl:string){
        let item = new CartItem(productName,configuration,price,imageUrl)
        this.items.push(item)
        this.updateLocalStorage()
    }

    removeItem(id:number){
        this.items = this.items.filter(currItem => currItem.id != id)
        this.updateLocalStorage()
    }

    updateLocalStorage(){
        addItemToLocalStorage(`product-cart-items`,this)
    }
}

export class CartItem{
    id:number = Number(new Date())
    productName:string
    price:number = 0
    imageUrl:string = ""
    configuration:any = null
    quantity:number = 0
    constructor(productName:any,configuration:any,price:number,imageUrl:string){
        this.productName = productName
        this.configuration = configuration
        this.price = price
        this.imageUrl = imageUrl
    }
}


export async function addToCartHandle(productDetails:any,callBack:()=>void) {
    if(productDetails){
      addCartItemsInLocalStorage(productDetails)
      // const customerId = STORE.activeCustomer.id
      // await STORE.storeApi.cart.add(customerId,{productId:productDetails.product_id,quantity:1,configuration:JSON.stringify(STORE.productConfiguration),price:STORE.productConfiguration.price}).then((data:any)=>{
      //   if(!data.data.error){
      //     showGlobalToast("Product Added to cart")
      //   }else{
      //     showGlobalToast(data.data.message,2000,"error")
      //   }
      // })
      if(callBack){
        callBack()
      }
      updateCartIconCounter(STORE.cartItems?.length)
      showProductCart()
      if(STORE.functionRefs.closeProductSearch){
        STORE.functionRefs.closeProductSearch()
      }
    }
  }

export function addCartItemsInLocalStorage(productDetails:any){

  let key = "store_cart"
  let items = getItemFromLocalStorage(key) || []
  let currItem = {
    product_id:productDetails.product_id,
    quantity:1,
    configuration:JSON.stringify(STORE.productConfiguration),
    price:STORE.productConfiguration.price
  }
  items.push(currItem)
  addItemToLocalStorage(key,items)
}




export function showProductCart() {
    $(".product-cart-wrapper").removeClass("display-none")
    setTimeout(() => {
        $(".product-cart-container").addClass("--is-active")
        applicationConfig.functions?.store_MainHeader?.refreshCartItems()
    }, 100);
}

export function hideProductCart() {
    $(".product-cart-container").removeClass("--is-active")
    setTimeout(() => {
        $(".product-cart-wrapper").addClass("display-none")
    }, 400);
}

export function showProductCheckout() {
    hideProductCart()
    $(".product-checkout-wrapper").removeClass("display-none")
}

export function hideProductCheckout() {
    $(".product-checkout-wrapper").addClass("display-none")
}

export function showOrdersWrapper() {
    $(".orders-wrapper").removeClass("display-none")
}

export function hideOrdersWrapper() {
    $(".orders-wrapper").addClass("display-none")
}

export function updateCartIconCounter(length:number) {
    let items = getItemFromLocalStorage(`product-cart-items`)
    if(items){
        $(".cart-item-counter").text(length)
    }
}


// export function getTotalPrice(cartItems:any) {
//     let totalPrice = 0
//     for (const item of cartItems) {
//         totalPrice += Number(item.price)
//     }
//     return totalPrice
// }

function emptyCart() {
  // STORE.cartItems.removeItem(id)
  addItemToLocalStorage("store_cart",[])
  applicationConfig.functions.store_MainHeader.refreshCartItems()
}


export async function createOrder(callback:()=>void) {
    const customerId = STORE.activeCustomer.id
    if(STORE.activeCustomer){
      const customerSessionId = STORE.activeCustomer.customerSessionId
      const orderDate = getTodayTimestampOnlyDate()
      const items = STORE.cartItems
      showComponentLoader("storeLoader")
      STORE.storeApi.orders.add(customerId,{items:items,customerSessionId:customerSessionId,orderDate:orderDate,totalPrice:STORE.checkoutPricing.totalPrice,advanceAmount:STORE.checkoutPricing.advanceAmount}).then(async (data:any)=>{
        await waitFor(500)
        applicationConfig.functions.store_MainHeader.refreshCartItems()
        if(!data.data.error){
          showComponentLoader("storeLoader")
          callback()
          await waitFor(1000)
          hideProductCheckout()
          showOrdersWrapper()
          emptyCart()
          hideComponentLoader("storeLoader")
          showGlobalToast("Order has been placed successfully")
          // applicationConfig.functions.store_MainHeader.openOrders()
        }else{
        }
      })
    }else{
        alert("Please start customer session")
    }
    hideComponentLoader("storeLoader")
    
  }
