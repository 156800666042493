//Export for Client name instead globally 

import { colorPaletteOutline, folderOpenOutline, imagesOutline, peopleOutline, serverOutline } from "ionicons/icons"
import { applicationConfig } from "./methods"



export const productsForDemo = ["Titanic","DS1735",,"DSTest","Quatro","ElizabethCorner","Havana","RigginsCorner","DS8121",
"Fantasy","Speranza","B565","B613","Arizona","Statue1","Vintter","Wardrobe2"]


export const categoryForStore = ["Sofa","Cot","Wardrobe","Dresser"]

export const productsForElevate = ["Fantasy","Apollo","Dura","Woodee"]

export const productsForOpusTl = ["Brunel","Emotion","Cabriole","DChester","Arizona","Washington",
  "Amazon","Fantasy","ElizabethCorner","Manila","Elsa","Dolton","WingChair","EvilWingChair","Cot21","B565","B613",
  "Wardrobe2","Dresser1","Aristocrat","Welvet","Moderno","Quatro","Tulip","Speranza","English","Armonia","MobiLounger"]
export const backgoundParts:any = {
    Hall:['Walls','Props','Curtain','Floor'],
    BedRoom:[],
    Kitchen:['Background'],
    Room1:['Background'],
    Room3:['Background'],
    LivingRoom:['Background'],
    DiningRoom:['Background'],
    Foyer:['Background'],
    // BedRoom2:[],
    BedRoom1:["Background"],
    BedRoom2:["Background"],
    BedRoom3:["Background"],
    Entry:[],
    Bathroom:[]
}

export const foregroundParts:any = {
    Sofa:['Base','ArmRest','SeatCushion','BackCushion',
    'Pillow','Wood','Feet','Metal','Glass','Plastic','Back'],
    Wardrobe:['BaseLeft','BaseRight','ExternalDrawerLeft1','ExternalDrawerLeft2','ExternalDrawerLeft3',
    'ExternalDrawerRight1','ExternalDrawerRight2','ExternalDrawerRight3','LoftLeft','LoftRight','Skirting',
    'Base_Left','Base_Right','Loft_Left','Loft_Right','External_Drawer1','External_Drawer2',
    ,'Skirting',"Frame",
    'Handle',
    'Glass',
    'Mirror',"Base","Middle_Band","MiddleBand","External_Drawer","ExternalDrawer","Body"],
    Cot:['Base','CotBase','Headrest','Backpanel','Frame','HeadBoard','FootBoard','Upholstery','BackPanel','Mattress',"SideTable","Wood"],
    Curtain:['CurtainTop',
        'CurtainBase',
        'CurtainBottom'
        ],
    Floor:['Floor'],
    Carpet:['Carpet'],
    Walls:['Wall','Wall1','Wall2','Wall3','Wall4'],
    TvUnit:['Backpanel','BackPanel'
    ,'Base'
    ,'Cladding'
    ,'Drawer1'
    ,'Drawer2'
    ,'Drawer3',
    'Ledge'
    ,'Ledge1'
    ,'Ledge2'
    ,'Ledge3'
    ,'Ledge4'
    ,'Shutter1'
    ,'Shutter2'
    ,'Shutter3'
    ,'Top1'
    ,'Top2'
    ,'Handle','Frame'],
    CrockeryUnit:['Frame'
    ,'Glass'
    ,'Handle'
    ,'Shutter1'
    ,'Shutter2'
    ,'Shutter3'
    ,'Shutter4'
    ,'Shutter5'
    ,'Shutter6',
    "CounterTop",
    "Drawer",
    "Drawer1",
    "Drawer2"
    ,'Skirting'],
    Kitchen:['BaseShutter1'
    ,'BaseShutter2'
    ,'BaseShutter3'
    ,'BaseShutter4'
    ,'BaseShutter5'
    ,'BaseShutter6'
    ,'BaseShutter7'
    ,'BaseShutter8'
    ,'BaseShutter9'
    ,'LoftShutter1'
    ,'LoftShutter2'
    ,'LoftShutter3'
    ,'LoftShutter4'
    ,'LoftShutter5'
    ,'LoftShutter6'
    ,'LoftShutter7'
    ,'UpperLoft_Shutter1'
    ,'UpperLoft_Shutter2'
    ,'UpperLoft_Shutter3'
    ,'UpperLoft_Shutter4'
    ,'UpperLoft_Shutter5'
    ,'UpperLoft_Shutter6'
    ,'UpperLoft_Shutter7'
    ,'UpperLoft_Shutter8'
    ,'LoftBase'
    ,'UpperLoft_Base'
    ,'Skirting'
    ,'Handle'
    ,'CounterTop',
    'BackSplash',
    "BaseDrawer1",
    "BaseDrawer2",
    "TallUnitShutter1",
    "WallUnitShutter1",
    "WallUnitShutter2",
    "WallUnitBase",
    "OpenShelf"
    ],
    ShoeRack:['Base'
    ,'Ledge',
    ,'Shutter1'
    ,'Shutter2'
    ,'Shutter3'
    ,'Shutter4'
    ,'Top'
    ,'Sitting'
    ],
    Foyer:['Base',
        'Foyer_Panel'
        ],
    Wallpaper:['Wallpaper',"Other"],
    WallPaint:["Wall"],
    WallCladding:["WallCladding"],
    Decor:[],
    KidsFurniture:["Color1","Color2","Color3","Handle"],
    DiningTable:["TableTop","Body","Sitting","Chair","Wood"],
    CentreTable:["Body"],
    Tables:["Body"],
    Chairs:["SeatCushion","Base"],
    Lighting:[],
    Dresser:["Drawer","Frame","BaseLeft","Skirting","Leg","Handle","Mirror","MiddleBand","Body","ExternalDrawer"],
    PujaUnit:["Base","Frame","Shutter1","Shutter2","Shutter3","Shutter4","Drawer1"
    ,"Drawer2","Drawer3","Drawer4","Mesh","Handle","Glass"],
    Console:["Metal","Marble"],
    SideTable:[],
    Swing:["Wood_Base","Metal_Handle","Metal_Rope","SeatCushion","BackCushion","Metal_Stand","Base","Plateform","SwingPlatform"],
}

export const defaultConfig:any = {
    
}

export const areaPriorityList = {
    Foyer:7,
    LivingRoom:4,
    BedRoom1:1,
    BedRoom2:2,
    BedRoom3:3,
    DiningRoom:5,
    Kitchen:6,
}

export const categoryMeshMapping =  {
    "Sofa":"BackCushion",
    "Cot":"Upholstery"
}


export const appilcationModulesList = [
    "login","products","customizein3d","collections","collection"
]

export const modulesCode = {
    "products":"products",
    "customizein3d":"cin3d"
}

export const numbers_array = ["0","1","2","3","4","5","6","7"]


export const tokenValidityList = [
    {
      label: "1 hour",
      value: 1 / 24,
    },
    {
      label: "6 hours",
      value: 6 / 24,
    },
    {
      label: "1 Day",
      value: 1,
    },
    {
      label: "2 Days",
      value: (24 * 2) / 24,
    },
    {
      label: "3 Days",
      value: (24 * 3) / 24,
    },
]


export const customizein3dTabItems = [
  {
    tabName: "addModulesContainer",
    tabLabel: "Add Module",
    tabId: "addModule",
    img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/SofaDesign.png",
  },
  {
    tabName: "finishContainer",
    tabLabel: "Choose Finish",
    tabId: "finish",
    img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/mySofaLogo.png",
  },
  // {
  //   tabName: "roomLayoutContainer",
  //   tabLabel: "Room Layout",
  //   tabId: "roomLayout",
  //   img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/RoomArrangement.png",
  // },
  // {
  //   tabName: "addonsContainer",
  //   tabLabel: "Addons",
  //   tabId: "addons",
  //   img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/mySofaLogo.png",
  // },
  {
    tabName: "advancedConfig",
    tabLabel: "Advanced",
    tabId: "advanced",
    img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/advanced.svg",
    isCustomTab:true
  },
]


export function getOverviewCardsList() {
  return [
    {
        pageTitle:"Products",
        backgroundImage:"assets/images/product.jpg",
        link:"/product-categories",
        count:applicationConfig?.data?.productsList?.length || 0,
        icon:serverOutline
    },
    {
        pageTitle:"Collections",
        backgroundImage:"assets/images/collection.jpeg",
        link:"/collections",
        count:applicationConfig?.data?.moodboardList?.length || 0,
        icon:imagesOutline
    },
    {
        pageTitle:"Finish",
        backgroundImage:"assets/images/finish.jpeg",
        link:"/finish",
        count:applicationConfig?.data?.materials?.length || 0,
        icon:colorPaletteOutline
    },
    {
        pageTitle:"Projects",
        backgroundImage:"assets/images/project.jpg",
        link:"/projects",
        count:applicationConfig?.data?.projects?.length || 0,
        icon:folderOpenOutline
    },
    {
        pageTitle:"Users",
        backgroundImage:"assets/images/project.jpg",
        link:"/users",
        count:applicationConfig?.data?.usersList?.length || 0,
        icon:peopleOutline
    }
]
}
 


export var activityOutcomes = [
  {
    outcomeName:"Not Reached",
    value:"notreached"
  },
  {
    outcomeName:"Reached but appointment not fixed",
    value:"reached"
  },
  {
    outcomeName:"Pending appointment",
    value:"pending"
  },
]

export const categoryTypesMapping = [
  {
    id:1,
    category_type:"Loose Furniture",
    categories:["Sofa","Cot","CenterTable","Console","DiningTable","Tables","Decor"],
    username:"DemoLoose"

  },
  {
    id:2,
    category_type:"Modular Furniture",
    categories:["PujaUnit","CrockeryUnit","Wardrobe","TvUnit","ShoeRack","Partition","Kitchen","Dresser","KidsFurniture","HomeOfficeFurniture"],
    username:"DemoModular"
  
  },{
    id:3,
    category_type:"Decor",
    categories:["Decor","Lighting"],
    username:"DemoDecor"
  },
  {
    id:4,
    category_type:"Soft Furnishing",
    categories:["Curtain","Carpet"],
    username:"DemoSoft"
  },
  {
    id:5,
    category_type:"Wall Furnishing",
    categories:["Wallpaper","WallPaint","WallCladding",],
    username:"DemoWall"
  }
]


export const materialHexRelationship = [
  {
    materialCode:"Iron",
    hex:"",
    rgb:[0.560,0.570,0.580],
    baseColourHexCode:"Default"
  },
  {
    materialCode:"Silver",
    hex:"0xb0b0b0",
    rgb:[0.972,0.960,0.915],
    baseColourHexCode:"0xb0b0b0"
  },
  {
    materialCode:"Aluminium",
    hex:"",
    rgb:[0.913,0.921,0.925],
    baseColourHexCode:"Default"
  },
  {
    materialCode:"Gold",
    hex:"0x9f8c5a",
    rgb:[1.000,0.766,0.336],
    baseColourHexCode:"0x9f8c5a"
  },
  {
    materialCode:"Copper",
    hex:"",
    rgb:[0.955,0.637,0.538],
    baseColourHexCode:"Default"
  },
  {
    materialCode:"Chrome",
    hex:"0xaba9a9",
    rgb:[0.550,0.556,0.554],
    baseColourHexCode:"0xaba9a9"
  },
  
  {
    materialCode:"Nicke",
    hex:"",
    rgb:[0.660,0.609,0.526],
    baseColourHexCode:"Default"
  },
  {
    materialCode:"Titanium",
    hex:"",
    rgb:[0.542,0.497,0.449],
    baseColourHexCode:"Default"
  },
  {
    materialCode:"Brass",
    hex:"0xb5a642",
    rgb:[0.180,0.166,0.66],
    baseColourHexCode:"0xb5a642"
  },
  {
    materialCode:"RoseGold",
    hex:"0x905757",
    rgb:[0.183,0.110,0.121],
    baseColourHexCode:"0x905757"
  },
  {
    materialCode:"Black",
    hex:"0x000000",
    rgb:[0.183,0.110,0.121],
    baseColourHexCode:"0x000000"
  },
]

