import { Camera, Object3D, Quaternion, Vector3 } from "three";
import { getForegroundSavedConfig } from "../../services/api";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../cache/localstorage";
import { Configuration, Module } from "../customizein3d/Configuration";
import { customizerConfig, product3DModelsList } from "../customizein3d/area3dmodel/area3dModel";
import { getOriginalPartName, getOriginalProductName, isWallMountedProduct, saveConfiguration, setProductNamesInModel } from "../customizein3d/area3dmodel/helper";
import { getAreaFromWallsBoundingBox } from "../customizein3d/area3dmodel/walls";
import { FloorplanConfiguration } from "../customizein3d/floorplanner/floorplan";
import { foregroundParts, numbers_array } from "../data";
import { applicationConfig, getArrayOfDistinctValuesFromArray, getFilteredArray, getObjectArrayOfDistictValues, stringWithoutWhiteSpace } from "../methods";
import { getLatestConfigurationFromDatabase } from "./customizer";

var positionVector = new Vector3()

export class ProjectConfiguration{

    clientId:number
    clientName:string = "DemoClient2"
    projectId:number
    projectConfiguration:any = {}
  
    areasList:Array<any>
    projectName:string = "NewDemo"
    projectProducts:Array<any>

    //to check if the area is changed
   
    isRenderCreated:boolean = false
    configName:string = "Default"
    areAreasDefined:boolean = false
    isCreatingRender = false
    configId:number = 0

    northWall:string = ""

    isDefinedFloorplan:boolean = false
    floorplanScale:Vector3 = new Vector3(1,1,1)

    isFloorplanCreatedFromShape:boolean = false

    cameras = []

    floorplanConfiguration:FloorplanConfiguration = null

    isProductRenderMode:boolean = false

    productRenderModeConfig = {
      product:null,
      configHash:"",
      configId:"",
      currScene:"Room1",
      productModelUrl:""
    }
  
    constructor(clientId:number,clientName:string,projectId:number,projectName:string){

      this.clientId = clientId
      this.clientName = clientName
      this.projectId = projectId
      // applicationConfig.areaName = areaName
      // applicationConfig.areaNameId = areaId
      this.projectName = projectName
  
      let projectProducts = getFilteredArray(applicationConfig?.data.projectAreaProductsList,"project_id",projectId) 
      this.projectProducts = projectProducts
      // currentConfigObject.setProjectProductsList(projectProducts)
      let allAreas = getFilteredArray(applicationConfig?.data?.projectAreasList,"project_id",projectId)
  
      this.areasList = [...allAreas || [],{area_name:"Other"}]

      let savedConfigurations = null
      let cameras = []
      let savedConfigurationFromLocalStorage = getItemFromLocalStorage(`Customizer_3d_${projectName}_${projectId}`) || applicationConfig.shareItemConfiguration

      if(savedConfigurationFromLocalStorage){
        savedConfigurations = savedConfigurationFromLocalStorage.projectConfiguration
        const floorplanScale = savedConfigurationFromLocalStorage.floorplanScale
        this.configName = savedConfigurationFromLocalStorage.configName
        this.isRenderCreated = savedConfigurationFromLocalStorage.isRenderCreated
        this.areAreasDefined = savedConfigurationFromLocalStorage.areAreasDefined
        this.configId = savedConfigurationFromLocalStorage.configId
        this.northWall = savedConfigurationFromLocalStorage.northWall
        this.isFloorplanCreatedFromShape = savedConfigurationFromLocalStorage.isFloorplanCreatedFromShape
        this.floorplanConfiguration = savedConfigurationFromLocalStorage.floorplanConfiguration
        // this.parseFloorplanConfiguration(savedConfigurationFromLocalStorage.floorplanConfiguration.vertices)
        // this.floorplanScale = new Vector3(floorplanScale.x,floorplanScale.y,floorplanScale.z)
        cameras = savedConfigurationFromLocalStorage.cameras
      }else{
        let savedConfigFromDatabase = getLatestConfigurationFromDatabase()
        if(savedConfigFromDatabase){
            savedConfigurations = JSON.parse(savedConfigFromDatabase.configuration)
            const floorplanScale = savedConfigFromDatabase.floorplanScale
            this.configName = savedConfigFromDatabase.config_name
            this.isRenderCreated = savedConfigFromDatabase.is_created_render
            this.areAreasDefined = savedConfigFromDatabase.are_areas_defined
            this.configId = savedConfigFromDatabase.id
            this.northWall = savedConfigFromDatabase.northWall || ""
            this.isFloorplanCreatedFromShape = savedConfigFromDatabase.isFloorplanCreatedFromShape
            this.floorplanConfiguration = savedConfigFromDatabase.floorplanConfiguration
            // this.parseFloorplanConfiguration(savedConfigFromDatabase.floorplanConfiguration.vertices)
            // this.floorplanScale = new Vector3(floorplanScale.x,floorplanScale.y,floorplanScale.z)
            cameras = savedConfigurations.cameras
            savedConfigurations = savedConfigurations.projectConfiguration
        }
      }

      if(savedConfigurations){
          this.createProjectConfigFromSavedConfig(savedConfigurations,cameras)
      }else{
          this.createInitialProjectConfig()
      }

      // if(!this.configName){
      //   this.configName = String(Number(new Date()))
      // }
    }


  

    createConfigFromConfigId(configId:number){
      if(configId){
        let config:any = getFilteredArray(applicationConfig?.data?.customizerSavedConfigs,"id",configId)
          if(config.length){
          this.configName = config[0].config_name
          this.isRenderCreated = config[0].is_created_render
          this.areAreasDefined = config[0].are_areas_defined
          this.configId = config[0].id
          config = JSON.parse(config[0].configuration)
          let cameras = config.cameras
          config = config.projectConfiguration
          this.projectConfiguration = {}
          this.cameras = []
          this.createProjectConfigFromSavedConfig(config,cameras)
        }
      }
    }


    createInitialProjectConfig(){
      this.configName = "Default"
      for (const i in this.areasList) {
          let area:any = this.areasList[i]
          if(area && this.projectName){
            let areaConfig = new Area(area)
            this.projectConfiguration[area.area_name] = areaConfig
            let products = getFilteredArray(this.projectProducts,"area_name",area.area_name)
            if(products){
              products = getObjectArrayOfDistictValues(products,"product_name")
              areaConfig.createAreaConfiguration(products)
            }
            if(!area.area_name.toLowerCase().includes("other")){
              this.addCamera(area.area_name,"3d")
            }
          }
      }
      // this.updateLocalStorage()
      saveConfiguration(false,this,this.configName).then((data:any)=>{
          this.setConfigId(data.data.data.insertId)
          getForegroundSavedConfig().then(data=>{
            applicationConfig.data.customizerSavedConfigs = data
          }).catch(err=>{
            console.log(err)
          })
      }).catch(err=>{
        console.log(err)
      })
    }

    resetCameras(){
      this.cameras = []
      for (const i in this.areasList) {
        let area:any = this.areasList[i]
        this.addCamera(area.area_name)
      }
    }

    update360CamerasPosition(){
      for (const currCamera of this.cameras) {
        let areaName = currCamera.areaName
        currCamera.transform.position = this.projectConfiguration[areaName].anchor
        console.log(this.projectConfiguration[areaName].anchor)
      }
    }


    addCameras(camerasList:Array<camera>){
      this.cameras = [...this.cameras,...camerasList]
    }

    remove2DCameras(){
      this.cameras = this.cameras.filter(currCam=>currCam.renderType != "2d")
      this.updateLocalStorage()
    }

    removeArea2DCameras(areaName:string){
      this.cameras = this.cameras.filter(currCam=>(currCam.renderType === "2d" && currCam.areaName !== areaName) || currCam.renderType === "3d")
      this.updateLocalStorage()
    }

    delete2dCamera(cam:camera){
      this.cameras = this.cameras.filter(currCam=>currCam.cameraId !== cam.cameraId)
      this.updateLocalStorage()
    }

    remove3DCameras(){
      this.cameras = this.cameras.filter(currCam=>currCam.renderType != "3d")
      this.updateLocalStorage()
    }

    setAreAreasDefined(val:boolean){
      this.areAreasDefined = val
      this.updateLocalStorage()
    }

    setIsCreatingRender(val:boolean){
      this.isCreatingRender = val
    }
    setConfigId(val:number){
      this.configId = val
      this.updateLocalStorage()
    }
    
    createProjectConfigFromSavedConfig(projectConfiguration:any,cameras:Array<any>){
        for (const areaName in projectConfiguration) {

            let areaConfiguration = projectConfiguration[areaName].areaConfiguration
            let areaConfigObject = new Area({area_id:projectConfiguration[areaName].areaId,
              area_name:projectConfiguration[areaName].areaName,
              anchor:projectConfiguration[areaName].anchor,
              walls:projectConfiguration[areaName].walls,
              isConfigChanged:projectConfiguration[areaName].isConfigChanged,
              isRenderCreated:projectConfiguration[areaName].isRenderCreated
            })
            this.projectConfiguration[areaName] = areaConfigObject
            for (const originalProductName in areaConfiguration) {

                let productInstances = areaConfiguration[originalProductName]
                for (let productInstanceKey in productInstances) {
                

                  let currProductInstance =  productInstances[productInstanceKey]
                  let categoryName = currProductInstance.categoryName

                  let transform = areaConfiguration[originalProductName][productInstanceKey].transform 
                  // let originalProductName = getOriginalProductName(null,productName)
                  let productInstance =  new ProductInstance(currProductInstance,originalProductName,productInstanceKey,currProductInstance.enabled,currProductInstance.isAddedAsNew)
                  // areaConfigObject.areaConfiguration[productName] = productInstance
                  // areaConfigObject[originalProductName] = {[productInstanceKey] : productInstance}
                  // productInstance.updateTextureWithAvailableParts()
                  if(transform.rotation.length){
                    let rotation = transform.rotation
                    transform.rotation = new Quaternion(rotation[0],rotation[1],rotation[2],rotation[3])
                  }
                  productInstance.getTransform().updateTransform(transform.position,transform.rotation,transform.scale,transform.rotationDelta)
                  areaConfigObject.setProductConfig(productInstance,originalProductName,productInstanceKey)
                  productInstance.isAttachedToWall = currProductInstance.isAttachedToWall
                  productInstance.isWallMounted = isWallMountedProduct(originalProductName,categoryName)
                  if(currProductInstance.normal){
                    productInstance.normal = new Vector3(currProductInstance.normal.x,currProductInstance.normal.y,currProductInstance.normal.z)
                  }
              
                  //update Finish config 
                  // let productParts = foregroundParts[categoryName]
                  // if (productParts?.length) {
                  //     for (const partName of productParts) {
                  //       let textureInfo =  areaConfiguration[originalProductName][productInstanceKey].configuration[partName] 
                  //       if(textureInfo){
                  //         let textureObject = areaConfigObject?.getProductConfig(originalProductName,productInstanceKey).configuration[partName]
                  //         textureObject?.updateTextureInfo(textureInfo)
                  //       }
                  //     }
                  // }
                  let configuration = currProductInstance.configuration
                  for (const partName in configuration) {
                    let textureInfo = configuration[partName]
                    if(textureInfo && partName !== "undefined"){
                      let textureObject = new TextureInfo()
                      textureObject?.updateTextureInfo(textureInfo)
                      configuration[partName] = textureObject
                    }
                  }
                  productInstance.configuration = configuration
                  this.updateModulesConfig(productInstance.moduleConfiguration,currProductInstance.moduleConfiguration,productInstance)
                }
            
              }

          }
          this.cameras = []
          for (const currCamera of cameras) {
            let cam = new camera(currCamera.areaName,currCamera.renderType,currCamera.cameraId,currCamera)
            this.appendCamera(cam)
          }
          this.updateLocalStorage()
    }


    addCamera(areaName:string,renderType:string="2d"){
      let cam = new camera(areaName,renderType)
      this.appendCamera(cam)
      return cam
      // let isExists = this.cameras.find(currCamera=> currCamera.areaName === cameraTobeAdded?.areaName && currCamera.renderType === "3d") || null
      // if(!isExists || cam.renderType === "2d"){
      // }
    }

    appendCamera(cam:camera){
      this.cameras.push(cam)
    }

    updateModulesConfig(configuration:Configuration,savedConfig:any,product:any){
      let modules = savedConfig?.modules || []
      let addons = savedConfig?.addons || []
      // configuration.configuration = 
      let modulesList = modules.concat(addons)
      if(modulesList){
        configuration.setProduct(product)
        for (const currModule of modulesList){
          let instance = new Module(currModule,"")
          instance.transform.updateTransform(currModule.transform.position,currModule.transform.rotation,currModule.transform.scale,currModule.transform.rotationDelta)
          if(currModule.normal){
            instance.updateNormal(new Vector3(currModule.normal.x,currModule.normal.y,currModule.normal.z))
          }
          configuration.modules.push(instance)
        }
      }
    }

    removeCamera(cameraId:string){
      this.cameras = this.cameras.filter(currCamera=>currCamera.cameraId !== cameraId)
    }

    setIsRenderCreated(val:boolean){
      this.isRenderCreated = val
      this.updateLocalStorage()
    }
  
    setCurrArea(areaName:string){
      applicationConfig.areaName = areaName
    }
    
  
  
    getAreaObject(areaName:string){
      return this.projectConfiguration[areaName || applicationConfig.areaName]
    }

    setAreaIsConfigChanged(areaName:string,val:boolean){
      if(areaName && areaName.toLowerCase()!= "all"){
        this.getAreaObject(areaName)?.setIsConfigChanged(val)
      }
    }

    setAreasIsChangedConfig(flag:boolean){
      for (const area of this.areasList) {
        this.setAreaIsConfigChanged(area.area_name,flag)
      }
      this.updateLocalStorage()
    }


    getProductsList(){
      let list = []
      let areaConfig = this.projectConfiguration[applicationConfig.areaName].areaConfiguration
      for (const key in areaConfig) {
         list.push(key)
      }
      return list
    }

    updateAreaAnchor(areaName:string,position:Vector3){
      this.getAreaObject(areaName).updateAnchor(position)
      //Update camera anchor position
      let camera = this.getCameraFromAreaName(areaName)
      if(camera){
        camera.transform.updateTransform(position,camera.transform.rotation,camera.transform.scale,camera.transform.rotationDelta)
      }
      this.updateLocalStorage()
    }

    updateCameraTransform(areaName:string,camera:Camera){
      let areaCamera = this.getCameraFromAreaName(areaName)
      if(areaCamera){
        areaCamera.transform.updateTransform(new Vector3(areaCamera.transform.position.x,camera.position.y,areaCamera.transform.position.z),camera.quaternion,camera.scale,0)
      }
      this.updateLocalStorage()
    }

    updateCameraNear(areaName:string,camera:any){
      let areaCamera = this.getCameraFromAreaName(areaName)
      if(areaCamera){
        areaCamera.updateNear(camera.near)
      }
      this.updateLocalStorage()
    }

    remove3dCameras(){
      let cameras = this.cameras
      for (const camera of cameras) {
        if(camera.renderType === "3d"){
          cameras = cameras.filter(cam=>cam.renderType!=="3d")
        }
      }
      this.cameras = cameras
      this.updateLocalStorage()
    }

   

    getCameraFromAreaName(areaName:string){
      return this.cameras.find(currCamera=>currCamera.areaName === areaName && customizerConfig.currentRenderingType === currCamera.renderType) || null
    }

    getAreaFromCameraName(cameraName:string){
      return  this.cameras.find(currCamera=>currCamera.cameraName === cameraName).areaName || null
      
    }
    updateCameraValues(sceneCamera:any,cameraObj:camera){
      
      // cameraObj.updateTargetPosition(sceneCamera)
      cameraObj.updateFov(sceneCamera.fov)
      // cameraObj.transform.updateTransform(cameraObj.transform.position,cameraObj.transform.rotation,sceneCamera.scale,0)
      cameraObj.transform.updateTransform(cameraObj.transform.position,sceneCamera.quaternion,cameraObj.transform.scale,0)
    }

    updateAreaWalls(areaName:string,walls:Array<any>){
      this.getAreaObject(areaName).updateWalls(walls)
      this.updateLocalStorage()
    }

    getProjectConfiguration(){
      return this.projectConfiguration
    }
  
  
    updateAllProductsTransform(){
      product3DModelsList.forEach(object => {
        let target = new Vector3()   
        let rotation = new Quaternion()
        object.getWorldPosition(target)
        object.getWorldQuaternion(rotation)
  
        let productConfig = this.getAreaObject(applicationConfig.areaName).getProductConfig(object.name)
        if(productConfig){
          object.getWorldPosition(target)
          object.getWorldQuaternion(rotation)
          productConfig.getTransform().updateTransform(target,rotation,object.scale)
        }
      })
    }

    getAllProductInstances(productName:string){
        let allProductInstances = []
        // let productName = product.productName
        productName = stringWithoutWhiteSpace(productName)
        //   let areaConfig = savedConfigurations[this.areaName]
        for (const areaName in this.projectConfiguration) {
            let currAraeConfig = this.getAreaObject(areaName).areaConfiguration
            for (const originalProductName in currAraeConfig){
              // let productInstances = currAraeConfig[originalProductName]
              for (const productInstanceName in currAraeConfig[originalProductName]) {
            
                if(productInstanceName.includes(productName) && currAraeConfig[originalProductName][productInstanceName]?.enabled){
                  allProductInstances.push(productInstanceName)
                } 
              }
            }
        }

        return getArrayOfDistinctValuesFromArray(allProductInstances) || []
    }
  

    updateFinish(originalProductName:string,productInstanceName:string,partName:string,textureInfo:string){
      // console.log(partName)
      let areaName = this.getAreaNameFromProduct(productInstanceName)
      let textureObject = this.getTextureObject(originalProductName,productInstanceName,partName,areaName)
      if(textureInfo && textureObject){
        if(textureObject.updateTextureInfo){
          textureObject.updateTextureInfo(textureInfo)
          this.updateLocalStorage()
        }
        
      }
    }

    getTextureObject(originalProductName:string,productInstanceKey:string,partName:string,areaName:any = null){
      return  this.projectConfiguration[areaName || applicationConfig.areaName].getProductConfig(originalProductName,productInstanceKey)?.configuration[partName]
    }

  

    updateProductTransform(productInfo:any,object:Object3D,target:Vector3,rotation:Quaternion,scale:Vector3,rotationDelta:number){
      //Check if product exists in the config or not // product can be dropped outside the area while dragging 
      //if product is added originalproductname and instance name will be set || product is already in the configuration
      if(object.userData.originalProductName && object.userData.productInstanceName){
        let product = this.getProductInstanceFromName(object.userData.productInstanceName)
        // If product present in config but the current area is changed
        product?.transform.updateTransform(target,rotation,scale,rotationDelta)

        if(customizerConfig.dragStartArea !== customizerConfig.dragEndArea){
          //Add product in new area remove it from old config 
          // let isDeleted = this.deleteProductKeyFromArea(customizerConfig.dragStartArea,object.userData.originalProductName,object.userData.productInstanceName)
          let isDeleted = this.deleteProductInstance(object.userData.productInstanceName)
          if(isDeleted){
            // this.addProduct(productInfo,object)
            this.getAreaObject(customizerConfig.dragEndArea).setProductConfig(product,product.originalProductName,product.productName)
          }
          // this.projectConfiguration[customizerConfig.dragEndArea].getProductConfig(object.userData.originalProductName,object.userData.productInstanceName).getTransform().updateTransform(target,rotation,scale,rotationDelta)
        }
      }else{
        //First add product to config // then update the transform values 
        this.addProduct(productInfo,object)
        this.projectConfiguration[customizerConfig.dragEndArea].getProductConfig(object.userData.originalProductName,object.userData.productInstanceName).getTransform().updateTransform(target,rotation,scale,rotationDelta)
      }  
      this.updateLocalStorage()
      
    }

    updateLocalStorage(){
        addItemToLocalStorage(`Customizer_3d_${this.projectName}_${this.projectId}`,this)
        // setIsConfigChangedTrue()
    }

    deleteProductKeyFromArea(areaName:string,originalProductName:string,productInstanceName:string){
      // let product = this.projectConfiguration[areaName].getProductConfig(originalProductName,productInstanceName) 
      //if area name is not found
      if(!areaName){
        return false
      }
      if(this.projectConfiguration[areaName].areaConfiguration[originalProductName]){
        if(this.projectConfiguration[areaName].areaConfiguration[originalProductName][productInstanceName]){
          delete this.projectConfiguration[areaName].areaConfiguration[originalProductName][productInstanceName]
          return true
        }
        return false
      }else{
        return false
      }
    }

    getCurrAreaConfig(){
        return this.getAreaObject(applicationConfig.areaName).areaConfiguration
    }

    setConfigName(name:string){
      this.configName = name
    }
    
    addProduct(product:any,modelObject:any,areaName:any = null){
 
        try {
            modelObject.getWorldPosition(positionVector)
            let detectedArea = areaName || getAreaFromWallsBoundingBox(modelObject) 
            if(detectedArea){
              let productName = stringWithoutWhiteSpace(product.product_name || product.productName)
              let originalProductName = stringWithoutWhiteSpace(getOriginalProductName(null,product.product_name || product.productName)) 

              let areaConfiguration:any = this.getAreaObject(detectedArea).areaConfiguration
              let allProductInstances = this.getAllProductInstances(product.product_name || product.productName)
      
              if(allProductInstances.length && allProductInstances.join("_").includes(productName)){
                
                let length = allProductInstances.length
                let firstProduct:any = allProductInstances[0]
                // if length is 1 and product is preasent in the area 
                if(length === 1 && areaConfiguration[firstProduct]){
                  let newProductKey = this.updateProductKey(detectedArea,originalProductName,firstProduct)
                  modelObject.userData.productName = newProductKey
                }
                allProductInstances.sort()
                let lastProduct:any = allProductInstances[length - 1]
                productName = this.getProductInstance(lastProduct)
              }
              let productInstance =  new ProductInstance(product,originalProductName,productName,true,true)
              productInstance.updateTextureWithAvailableParts(modelObject)
              productInstance.updateProductTransform(modelObject)
              productInstance.setClientName(product)
              this.getAreaObject(detectedArea).setProductConfig(productInstance,originalProductName,productName)
              setProductNamesInModel(modelObject,product.categoryName,originalProductName,productName,detectedArea)
              //Store for undo 
              modelObject.userData.configuration = productInstance
              this.updateLocalStorage()
              return productInstance
            }
        } catch (error) {
          // showToast(error,2000,"error")
          console.log(error)
        }
    }

    updateProductKey(areaName:string,originalProductName:string,productInstanceName:string){
        let array = productInstanceName.split("")
        array.push("_")
        array.push(String(1))
        let newProductKey = array.join("")

        

        // let productToBeDisabled = areaConfiguration[originalProductName][productInstanceName] 
        let productToBeDisabled = this.getProductInstanceFromName(productInstanceName)
        // productToBeDisabled.enabled = false
        this.removeProduct(originalProductName,productInstanceName)


        let productInstance =  new ProductInstance(productToBeDisabled,originalProductName,newProductKey,true,false)
        productInstance.configuration = productToBeDisabled.configuration
        productInstance.transform = productToBeDisabled.transform
        this.getAreaObject(areaName).setProductConfig(productInstance,originalProductName,newProductKey)
        
        //update model object userdata 
        // let object = getObjectByName(scene,originalProductName)
        let object = null
        product3DModelsList.forEach(currObject => {
          if(currObject.userData.productInstanceName === originalProductName || currObject.userData.productInstanceName === productInstanceName){
            object = currObject
          }
        });
        if(object){
          object.userData.productInstanceName = newProductKey
        }
        return newProductKey
    }

    getProductInstance(productName:string){
        let array = productName.split("")
        let lastIndex = array.length - 1
        if(numbers_array.includes(array[lastIndex]) && array[lastIndex - 1] === "_"){
          let instance = Number(array[lastIndex])
          instance += 1
          array.pop()
          array.push(String(instance))
          return array.join("")
        }
        array.push("_")
        array.push(String(2))
        return array.join("")
    }


    removeProduct(originalProductName:string,productInstanceName:string){
      try {
        this.deleteProductInstance(productInstanceName)
        this.updateLocalStorage()
      } catch (error) {
        console.log(error)
        // showToast("Error removing from config",2000)
      }
        
      }

      deleteProductInstance(productInstanceName:string){
        for (const areaKey in this.projectConfiguration) {
          let areaConfig = this.projectConfiguration[areaKey].areaConfiguration
          for (const productGroupKey in areaConfig) {
            for (const productInstanceKey in areaConfig[productGroupKey]) {
              if(productInstanceName === productInstanceKey){
                delete areaConfig[productGroupKey][productInstanceKey]
                if(!Object.keys(areaConfig[productGroupKey]).length){
                    delete areaConfig[productGroupKey]
                }
                return true
              }
            }
          }
        }
        return false
      }

      getAreaNameFromProduct(productInstanceName:string){
        for (const areaKey in this.projectConfiguration) {
          let areaConfig = this.projectConfiguration[areaKey].areaConfiguration
          for (const productGroupKey in areaConfig) {
            for (const productInstanceKey in areaConfig[productGroupKey]) {
              if(productInstanceName === productInstanceKey){
                return areaKey
              }
            }
          }
        }
        return null
      }

      getProductInstanceFromName(productInstanceName:string){
        for (const areaKey in this.projectConfiguration) {
          let areaConfig = this.projectConfiguration[areaKey].areaConfiguration
          for (const productGroupKey in areaConfig) {
            for (const productInstanceKey in areaConfig[productGroupKey]) {
              if(productInstanceName === productInstanceKey){
                return areaConfig[productGroupKey][productInstanceKey]
              }
            }
          }
        }
      }

      setNorthWall(name:string){
        this.northWall = name
        this.updateLocalStorage()
      }

      setModuleConfig(areaName:string){
        this.getAreaObject(areaName)
      }
    
    
      getCurrAreaConfiguration(){
        return this.getCurrAreaConfig()
      }

      getAreaProductsArray(areaName:string,includeEnabled:boolean = false){
        let productsToBeVisible = []
        let configuration = this.projectConfiguration[areaName].areaConfiguration
        for (const productKey in configuration) {
          for (const instanceKey in configuration[productKey]) {
            if(configuration[productKey][instanceKey].enabled){
              productsToBeVisible.push(instanceKey)
            }
          }
        }
        return productsToBeVisible
      }

      setIsFloorplanCreatedFromShape(val:boolean){
        // this.setIsFloorplanCreatedFromShape(val)
        this.isFloorplanCreatedFromShape = val
        this.updateLocalStorage()
      }
  
  }
  
  class Area{
    areaId:any
    areaName:string
    anchor:Vector3
    areaConfiguration:any = {}
    walls: any[];
    isConfigChanged:boolean = true
    isRenderCreated:boolean = false
    // areaProducts:Array<any> = []
  
    constructor(area:any){
      this.areaId = area.area_id
      this.areaName = area.area_name
      this.anchor = area.anchor || new Vector3(0,0,0)
      this.walls = area.walls || []
      this.isConfigChanged = area.isConfigChanged
      this.isRenderCreated = area.isRenderCreated
    }
  
    createAreaConfiguration(products: Array<any>) {
      // this.areaConfiguration = {}
      //If config exists in the local storage
      // let configFromDatabase = getFilteredArray(applicationConfig?.data.customizerSavedConfigs,"project_id",currentConfigObject.projectId)
      // let configFromDatabase = applicationConfig?.data.customizerSavedConfigs?.filter(config=> config.project_name===projectName && config.area_name === areaName)
    
  
      // if(savedConfigurations[areaName]){
      //   this.areaModelConfiguration[areaName] = savedConfigurations[areaName]
      //   return
      // }
  
      // this.areaProducts = products
  
      for (const product of products) {
        let originalProductName = stringWithoutWhiteSpace(getOriginalProductName(null,product.product_name))
        let productInstanceName = stringWithoutWhiteSpace(product.product_name)
        let productInstance =  new ProductInstance(product,originalProductName,productInstanceName,true,false)
        let transform = JSON.parse(product.transform) 
        if(transform){
          productInstance.getTransform().updateTransform(transform.position,transform.rotation,transform.scale,transform.rotationDelta)
        }
        this.setProductConfig(productInstance,originalProductName,productInstanceName)
      }
    }

    setProductConfig(productInstance:ProductInstance,originalProductName:string,productInstanceName:string){
      this.areaConfiguration[originalProductName] = {...this.areaConfiguration[originalProductName],[stringWithoutWhiteSpace(productInstanceName)] : productInstance}
    }
  
    getProductConfig(originalProductName:string,productInstanceName:string){
      if(this.areaConfiguration[stringWithoutWhiteSpace(originalProductName)]){
        return this.areaConfiguration[stringWithoutWhiteSpace(originalProductName)][stringWithoutWhiteSpace(productInstanceName)]
      }
      return null
    }

    setIsConfigChanged(val:boolean){
      this.isConfigChanged = val
    }
    
    setIsRenderCreated(val:boolean){
      this.isRenderCreated = val
    }

    updateAnchor(position:Vector3){
      this.anchor = position
    }
    updateWalls(walls:Array<any>){
      this.walls = walls
    }
  
  }

  
  export class ProductInstance{
  
    productName:string
    categoryName:string
    subCategoryName:string
    enabled:boolean = true
    isAddedAsNew:boolean = false
    transform = new Transform()
    productId:number
    categoryId:number
    originalProductName:string
    productInstanceName:string

    isAttachedToWall:boolean = false
    isWallMounted:boolean = false
    normal:Vector3 = new Vector3(0,0,1)
  
    configuration:any = {}
    moduleConfiguration:Configuration = new Configuration()
    subCategory: string;

    sourceClientName:string = ""
    additionalConfiguration:any = {
      configHash:"",
      configId:""
    }
  
  
    constructor(product:any,originalProductName:string = "",productInstanceName:string = "",enabled:boolean = true,isAddedAsNew:boolean = true){
      this.originalProductName = stringWithoutWhiteSpace(originalProductName)
      this.productInstanceName = stringWithoutWhiteSpace(productInstanceName)

      this.productId = product.product_id || product.productId || ""
      this.categoryId = product.category_id || product.categoryId || ""
      this.productName = product.product_name?.trim() || product.productName?.trim() || ""
      this.categoryName = product.category_name || product.categoryName || ""
      this.subCategoryName = product.sub_category_name || product.subCategoryName || ""
      this.subCategory = product.sub_category_name || product.subCategoryName || ""
      this.enabled = enabled
      this.isAddedAsNew = isAddedAsNew
    

      // let productParts = foregroundParts[this.categoryName]
      // if (productParts?.length) {
      //   for (const partName of productParts) {
      //     this.configuration[partName] = new TextureInfo()
      //   }
      // }
    }

    setClientName(product:any){
      let clientName = applicationConfig?.clientName
      let storefront_name = product?.storefront_name
      if(storefront_name){
        clientName = storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
      }
      this.sourceClientName = clientName
    }

    updateTextureWithAvailableParts(modelObject:any){
      this.configuration = {}
      let productParts = foregroundParts[this.categoryName]
      if (productParts?.length) {
        modelObject.traverse(mesh => {
          if(mesh.isMesh){
            let partName = getOriginalPartName(this.categoryName,mesh.name)
            this.configuration[partName] = new TextureInfo()
          }
        })
      }
    }

    updateProductTransform(modelObject:any){
        var target = new Vector3()
        let rotation = new Quaternion()
        modelObject.getWorldPosition(target)
        modelObject.getWorldQuaternion(rotation)
        // this.categoryName = product.categoryName
        // this.subCategoryName = product.subCategoryName
        // this.productName = productName
        // this.isAddedAsNew = true
        this.transform.updateTransform(target,rotation,modelObject.scale,modelObject.userData.rotationDelta)
      }
  
    getTransform(){
      return this.transform
    }

    getTexture(partName:string){
        return this.configuration[partName]
    }

    updateNormal(normal:Vector3){
      this.normal = normal
    }

  }
  
  export class TextureInfo{
    companyName:string =  "Default"
    collectionName:string =  "Default"
    materialCode:string =  "Default"
    materialType:string = "Default"
    materialId:number = 0
    uvScale:number = 0
    roughness:number = 0
    metalness:number = 0
    specular:number = 0
    clearcoat:number = 0
    sheen:number = 0
    transmission:number = 0
    normal_strength:number = 1
    normalStrength:number = 1
    partName:string = ""
    baseColourHexCode:string = ""
  
    constructor(textureInfo:any = null){
      if(textureInfo){
        this.companyName = textureInfo.companyName || textureInfo.company_name 
        this.collectionName = textureInfo.collectionName || textureInfo.collection_name
        this.materialCode = textureInfo.materialCode || textureInfo.material_code
        this.materialType = textureInfo.materialType || textureInfo.material_type

        this.materialId = textureInfo.materialId || textureInfo.material_id || 0
        this.uvScale = textureInfo.uvScale || textureInfo.uv_scale || 0
        this.roughness = textureInfo.roughness || 0
        this.metalness = textureInfo.metalness || 0
        this.specular = textureInfo.specular || 0
        this.sheen = textureInfo.sheen || 0
        this.clearcoat = textureInfo.clearcoat || 0
        this.transmission = textureInfo.transmission || 0
        this.baseColourHexCode = textureInfo.baseColourHexCode || "Default"
      }
    }

    updateTextureInfo(textureInfo:any){
        this.companyName = textureInfo.companyName
        this.collectionName = textureInfo.collectionName
        this.materialCode = textureInfo.materialCode
        this.materialType = textureInfo.materialType

        this.uvScale = textureInfo.uvScale
        this.roughness = textureInfo.roughness
        this.metalness = textureInfo.metalness
        this.specular = textureInfo.specular
        this.clearcoat = textureInfo.clearcoat
        this.sheen = textureInfo.sheen
        this.transmission = textureInfo.transmission
        this.normal_strength = textureInfo.normal_strength
        this.baseColourHexCode = textureInfo.baseColourHexCode || "Default"
    }
  }
  
  export class Transform{
    position:Vector3 = new Vector3()
    rotation:any = new Quaternion()
    scale:Vector3 = new Vector3(1,1,1)
    rotationDelta:Number = 0
  
    constructor(rotation:any = new Quaternion()){
      this.rotation = rotation
    }
  
    updateTransform(position:Vector3,rotation:Quaternion,scale:Vector3,rotationDelta:number){
      this.position = position
      this.rotation = rotation
      this.scale = scale
      this.rotationDelta = rotationDelta
    }

    updatePosition(position:Vector3){
      this.position = position
    }

    updateRotation(rotation:Quaternion){
      this.rotation = rotation
    }

    clone(trans:Transform){
      let transform = new Transform()
      transform.updateTransform(trans.position,trans.rotation,trans.scale,0)
      return transform
    }

  }

  export class camera{
    // #change
    // Remove aspect
    aspect:number = 4/3
    fov:number = 75
    transform:Transform = new Transform(new Quaternion(0,0,0,1))
    areaName:string = "Default"
    renderType:string = "2d"
    cameraId:number = Number(new Date)
    near:number = 0.025
    cameraName:string = ""
    targetPosition:Vector3 = new Vector3()
    // sceneCamera:Camera = null
    polarAngle:number = 0
    azimuthAngle:number = 0
    isRendered:boolean = false
    renderTime:number = 0
    cropPoints:any = {
      minX:0,
      maxX:0,
      minY:0,
      maxY:0
    }

    constructor(areaName:string,renderType:string = "2d",cameraId:number = Number(new Date),currCamera:any = null){
      this.areaName = areaName || "Other"
      this.renderType = renderType
      this.cameraId = cameraId
      this.cameraName = areaName + cameraId

      if(currCamera){
        this.transform.updateTransform(currCamera.transform.position,currCamera.transform.rotation,currCamera.transform.scale,0)
        // this.sceneCamera = currCamera.sceneCamera
        this.fov = currCamera.fov
        this.cropPoints = currCamera.cropPoints
        this.polarAngle = currCamera.polarAngle
        this.azimuthAngle = currCamera.azimuthAngle
        this.near = currCamera.near
        this.isRendered = currCamera.isRendered
        this.renderTime = currCamera.renderTime
      }
    }


    updateRenderType(type:string){
      this.renderType = type
    }

    updateArea(name:string){
      this.areaName = name
      this.cameraName = name + this.cameraId
    }

    updateCameraName(name:string){
      this.cameraName = name + this.cameraId
    }

    updateCameraId(cameraId:number){
      this.cameraId = cameraId
      this.cameraName = this.areaName + cameraId
    }

    // updateSceneCamera(sceneCam:Camera){
    //   this.sceneCamera = sceneCam
    // }

    updateNear(val:number){
      this.near = val
    }

    updateFov(val:number){
      this.fov = Number(val)  || 45
    }

    setIsRendered(val:boolean){
      this.isRendered = val            
    }

    // updateTargetPosition(sceneCamera:any){
    //   let target = new Vector3()
    //   sceneCamera.getWorldDirection(target)
    //   target.multiplyScalar(1).add(sceneCamera.position)
    //   this.targetPosition = target
    // }

    setCropPoints(minX:number,maxX:number,minY:number,maxY:number){
      // let delta = 0
      // if(!customizerConfig.isChangedHeight){
      //   delta = -54
      // }
      this.cropPoints.minX = Math.round(minX) 
      this.cropPoints.maxX = Math.round(maxX)
      this.cropPoints.minY = Math.round(minY) 
      this.cropPoints.maxY = Math.round(maxY)
    }

    updateCropPoints(points:any){
      this.cropPoints = points
    }

    setRotation(polarAngle:number,azimuthAngle:number){
      this.polarAngle = polarAngle 
      this.azimuthAngle = azimuthAngle
    }

    setRenderTime(startTime:number,endTime:number){
      this.renderTime = endTime - startTime
    }
  }
  