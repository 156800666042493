import $ from "jquery"
import { showGlobalToast } from "../../UI_methods/global"
import { attachModules, detachModule } from "../common"
import { dimensions, fitToModuelsScene, group, scene } from "./customizein3d"
import { createShadow } from "./shadows"


var CURR_SWING_OBJECT = null
var stand = null

export function toggleSwingStand(){
    let configuration = CURR_SWING_OBJECT.userData.configuration
    const isTrue = configuration.additionalConfiguration.hasStand
    if(isTrue){
        hideSwingStand()
    }else{
        showSwingStand()
    }
}

export function showSwingStand(){
    try {
        // stand = group.children.find(currChild => currChild.name.toLowerCase().includes("stand"))
        // stand.visible = true
        attachModules([stand],group)
        let config = CURR_SWING_OBJECT.userData.configuration
        config.additionalConfiguration.hasStand = true
        $("#toggleStandButton").find(".text").text("Remove Stand")
        toggleFinishPartButtonVisiblity()
        dimensions.hideDimensions()
        // setTimeout(() => {
        //     createShadow()
        //     }, 500);
        fitToModuelsScene()    
    } catch (error) {
        console.log(error)
        
    }
   
}

export function resetSwingObjects(){
    if(CURR_SWING_OBJECT){
        detachModule(CURR_SWING_OBJECT)
        scene.remove(CURR_SWING_OBJECT)
    }
    if(stand){
        detachModule(stand)
        scene.remove(stand)
    }
}

export function isSwingStandAttach(object:any){
    let config = object?.userData?.configuration
    return config.additionalConfiguration?.hasStand
}

export function hideSwingStand(object:any = null){
    try {
        // stand = CURR_SWING_OBJECT?.children.find(currChild => currChild.name.toLowerCase().includes("stand"))
        if(object){
            CURR_SWING_OBJECT = object
            stand = CURR_SWING_OBJECT.children.find(currChild => currChild.name.toLowerCase().includes("stand"))
            detachModule(stand)
            attachModules([stand],group)
        }
        detachModule(stand)
        // stand.visible = false
        let config = CURR_SWING_OBJECT.userData.configuration
        config.additionalConfiguration.hasStand = false
        $("#toggleStandButton").find(".text").text("Add Stand")
        toggleFinishPartButtonVisiblity()
        // setTimeout(() => {
        // createShadow()a
        // }, 500);
        dimensions.hideDimensions()
        fitToModuelsScene()
    } catch (error) {
        console.log(error)
    }
    
}

export function attachStandForUpdateDimensions(object:any){
    detachModule(stand)
    attachModules([stand],object)
}

export function detachStandForUpdateDimensions(){
    // detachModule(stand)
    // attachModules([stand],group)
    hideSwingStand()
}

export function toggleFinishPartButtonVisiblity(){
    try {
        if(CURR_SWING_OBJECT){
            let config = CURR_SWING_OBJECT.userData.configuration
            let value = config.additionalConfiguration.hasStand
            let parentContainer = $(".part-list-container")
              parentContainer.children().each(function() {
                  let currPartName = $(this).data("part-name") 
                  if (currPartName.toLowerCase().includes("stand")) {
                    if(value){
                        $(this).fadeIn()
                    }else{
                        $(this).fadeOut()
                    }
                  }
            });
        } 
    } catch (error) {
        
    }
    
    
}