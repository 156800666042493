import CameraControls from "camera-controls";
import $ from "jquery";
import { BoxGeometry, BufferGeometry, Camera, Group, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, Object3D, Quaternion, Raycaster, TextureLoader, Vector3 } from "three";
import { getForegroundSavedConfig, updateVisualizerConfig } from "../../../services/api";
import { addActiveClassOnTexture, getWindowHeight, hideComponentLoader, removeActiveClassFromAllTextures, showToast, updateLoaderProgress } from "../../UI_methods/global";
import { untarAndSaveToCache } from "../../cache/cache";
import { getItemFromLocalStorage } from "../../cache/localstorage";
import { ProjectConfiguration, TextureInfo, Transform } from "../../customizer/ProjectConfiguration";
import { getUrlPrefix } from "../../customizer/customizer";
import { foregroundParts, numbers_array } from "../../data";
import { applicationConfig, disposeVariables, getFilteredArray, getMaterialTypeArrayForPartname, getObjectByParameter, logger, stringWithoutWhiteSpace, waitFor } from "../../methods";
import { applyEnvMapInModel, detachModule, getModelBoundingBox, setModelBoundingBox, updateGroupCenter, updateModelBoundingBox } from "../common";
import { generatedEnvironmentMap } from "../enviornment";
import { updateDragIconPosition } from "../modules/annotations";
import { addModule } from "../modules/customizein3d";
import { addImage, getMaterialUpdated, getModelPositions, getObjectDimensionPosition, saveScreenShotToS3 } from "../modules/helper";
import { CURR_SELECTED_PRODUCT } from "../raycasting";
import { createProductAnnotations } from "./annotations";
import { AREA_MODEL_ORIGIN, CURR_AREA_GROUP, CURR_SELECTED_MESHES, FLOOR, IS_SHELL_MODE, IS_SPACE_PLANNER_MODE, PARTS_MAPPING, PRODUCTS_LIST, addModelToScene, areaModel, camera, canvas, composer, controls, customizerConfig, disableSpacePlanner, enableFloorplanMode, enableSpacePlanner, floorplanner, groundGroup, kitchenPlanner, labelRenderer, maskForCeiling, mouse, orthoCamera, product3DModelsList, projectConfiguration, renderer, scale, scene, undo, updateAreaModelFinish, updateCanvasDimensions } from "./area3dModel";
import { CURR_DRAGGING_PRODUCT, isDraggingProduct } from "./dragging";
import { addDraggedProduct,  addProduct, addStoreProductForRender, createGroupForModule } from "./modules";
import { CURR_SELECTED_PRODUCT_GROUP, isProductConfigureMode } from "./productConfigure";
import { areaWallsBoundingBoxMapping, autoWallsHiding, createSnappedPlane, enableWallsHiding } from "./walls";
import { STORE } from "../../store/storeConfiguration";
import { postProductRenderModeAction } from "./floorplan/render2d";

var textureLoader = new TextureLoader();
var initialBoxHelperColor = 0x0058a3
var heightPercent = 0.5


  export function getOriginalPartName(categoryName:string,partName:String) {
    let productParts = foregroundParts[categoryName]
    partName = productParts?.find(part=>partName?.includes(part) || part?.includes(partName))
    return String(partName)
  }

  export function getOriginalCategoryName(customizerConfig:any,productName:string) {
    return applicationConfig?.data?.productsList?.find(product=>stringWithoutWhiteSpace(product?.product_name)?.includes(stringWithoutWhiteSpace(productName)) || stringWithoutWhiteSpace(productName)?.includes(stringWithoutWhiteSpace(product?.product_name)))?.category_name || null
    // return applicationConfig?.data?.productsList?.find(product=>stringWithoutWhiteSpace(product?.product_name)?.startsWith(productName) || stringWithoutWhiteSpace(productName)?.startsWith(product?.product_name))?.category_name
  }

  export function getProductIdFromName(customizerConfig:any,productName:string) {
    return applicationConfig?.data?.productsList?.find(product=>product?.product_name?.startsWith(productName) || productName?.startsWith(product.product_name) )?.product_id || null
  }

  export function getOriginalSubCategoryName(customizerConfig:any,productName:string) {
    return applicationConfig?.data?.productsList?.find(product=>stringWithoutWhiteSpace(product?.product_name).startsWith(productName) || productName.startsWith(stringWithoutWhiteSpace(product.product_name)))?.sub_category_name || null
  }

  export function getOriginalProductName(customizerConfig:any,productName:string) {
    return applicationConfig?.data?.productsList?.find(product=>stringWithoutWhiteSpace(product?.product_name).includes(productName) || stringWithoutWhiteSpace(productName).includes(product?.product_name))?.product_name || productName
  }

  export function getProductNameWithoutVariant(productName:string){
    let array = productName.split("")
    let lastIndex = array.length - 1
    if(numbers_array.includes(array[lastIndex]) && array[lastIndex - 1] === "_"){
      array.pop()
      array.pop()
      return array.join("")
    }
    return productName
  }
  

  export async function updateMaterialForSelectedObjects(customizerConfig:any,textureInfo:any,mesh:any) {
    let productName:string = ""
    let categoryName:string = ""
    let partName:string = ""
    // let object = CURR_SELECTED_PRODUCT || getProductFromAreaModel(mesh)
    if(mesh){
      categoryName = CURR_SELECTED_PRODUCT?.userData?.categoryName
      partName = getOriginalPartName(categoryName,mesh.name)
      if(CURR_SELECTED_PRODUCT){
        undo.add("finish",{meshes:[mesh],material:mesh.material,textureInfo:textureInfo,partName:partName,productInstanceName:CURR_SELECTED_PRODUCT.userData.productInstanceName})
      }
      productName = CURR_SELECTED_PRODUCT?.userData?.productName || mesh.parent.name
      if(CURR_SELECTED_PRODUCT){
        projectConfiguration.updateFinish(CURR_SELECTED_PRODUCT?.userData?.originalProductName,CURR_SELECTED_PRODUCT?.userData?.productInstanceName,partName,textureInfo)
      }
      return
    }
    // let allMeshes:any = composer.outlinePass.selectedObjects
    let allMeshes:any = CURR_SELECTED_MESHES
    if(allMeshes.length){
      if(CURR_SELECTED_PRODUCT){
        //For undo
        let texture = allMeshes[0].material.userData.textureInfo 
        if(!texture){
          texture = new TextureInfo()
        }
        undo.add("finish",{meshes:allMeshes,material:allMeshes[0].material,textureInfo:texture,partName:partName,productInstanceName:CURR_SELECTED_PRODUCT.userData.productInstanceName})
      }
      allMeshes.forEach(async (mesh:any)=>{
        categoryName = CURR_SELECTED_PRODUCT?.userData?.categoryName || getOriginalCategoryName(customizerConfig,mesh.parent.parent.name) 
        let partName = getOriginalPartName(categoryName,mesh.name)
        let materialTyesForPart = getMaterialTypeArrayForPartname(categoryName,partName) 
        if(materialTyesForPart.includes(textureInfo?.materialType)){
          await getMaterialUpdated(textureLoader,textureInfo,mesh?.material).then(result=>{
            productName =  CURR_SELECTED_PRODUCT?.userData?.productName || mesh.parent.name
            if(CURR_SELECTED_PRODUCT){
              projectConfiguration.updateFinish(CURR_SELECTED_PRODUCT?.userData?.originalProductName,CURR_SELECTED_PRODUCT?.userData?.productInstanceName,partName,textureInfo)
            }
          })
        }
      })
    }
  }

  export   async function applySavedFinishConfig(object:any){
    try {
      // let savedConfiguration = customizerConfig.getAreaModelConfiguration()
      // if(isProductExistsInTheList(projectConfiguration.projectProducts,object)){
      let categoryName = object.userData.categoryName
      object.traverse(async (mesh) => {
        if(mesh.isMesh){
          let partName = mesh.name
          partName = getOriginalPartName(categoryName,partName)
          let areaName = projectConfiguration.getAreaNameFromProduct(object.userData.productInstanceName)
          let textureInfo = projectConfiguration.getTextureObject(object.userData.originalProductName,object.userData.productInstanceName,partName,areaName)
          
          //Download if not downloaded
          let companyName = textureInfo?.company_name || textureInfo?.companyName 
          let collectionName = textureInfo?.collection_name || textureInfo?.collectionName 

          if(companyName !== "Default"){
            let key = applicationConfig.awsConfig.getTarFileKey("thumbnails",{companyName:companyName,collectionName:collectionName})
            await untarAndSaveToCache(applicationConfig.awsConfig,`thumbnails/OVL/${companyName}/${collectionName}`,key)
          }

          
          if(textureInfo){
            if(textureInfo && textureInfo?.materialType != "Default" && partName != "undefined"){
              updateAreaModelFinish(textureInfo,mesh)
                partName = getOriginalPartName(categoryName,mesh.name)
                let materialTyesForPart = getMaterialTypeArrayForPartname(categoryName,partName) 
                if(materialTyesForPart.includes(textureInfo?.materialType)){
                  getMaterialUpdated(textureLoader,textureInfo,mesh?.material).then(result=>{
                    if(CURR_SELECTED_PRODUCT){
                    }
                  })
                }
            }
          }
        }
      })
      // }
    } catch (error) {
      console.log(error)
    }
  }

  
 
  export function getCurrProductParts(categoryName:string) {
    let parts = new Set()
    // let materialTypes:Array<string> = []
    CURR_SELECTED_PRODUCT.children.forEach(mesh => {
      if(mesh.isMesh){
        let partName = getOriginalPartName(categoryName,mesh.name)
        //Duplicacy of materials
        // if(!materialTypes.includes(JSON.stringify(getMaterialTypes(applicationConfig?.data.objectMaterialTypes,categoryName,partName)))){
          parts.add(partName)
          // let currMaterialTypes = getMaterialTypes(applicationConfig?.data.objectMaterialTypes,categoryName,partName) 
          // materialTypes.push(JSON.stringify(currMaterialTypes))
        // }
      }
    });
    return Array.from(parts)
  }

  export function openMaterialsWindowFromCurrentProduct() {
    let config = CURR_SELECTED_PRODUCT.userData.configuration
    let categoryName = config?.categoryName || CURR_DRAGGING_PRODUCT.categoryName
    let productDetails = {
        categoryName : categoryName,
        partsList:getCurrProductParts(categoryName)
    }
    applicationConfig.functions.customizer.openMaterialWindowFromCanvas(productDetails)
  }
 
  export function isProductExistsInTheList(productsList:Array<any>,object:any){
    return projectConfiguration?.projectProducts?.find(product=>object.name?.includes(stringWithoutWhiteSpace(product.product_name)) || object.userData.isAddedAsNew ) || null
  }

  export function isProductExistsInArea(projectProducts:Array<any>,areaName:string,productNameFromModel:string){
    // let areaProducts = getFilteredArray(projectProducts,"area_name",areaName)
    
    // Area model contains only products
    let isPresent = false
    product3DModelsList.forEach(currModel=>{
      if(currModel?.name?.includes(productNameFromModel) || productNameFromModel?.includes(currModel.name) || stringWithoutWhiteSpace(currModel?.name).includes(stringWithoutWhiteSpace(productNameFromModel))){
        isPresent = true
      }
    })
    return isPresent
    // return areaProducts?.find(product=>productNameFromModel?.includes(stringWithoutWhiteSpace(product.product_name))) || null
  }


  export function setProductModules() {
    // event.preventDefault()
    // $(".tab-content").removeClass("--is-active")
    // $("#customizerProductModules").addClass("--is-active")
    let productName = getOriginalProductName(customizerConfig,CURR_SELECTED_PRODUCT.userData.productName)
    let productId = getProductIdFromName(customizerConfig,productName)
    if(productName?.includes("Pepper")){
      productId = 3376
    }
    if(productId){
        customizerConfig.functions.setCurrProductId(productId)
        customizerConfig.functions.setCurrProductName(productName)
    }

  }

  export function setProductParts() {
    let parts = new Set()
    let partName = "Part"
    let i = 1
    CURR_SELECTED_PRODUCT.children.forEach(mesh=>{
      let productName = CURR_SELECTED_PRODUCT?.name
      let categoryName = CURR_SELECTED_PRODUCT.userData.categoryName
      if(mesh.isMesh){
        parts.add(getOriginalPartName(categoryName,mesh.name))
        PARTS_MAPPING[partName+i] = mesh.name
        i = i + 1
      }
    })
    customizerConfig.functions.setProductParts(Array.from(parts))
    customizerConfig.functions.setCurrSelectedPart("No part selected")
  }



  
export async function loadModelToTheScene(loader:any,modelSrc:string) {
  return new Promise(async (resolve,reject)=>{
     await loader.load(modelSrc,
      function ( gltf:any ) {
            // updateLoaderProgress("canvasLoader",1,1,"Loaded...")
            resolve(gltf)
        },
        function ( xhr:any ) {
          // console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
          // updateLoaderProgress("canvasLoader",xhr.loaded,xhr.total,"Loading model...")
        },
        function ( error:any ) {
            reject(error)
        }
    );
  })
}

export async function prepareModel(modelObject:any,areaName:string) {
  updateLoaderProgress("canvasLoader",1,1,"Applying config..")
  modelObject.traverse((object) => {
      if(object.material){
          if(object.name.toLowerCase().includes("floor_")){
            const originalMaterial = object.material;
            const clonedMaterial = originalMaterial.clone();
            clonedMaterial.envMap = generatedEnvironmentMap.texture;
            object.material = clonedMaterial;
          }
          object.material.envMap = generatedEnvironmentMap.texture
          object.castShadow = true
          object.receiveShadow = true
          object.material.needsUpdate = true
      }else{
        
      }
  });

  if(!IS_SHELL_MODE){
    product3DModelsList.forEach(object => {
      let productNameFromModel = object.name
      if(isProductExistsInTheList(projectConfiguration.projectProducts,object) && !productNameFromModel.toLowerCase().includes("wall")){
        let productInstance = projectConfiguration.getAreaObject(applicationConfig.areaName).getProductConfig(stringWithoutWhiteSpace(getOriginalProductName(null,object.name)),object.name)
        productInstance.updateProductTransform(object)
      }
    })
    projectConfiguration.updateLocalStorage()
  }
}


export function postProductLoadActions(object:Object3D) {
    if(object.position.y > 20){
      object.userData.isWallProduct = true
    }else{
      object.userData.isWallProduct = false
    }
    applySavedFinishConfig(object)
    object.traverse(object => {
      setModelBoundingBox(object)
    })
}
 

export function setModelBoundaries(object:any) {
  let data = getObjectDimensionPosition(null,groundGroup)
  object.userData.limit = {
    min: new Vector3(data.positions.min.x,object.position.y,data.positions.min.z),
    max: new Vector3(data.positions.max.x,object.position.y,data.positions.max.z)
  };
  object.userData.update = function(){
    object.position.clamp(object.userData.limit.min, object.userData.limit.max);
  }
  PRODUCTS_LIST.push(object)
}

export function setWallProductBoundaries(object:any) {
  if(object.userData.snappedWall){
    let wall = object.userData.snappedWall
    // let center = new Vector3(
    //   object.userData.boundingBox.min.x,
    //   object.userData.boundingBox.min.y,
    //   object.userData.boundingBox.min.z
    // )
    // let areaName = getAreaFromPoint(center)
    // let planes = getFrontAndBackPlane(wall.name.toLowerCase() === "skirting" ? wall.parent : wall,areaName)
    let data = getObjectDimensionPosition(null,wall)
    object.userData.limit = {
      min: new Vector3(data.positions.min.x,object.position.min.y,data.positions.min.z),
      max: new Vector3(data.positions.max.x,object.position.max.y,data.positions.max.z)
    };
    object.userData.update = function(){
      object.position.clamp(object.userData.limit.min, object.userData.limit.max);
    }
  }
}

export function clampObjectToWall(object:Object3D) {
  let parentObject = object.userData.snappedWall
	if(!parentObject){
		createSnappedPlane(object)
		parentObject = object.userData.snappedWall
	}
  if(parentObject){
    let data = getObjectDimensionPosition(null,parentObject)  
    let min = data.positions.min
    let max = data.positions.max
    if(object.position.x > max.x){
      object.position.x = max.x
    }
    if(object.position.x < min.x){
      object.position.x = min.x
    }
  
    if(object.position.y > max.y - object.userData.dimensions.dimY){
      object.position.y = max.y - object.userData.dimensions.dimY
    }
    if(object.position.y < min.y){
      object.position.y = min.y
    }
  
    if(object.position.z  > max.z - object.userData.dimensions.dimZ){
      object.position.z = max.z - object.userData.dimensions.dimZ
    }
    if(object.position.z < min.z){
      object.position.z = min.z
    }
  }
}



export function clampObjectToArea(object:Object3D) {
  if(CURR_AREA_GROUP){
    let objectData = getObjectDimensionPosition(null,object)
    let objectCenter = objectData.center
    let objectPos = objectData.positions
    let data = kitchenPlanner.clampBox
    let min = data.positions.min
    let max = data.positions.max
  }
}

export function addActiveClassToTexture(customizerConfig:any,productName:string,partName:string) {
  let config = getConfiguration(customizerConfig?.projectName,customizerConfig?.areaName) || {}
  let productConfig = config[productName]
  if(productConfig){
    let materialDetails = productConfig[partName]
    let uiTextures = document.querySelectorAll(".texture-label-container")
  
    for (let i = 0; i < uiTextures.length; i++) {
      const texture = uiTextures[i];
  
      if(texture.getAttribute("data-company-name") === materialDetails.companyName
      && texture.getAttribute("data-collection-name") === materialDetails.collectionName
      && texture.getAttribute("data-material-code") === materialDetails.materialCode
      ){
        removeActiveClassFromAllTextures()
        addActiveClassOnTexture(texture)
      }
    }
  }
 

}

export function getConfiguration(projectName:string,areaName:string) {
  let configuration = getItemFromLocalStorage(`Customizer_3d_${projectName}`) || {}
  return configuration[areaName]
}



export function hideElements(areaModel:any) {
  areaModel.traverse((object) => {
    if(object.isMesh || object.name.includes("Background_Door")){
        if(object.name.toLowerCase().includes("wallpaper")  
        // || object.name.toLowerCase().includes("door") 
        || object.name.toLowerCase().includes("cladding") 
        // || object.name.toLowerCase().includes("wall") 
        // || object.name.toLowerCase().includes("skirting")
        )
        {
          object.visible = false
        }
    }
  });
}

export function displayWalls(areaModel:any) {
  areaModel.traverse((object) => {
    if(object.isMesh || object.name.includes("Background_Door")){
        if(object.name.includes("Wall") || object.name.includes("Door") || object.name.includes("Floor")){
          object.visible = true
        }
    }
  });
}



export function addInfoImage(scene,textureLoader:any,positions:any,name:string) {
  let mainContainer = $("#area3DSceneWrapper")
  let info = $("<div></div>",{
      class:"info-image-container top-left",
  }).text("info")

  info.css({"top":positions.y,"left":positions.x})

  mainContainer.append(info)
  
}



export function getProductsGroup(customizerConfig:any) {
  let group = new Group()
  let products = customizerConfig?.projectProductsList
  products = getFilteredArray(products,"area_name",customizerConfig?.areaName)
  product3DModelsList.forEach(object => {
    let productNameFromModel = object.name
    if(isProductExistsInTheList(products,object) && !productNameFromModel.toLowerCase().includes("wall")){
      group.add(object.clone())
    }
  })
  disposeVariables([products])
  return group
}

export function createEventListeners(element:any,camera:any,renderer:any) {
    window.addEventListener("resize",function(){
      updateCanvasDimensions()
      composer.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    })
}

export function drawOutlineBelowModel(object:any,boundingBox:any,SELECTED_OBJECT_OUTLINE_NAME:string) {

  let positions = getModelPositions(object,boundingBox)
  let posY = 15

  let points = []

  for (let i = 1; i < posY; i++) {
    
    points.push(
      new Vector3(positions.min.x,i/8,positions.min.z),
      new Vector3(positions.max.x,i/8,positions.min.z),
      new Vector3(positions.max.x,i/8,positions.max.z),
      new Vector3(positions.min.x,i/8,positions.max.z),
      new Vector3(positions.min.x,i/8,positions.min.z)
    )
    
  }
  createLineFromPoints(scene,points,SELECTED_OBJECT_OUTLINE_NAME)
}


export function createLineFromPoints(scene:any,points:Array<any>,SELECTED_OBJECT_OUTLINE_NAME:string = "") {
  // let group = new Group()
  const material = new LineBasicMaterial({
    color: 0x36d6d9,
    // color: 0x000000,
    linewidth: 1,
    linecap: 'round', //ignored by WebGLRenderer
    linejoin:  'round' //ignored by WebGLRenderer
  } );
  const lineGeometry = new BufferGeometry().setFromPoints( points )
  const line = new Line( lineGeometry, material )
  line.name = SELECTED_OBJECT_OUTLINE_NAME
  scene.add(line)
}


export function saveConfiguration(isUpdate:boolean,configuration:any,configName:string = projectConfiguration?.configName) {
  logger?.info("customizer","updateconfig",`Updating config` )

  $("#saveConfigText").text("Saving...")

 
  return new Promise((resolve,reject)=>{
    let data = {
      clientId:applicationConfig?.clientId,
      configName:configName || "Default",
      projectId:applicationConfig?.projectId || null,
      productId:configuration?.product?.productId || null,
      configuration:configuration,
      isUpdate:isUpdate,
      isCreatedRender:configuration.isRenderCreated || 0,
      areAreasDefined:configuration.areAreasDefined || 0,
      configId:configuration.configId || null,
      title:$("#configTitle").val() || "Default",
      description:$("#configDescription").val() || "Default",
    }

    updateVisualizerConfig(data)
    .then((response:any) => {
      if(!response.data.error){
        $("#saveConfigText").text("Saved")
        showToast("Config saved successfully",3000)
        logger?.info("customizer","updateconfig",`Config updated` )
        configuration.configName = configName
        let productId = 0
        let projectId = 0
        if(STORE.currProduct){
          productId = STORE.currProduct.product_id
        }
        getForegroundSavedConfig(projectId,productId).then(data=>{
          applicationConfig.data.customizerSavedConfigs = data
          if(applicationConfig.functions?.customizer?.refreshGallery){
            applicationConfig.functions.customizer.refreshGallery()
          }
          //If save as new load the saved configs 
          if(isUpdate){
            saveScreenShotToS3()
          }
          resolve(response)
        }).catch(err=>{
          console.log(err)
          resolve(response)
        })
      }else{
        console.log(response)
        showToast("Error in creating config",3000,"error")
        reject(response)
      }
      // hideComponentLoader("canvasLoader")
    })
    .catch((error:any) => {
      console.log(error)
      showToast("Error in creating config",3000,"error")
      logger?.info("customizer","updateconfig",`Error in update config` )
      reject(error)
      // hideComponentLoader("canvasLoader")
    });
  })
}

 

export function printSummary(id:string = "productSummary") {
  var divContents = document.getElementById(id).innerHTML;
  var a = window.open('', '', 'height=500, width=500');
  a.document.write('<html><head>');
  a.document.write('<link rel="stylesheet" href="https://opusassets.s3.ap-south-1.amazonaws.com/public/style/ui-components/design.css" type="text/css" />');
  a.document.write('<link rel="stylesheet" href="https://opusassets.s3.ap-south-1.amazonaws.com/public/style/common/summary.css" type="text/css" />');
  a.document.write('<link rel="stylesheet" href="https://opusassets.s3.ap-south-1.amazonaws.com/public/style/style.css" type="text/css" />');
  a.document.write('<link rel="stylesheet" href="https://opusassets.s3.ap-south-1.amazonaws.com/public/style/ui-components/borders.css" type="text/css" />');
  a.document.write('<link rel="stylesheet" href="https://opusassets.s3.ap-south-1.amazonaws.com/public/style/ui-components/tables.css" type="text/css" />');
  a.document.write('<link rel="stylesheet" href="https://opusassets.s3.ap-south-1.amazonaws.com/public/style/cssvariables.css" type="text/css" />');


  a.document.write('<link rel="stylesheet" href="https://opusassets.s3.ap-south-1.amazonaws.com/public/style/store/store.css" type="text/css" />');
  a.document.write('<link rel="stylesheet" href="https://opusassets.s3.ap-south-1.amazonaws.com/public/style/store/product-customizer.css" type="text/css" />');
  a.document.write('<link rel="stylesheet" href="https://opusassets.s3.ap-south-1.amazonaws.com/public/style/store/cart.css" type="text/css" />');
  
  
  a.document.write('<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" type="text/css" />');
  a.document.write('</head><body>');
  a.document.write(divContents);
  a.document.write('</body></html>');
  a.document.close();
  setTimeout(() => {
    a.document.close();
    a.print(); 
  }, 1000);
}

export function postAddingActions(modelObject:any) {
  modelObject.userData.isAddedAsNew = true
  modelObject.userData.dimensions = getObjectDimensionPosition(null,modelObject).dimensions
  modelObject.userData.center = getObjectDimensionPosition(null,modelObject).center

  setModelBoundingBox(modelObject)
  updateGlassMaterial(modelObject)
  updateModelBoundingBox(modelObject)

  if(modelObject.position.y > (AREA_MODEL_ORIGIN.y + (0.5 * scale))){
    modelObject.userData.isWallProduct = true
  }else{
    modelObject.userData.isWallProduct = false
  }

  let dimYArea = areaWallsBoundingBoxMapping["all"].boundingBox.dimensions.dimY
  let dimYObj = modelObject.userData.dimensions.dimY
  if(dimYObj < dimYArea * heightPercent){
    modelObject.userData.isFlatSurfaceProduct = true
    customizerConfig.addFlatSurfaceProduct(modelObject)
  }
  if(isProductConfigureMode){
    updateDragIconPosition(CURR_SELECTED_PRODUCT_GROUP,renderer,orthoCamera)
  }
  applyEnvMapInModel(modelObject)
  createProductAnnotations()

  const groupForRotation = new Group()
  groupForRotation.name = `GroupForRotation_${Number(new Date())}`
  scene.add(groupForRotation)

  modelObject.userData.groupForRotation = groupForRotation
}

export function setAddedProductDetailsInUserData(modelObject:any,product:any) {
  modelObject.userData.productName = product.product_name || product.productName
  modelObject.userData.categoryName = product.category_name  || product.categoryName
  modelObject.userData.subCategoryName = product.sub_category_name || product.subCategoryName  
  modelObject.userData.isWallMounted = isWallMountedProduct(product.product_name || product.productName,modelObject.userData.categoryName) 
  modelObject.userData.isAttachedToWall = false  
}

export function updateGlassMaterial(object:any) {
  object.traverse((currMesh:any)=>{
    if(currMesh.isMesh){
    }
  })
}

export function addDragHandler(object:Object3D) {
  addDragImageToScene(object)
}


export function addDragImageToScene(object:Object3D) {
  let data = getObjectDimensionPosition(null,object)
  let dim = data.dimensions
  let width = 40
  let key = "public/icons/drag/drag.png"
  let urlPrefix = getUrlPrefix(key)
  let tarFileKey = "public/icons/drag/drag.tar.gz"
  let position = new Vector3(dim.dimX/2,dim.dimY/2,dim.dimZ)

  // const geometry = new CircleGeometry( 0.10, 32 );
  // const material = new MeshBasicMaterial( { color: 0x000000 } );
  // const circle = new Mesh( geometry, material );
  // circle.position.set(position.x,position.y,position.z)
  // circle.name = "dragHandler"
  // object.add( circle );
  // return

  untarAndSaveToCache(applicationConfig.awsConfig,urlPrefix,tarFileKey)?.then(data=>{
    addImage(object,textureLoader,key,position,width,"dragHandler")
  }).catch(err=>{
    addImage(object,textureLoader,key,position,width,"dragHandler")
  })
}

// convert click coords to world space
// get the position of a canvas event in world coords
export function getWorldCoordsOld(e) {
  // get x,y coords into canvas where click occurred
  var rect = canvas.getBoundingClientRect(),
      x = e.clientX - rect.left,
      y = e.clientY - rect.top;
  // convert x,y to clip space; coords from top left, clockwise:
  // (-1,1), (1,1), (-1,-1), (1, -1)
  var mouse = new Vector3();
  mouse.x = ( (x / canvas.clientWidth ) * 2) - 1;
  mouse.y = (-(y / canvas.clientHeight) * 2) + 1;
  mouse.z = 0.5; // set to z position of mesh objects
  // reverse projection from 3D to screen
  mouse.unproject(camera);
  // convert from point to a direction
  mouse.sub(camera.position).normalize();
  // scale the projected ray
  var distance = -camera.position.z / mouse.z,
      scaled = mouse.multiplyScalar(distance),
      coords = camera.position.clone().add(scaled);
  console.log(mouse, coords.x, coords.y, coords.z);

  return coords
}


export function getWorldCoords(event:any) {
  // mouse.y = mouse.y - area3DSceneWrapper.getBoundingClientRect().top + window.scrollY
  // mouse.x = mouse.x - area3DSceneWrapper.getBoundingClientRect().left + window.scrollX

  // mouse.x = (mouse.x / area3DSceneWrapper.offsetWidth!) * 2 - 1
  // mouse.y = -(mouse.y / area3DSceneWrapper.offsetHeight!) * 2 + 1

  mouse.x = (mouse.x  / window.innerWidth) * 2 - 1;
	mouse.y = - (mouse.y / window.innerHeight) * 2 + 1;

 // Make the sphere follow the mouse
  var vector = new Vector3(mouse.x, 0, mouse.y);
	vector.unproject( camera );
	var dir = vector.sub( camera.position ).normalize();
	var distance = - camera.position.z / dir.z;
	var pos = camera.position.clone().add( dir.multiplyScalar( distance ) );
  return pos
}

var raycaster = new Raycaster();
var far = new Vector3();
var direction = new Vector3();


export function checkCollision(currObject:any,direction:string,targetObject:Array<any> = null) {
  //Set bounding box will be used for collision detection
  // let products = customizerConfig?.projectProductsList
  // products = getFilteredArray(products,"area_name",customizerConfig?.areaName)
  // console.log(getModelBoundingBox(point))
  let result = []
  let objCenter = getObjectDimensionPosition(null,currObject).center

  // raycaster.set(new Vector3(objCenter.x,AREA_MODEL_ORIGIN.y+0.05,objCenter.z),getNormal(direction))
  raycaster.set(new Vector3(objCenter.x,objCenter.y,objCenter.z),getNormal(direction))
  try {
    if(targetObject){
      result = raycaster.intersectObjects(floorplanner.group.children,true)
    }else{
      result = raycaster.intersectObjects(areaModel.children,true)
    }
  } catch (error) {
    console.log(error)
  }
 
  return result


  // 001 = left = south
  //00-1 = right = north

  //100 = front = west
  //-100 = back = east


  // return null
  // areaModel.traverse(object => {
  //   if(object && object?.isMesh && currObject.uuid!=object.uuid){
  //     if(getModelBoundingBox(object).intersectsBox(getModelBoundingBox(point))){
  //       if(!object.name.toLowerCase().includes("wall_")){
  //         result = object
  //         return
  //       }
  //       result = object
  //     }
  //   }
  // })
  // return result
}


export function getNormal(direction:string){
	switch (direction.toLowerCase()) {
		case "top": // front
			return new Vector3( 0, 0, -1 )
		break;
		case "bottom": //back
			return  new Vector3( 0, 0, 1 )
		break;
		case "right": // right
			return new Vector3( 1, 0, 0 )
		break;
		case "left"://left
			return  new Vector3( -1, 0, 0 )
		break;
	}
}



export function setAreaModelPosition(object:any) {
  // let data = getObjectDimensionPosition(null,object)
  object.position.set(0,0,0)
  // object.position.set(-500,0,-500)
  // object.position.set(0 + data.dimensions.dimX / 2 + 20 , 0 ,0 + data.dimensions.dimZ / 2 - 20)
}

export function removePlanFromAreaModel(model:any) {
  model.children.forEach(object => {
    if(object.name.includes("Plan") || object.name.includes("Camera")){
      // areaModel.remove(object)
      object.parent.remove(object)
      if(object.isMesh){
        object.geometry.dispose()
        object.material.dispose()
      }
    }
  })
}

export function getRect() {
  const geometry = new BoxGeometry( 10, 10, 10 )
  const material = new MeshBasicMaterial( {color: 0x00ff00} )
  const cube = new Mesh( geometry, material )
  return cube
  scene.add( cube )
}


export async function spacePlannerRotationIssueTempSol() {
  enableSpacePlanner(50)
  await waitFor(500)
  disableSpacePlanner(50)
  hideComponentLoader("canvasLoader")
}

export function addProductInArea(product:any){
  showToast("Drag and drop product",2000)
  return
  if(!isDraggingProduct){
    addProduct(product)
  }
}

 
export function getProductFromAreaModel(object:any) {
  if(object?.userData?.configuration){
    return object
  }
  if(!object?.parent){
    return null
  }
  return getProductFromAreaModel(object?.parent)
}


export function isWallProduct(object:any) {
  return object.userData.isWallProduct
}

export async function addProjectProducts() {
  $(".area-products-loader").removeClass("--is-hide")
  if(projectConfiguration.isProductRenderMode){
    await addStoreProductForRender(projectConfiguration.productRenderModeConfig.product,projectConfiguration.productRenderModeConfig.productModelUrl)
    return 
  }
  return new Promise(async (resolve,reject)=>{
    let areaList = projectConfiguration.areasList
    
    // areaList = getFilteredArray(areaList,"area_name","Kitchen")
    for (let i = 0; i < areaList.length; i++) {
      const area = areaList[i];
      await addAreaProducts(area.area_name)
      if(i === areaList.length -1 ){
        customizerConfig.setIsProductsLoaded(true)
      }
    }
  })
 
}


export function addAreaProducts(areaName:string,loadedProductsCount:number = 0) {
  if(!IS_SHELL_MODE){
    return
  }
  return new Promise(async (resolve,reject)=>{

      let areaProducts = projectConfiguration?.getAreaObject(areaName)?.areaConfiguration || {}
      if(!Object.keys(areaProducts).length){
        $(`#areaLabel${areaName}`).find(".area-products-loader").addClass("--is-hide")
        resolve("done")
      }
      $(`#areaLabel${areaName}`).find(".area-products-loader").css("width",`0%`)

      for (const productKey in areaProducts) {
        let productInstancesList = areaProducts[productKey]
        for (const currProductInstanceKey in productInstancesList) {
            let product = productInstancesList[currProductInstanceKey]
            if(!product.enabled){
              continue
            }
            if(product.moduleConfiguration?.modules?.length){
              await addProductModulesFromConfig(product,areaName).then(data=>{
                resolve("done")
                // if(Number(currProductInstanceKey) === Object.keys(areaProducts).length-1){
                //   hideComponentLoader("loadShellProductsLoader")
                //   resolve("done")
                // }
              }).catch(err=>{
                resolve("done")
                // if(Number(currProductInstanceKey) === Object.keys(areaProducts).length-1){
                //   hideComponentLoader("loadShellProductsLoader")
                //   resolve(err)
                // }
              })
            }else{
              await addProductFromConfig(product,productKey,currProductInstanceKey,areaName).then(data=>{
                loadedProductsCount  = loadedProductsCount + 1
                let width = (loadedProductsCount/Object.keys(areaProducts).length) * 100
                width = width > 100 ? 100 : width
                $(`#areaLabel${areaName}`).find(".area-products-loader").css("width",`${Math.ceil(width)}%`)
                if(loadedProductsCount === Object.keys(areaProducts).length){
                  $(`#areaLabel${areaName}`).find(".area-products-loader").addClass("--is-hide")
                  resolve("done")
                }
                // if(Number(currProductInstanceKey) === Object.keys(areaProducts).length-1){
                //   hideComponentLoader("loadShellProductsLoader")
                //   resolve("done")
                // }
              }).catch(err=>{
                resolve("done")
                loadedProductsCount  = loadedProductsCount + 1
                // if(Number(currProductInstanceKey) === Object.keys(areaProducts).length-1){
                //   hideComponentLoader("loadShellProductsLoader")
                //   resolve(err)
                // }
              })
            }
            
        }
      }
    
    
  })
 
}


export function addProductFromConfig(product:any,productKey:string,currProductInstanceKey:string,areaName:string) {
  return new Promise(async (resolve,reject)=>{
    await addDraggedProduct(product,false).then((object:any)=>{

      let productName = product.product_name || product.productName
      let categoryName = product.category_name || product.categoryName
      addModelToScene(object)
      let transform = product.transform
      object.visible = true

      setProductNamesInModel(object,product.categoryName,productKey,currProductInstanceKey,areaName,true)
      object.userData.configuration = product

      if(transform){
        applyTransformInObject(object,transform)
      }

      if(isWallMountedProduct(productName,categoryName)){
        object.userData.isAttachedToWall = true
      }

      applyEnvMapInModel(object)
      applySavedFinishConfig(object)

      resolve("done")
      
    }).catch(err=>{
      resolve(err)
      
    })
  })
}

export async function addProductModulesFromConfig(productInstance:any,areaName:string) {
  if(areaName === "Kitchen"){
    kitchenPlanner.addKitchenToSceneFromConfiguration(productInstance)
    // projectConfiguration.deleteProductInstance(productInstance.productInstanceName)
    // kitchenPlanner.addKitchenToscene(group,productInstance,[...productInstance.moduleConfiguration.modules],[...productInstance.moduleConfiguration.wallModules])
    return
  }
  let modulesList = [...productInstance.moduleConfiguration.modules]
  //Set it to null so that not add again when updating the position
  productInstance.moduleConfiguration.modules = []
  let group = createGroupForModule()
  group.userData.isProductModular = true 
  group.userData.configuration = productInstance 
  updateUserDataFromProductInstance(group,productInstance)
  await addModulesToscene(group,modulesList,productInstance)
  let transform = productInstance.transform
  applyTransformInObject(group,transform)
  updateGroupCenter(group,[...group.children])
}

export function applyTransformInObject(object:any,transform:Transform){
  if(transform){

    object.position.set(transform.position.x,transform.position.y,transform.position.z)

    const rotationGroup = object.userData.groupForRotation
    // addProductToRotationGroup(object,rotationGroup)
    let quaternion = new Quaternion(transform.rotation._x,transform.rotation._y,transform.rotation._z,transform.rotation._w)
    object.quaternion.copy(quaternion)

    let worldQuaternion = new Quaternion()
    object.getWorldQuaternion(worldQuaternion)
    rotationGroup.quaternion.copy(worldQuaternion)
    // removeProductFromRotationGroup(object,rotationGroup)

    object.scale.set(transform.scale.x,transform.scale.y,transform.scale.z)
  }
}

// export async function addModulesToscene(group:any, modulesList:Array<any>, productInstance:any) {
//   const configuration = productInstance.moduleConfiguration
//   const subCategoryName = productInstance.subCategoryName
//   await Promise.all(modulesList.map(async (module) => {
//     const object:any = await addModule(configuration,group,module,subCategoryName,false,postAddingActions);
//     console.log(object,object.children)
//     await Promise.all(module.configuration.map(async (currPartInfo:any) => {
//       const material = await getMaterialUpdated(textureLoader, currPartInfo);
//       const mesh = object.children.find(currMesh => currMesh.name.includes(currPartInfo.partName));
//       mesh.material = material;
//     }));
//   }));
//   return "done";
// }

export async function addModulesToscene(group:any,modulesList:Array<any>,productInstance:any) {
  const configuration = productInstance.moduleConfiguration
  await Promise.all(modulesList.map(async (module) => {
    const object:any = await addModule(configuration,group,module,false,postAddingActions)
    await Promise.all(module.configuration.map(async (currPartInfo:any) => {
      let material = await getMaterialUpdated(textureLoader,currPartInfo)
      let mesh =  object.children.find(currMesh=>currMesh.name.includes(currPartInfo.partName))
      mesh.material = material
    }))
  }))
}


export function updateUserDataFromProductInstance(group:any,productInstance:any) {
  group.userData.configuration = productInstance
  group.userData.productInstanceName = productInstance.productInstanceName
  group.userData.originalProductName = productInstance.originalProductName
  group.userData.productName = productInstance.productName
  group.userData.categoryName = productInstance.categoryName
  group.userData.subCategoryName = productInstance.subCategoryName
  group.name = productInstance.productName
}
 

export function setProductNamesInModel(object:Object3D,categoryName:string,originalProductName:string,productInstanceName:string,areaName:string,isUpdateWallProductInfo:boolean = false) {
  object.userData.originalProductName =  originalProductName
  object.userData.productInstanceName = productInstanceName
  object.userData.categoryName = categoryName
  if(isUpdateWallProductInfo){
    object.userData.normal = projectConfiguration.getProductInstanceFromName(productInstanceName).normal
    object.userData.isWallMounted = projectConfiguration.getProductInstanceFromName(productInstanceName).isWallMounted
    object.userData.isAttachedToWall = projectConfiguration.getProductInstanceFromName(productInstanceName).isAttachedToWall
  }
}

export function getProductModelFromProductInstanceName(productInstanceName:string) {
  let result = null
  product3DModelsList.forEach(currProduct => {
    if(currProduct.userData.productInstanceName === productInstanceName){
      result = currProduct
    }
  });
  return result
}


export function getAreaModelOrigin() {
  let position = new Vector3()
  let origin = getObjectDimensionPosition(null,floorplanner.group)
  // let posY = origin.center.y - (origin.dimensions.dimY / 2) + 0.2
  let posY = origin.center.y - (origin.dimensions.dimY / 2)
  
  let floor = null 
  floorplanner.group.traverse((mesh:any)=>{
    if(mesh.isMesh){
      if(mesh.name.includes("Floor_")){
        floor = mesh
        return
      }
    }
  })
  if(floor){
    let floorData = getObjectDimensionPosition(null,floor)
    posY = floorData.positions.max.y
  }
  
  position.x = origin.center.x
  position.z = origin.center.z
  position.y =  posY
  return position
}

export function isRenderCreatedForAllAreas(projectId:number) {
  let isRenderCreated = true
  let areas = getFilteredArray(applicationConfig?.data?.projectAreasList,"project_id",projectId)
  for (const area of areas) {
    if(!area.is_render_created){
      isRenderCreated = false
    }
  }
  return isRenderCreated
}

export function isCustomizerDataAvailable(projectId:number) {
  let isAvailable = true
  // let areas = getFilteredArray(applicationConfig?.data?.projectAreasList,"project_id",projectId)
  // let projectConfig = projectConfiguration.getProjectConfiguration() 
  // for (const areaKey in projectConfig) {
  //   let area = projectConfig[areaKey]
  //   if((!area.anchor || !area.walls.length) && projectId != 319){
  //     isAvailable = false
  //   }
  // }
  return projectConfiguration.areAreasDefined
}

export function setDisplayMode(projectConfiguration:ProjectConfiguration) {
  if(applicationConfig.functions.customizer.setIsFloorPlanMode && false){
    enableFloorplanMode()
  }
}



export function updateOrthographicCamera(areaName:string,tween=500) {
      let target = areaWallsBoundingBoxMapping[areaName].boundingBox.center
      controls.setPosition(target.x,target.y + 15* scale ,target.z,true)
      controls.fitToBox( CURR_AREA_GROUP, true, { paddingTop:2, paddingLeft:2, paddingBottom:2, paddingRight:2 } )
 
}

// export function addViewForCameraPosition() {
  
// }

// export function removeViewForCameraPosition() {
  
// }



export function updateSceneAscpectRatio(aspectRatio:any) {
  let height = getWindowHeight()
  aspectRatio = getAspectRatioFromString(aspectRatio)
  let width = height * Number(aspectRatio)
  $(".customize-canvas-wrapper").height(height)
  $(".customize-canvas-wrapper").width(width)
  composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
}

function getAspectRatioFromString(aspectRatio:string) {
  if(aspectRatio == "16:9"){
    return 16/9
  }
  if(aspectRatio == "21:9"){
    return 21/9
  }
  if(aspectRatio == "4:3"){
    return 4/3
  }
  if(aspectRatio == "16:9"){
    return 16/9
  }
}

 

export function setMaskForCeiling() {
  floorplanner.group.traverse(mesh=>{
    if(mesh.name.toLowerCase().includes("ceiling")){
      mesh.layers.set(maskForCeiling)
      mesh.traverse(child=>{
        child.layers.set(maskForCeiling)
      })
    }
  })
}


export function setCameraTarget(camera:Camera,controls:CameraControls,distance:number) {
    let targetPosition = new Vector3()
    camera.getWorldDirection(targetPosition)
    let phi = Math.atan2(targetPosition.x,targetPosition.z);
    let theta = Math.acos(targetPosition.y);
    controls.rotate(phi,theta,false)
    let points = [
      new Vector3(camera.position.x,camera.position.y,camera.position.z),
      new Vector3(targetPosition.x,targetPosition.y,targetPosition.z),
    ]
    createLineFromPoints(areaModel,points)
    targetPosition.multiplyScalar(distance)
    controls.setTarget(targetPosition.x,targetPosition.y,targetPosition.z)
}

export function isWallMountedProduct(productName:string,categoryName:string) {
  return applicationConfig?.data?.productsList.find(product=>stringWithoutWhiteSpace(product.product_name)  === stringWithoutWhiteSpace(productName)  && stringWithoutWhiteSpace(product.category_name) === stringWithoutWhiteSpace(categoryName))?.is_wall_mounted || 0
}

export function setObjectNormal(object:any) {
  let angle = object.rotation.y * (180/Math.PI);
  switch (angle) {
    case 0:
      object.userData.normal = new Vector3(0,0,1)
    break;

    case 90:
      object.userData.normal = new Vector3(1,0,0)
    break;

    case 180:
      object.userData.normal = new Vector3(0,0,-1)
    break;

    case 270:
      object.userData.normal = new Vector3(-1,0,0)
    break;

    case 360:
      object.userData.normal = new Vector3(0,0,1)
    break;
  
    default:
      break;
  }
}


export function checkCollisionWithBoundingBox(object:any) {
  
  let result = null
  if(object.userData.isOnProductSurface){
    let data = getObjectDimensionPosition(null,object)
    let center = data.center
    raycaster.set(new Vector3(center.x,center.y,center.z),new Vector3(0,-1,0))
    let objects = raycaster.intersectObjects([...object.userData.attachedProduct.children,FLOOR],true)
    if (objects?.length) {
      let meshPosition = getObjectDimensionPosition(null,objects[0].object).positions
      result = meshPosition.max.y
    }
  }else{
    customizerConfig.flatSurfaceProducts.forEach(currObject => {
      if(currObject.userData.productName && currObject.userData.isFlatSurfaceProduct && currObject?.uuid != object?.uuid){
        updateModelBoundingBox(currObject)
        if(getModelBoundingBox(object).intersectsBox(getModelBoundingBox(currObject))){
            result = currObject.userData.boundingBox.max.y
            object.userData.isOnProductSurface = true
            object.userData.attachedProduct = currObject
        }
      }
    });
  }
  return result
}

export function rotateCompassIconFromCameraControls() {
    const angle = controls.azimuthAngle
    const degree = Math.round((angle * (180 / Math.PI) * -1) + 100);
    const anchorElement = $("#compassContainer")
    anchorElement.css("transform",`rotateZ(${-degree+170}deg)`)
}

export function addProductToRotationGroup(object:Object3D,group:Group) {
  // if(!object.userData.isProductModular){
    let center = getObjectDimensionPosition(null,object).center
    detachModule(object)
    // group.attach(object)
    // detachModule(object)
    group.position.set(center.x,center.y,center.z)
    group.attach(object)
  // }
}

export function removeProductFromRotationGroup(object:Object3D,group:Group,scene:Group) {
  // if(!object.userData.isProductModular){
    detachModule(object)
    scene.add(object)
    group.clear()
  // }
}


export function setInitialWallsHiding() {
  if(projectConfiguration.areasList.length > 2){
    autoWallsHiding()
  }else{
    enableWallsHiding()
  }
}

export function rotateCameraInSpacePlanner() {
  controls.rotateAzimuthTo(controls.azimuthAngle + Math.PI/2,true)
}