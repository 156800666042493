import React, { useEffect } from 'react';
import { endRotation, rotationRangeSliderHandle, startRotation } from '../../../../../utils/customizein3d/modules/actions';

import { enableBrowserFullScreen } from '../../../../../utils/UI_methods/global';
import { showActionTabs } from '../../../../../utils/customizein3d/modules/UI_methods';
import { pointerDownDragIcon, pointerUpDragIcon } from '../../../../../utils/customizein3d/modules/annotations';
import { Init, disableFloorplanMode, drop, enableFloorplanMode, floorplanner, sprites, toggleSpacePlannerMode } from '../../../../../utils/customizein3d/modules/customizein3d';
import { allowDrop, checkForLandscapeMode, onPointerDown, onPointerUp, pointerMoveAnnotationDragAction } from '../../../../../utils/customizein3d/modules/helper';
import { applicationConfig, getUser } from '../../../../../utils/methods';
import Floorplan from '../../../../Floorplan/Floorplan';
import ConfigureModeToggle from '../../../shared-components/Canvas/ConfigureModeToggle';
import RangeSlider from '../../../ui-components/input/RangeSlider';
import Toast from '../../../ui-components/notification/Toast';
import Layouts from '../layouts/Layouts';
import Annotations from './Annotations';
import LeftBottomActions from './LeftBottomActions';
import ResizeModule from './ResizeModule';
import RightBottomActions from './RightBottomActions';
import RoomLayout from './RoomLayout';
import SpacePlannerToggle from './SpacePlannerToggle';
import TopRightActions from './TopRightActions';
import { deselectModule } from '../../../../../utils/customizein3d/modules/raycasting';
import Button2 from '../../../ui-components/buttons/Button2';
import SceneBackgroundCanvasUI from './SceneBackgroundCanvasUI';
import { isTouchDevice } from '../../../../../utils/customizer/helper';
import ModesSelector from './ModesSelector';

 
  
  const Canvas: React.FC<{
    modulesConfigurtion:any
    isSpacePlannerMode:boolean
    currProduct:any
    isFloorplanMode:boolean
    isViewProductMode:boolean
    setIsSpacePlannerMode:(val:boolean)=>void
    isElevateMode?:boolean
  }> = (props) => {
 
    useEffect(()=>{
        Init(applicationConfig)
        checkForLandscapeMode()
    },[applicationConfig,props.modulesConfigurtion])


  
    return (
      <>
        <Annotations
          configObj={applicationConfig}
        />

        {props.currProduct?.category_name === "Sofa"?
        <ResizeModule/>
        :null}
        
        

        {/* <ReclinerHeight/> */}

        <div className="position-relative full-height-width customization-wrapper"
          id="3dCustomizerWrapper"
          onDragOver={allowDrop}
          onDrop={drop}
          onTouchMove={pointerMoveAnnotationDragAction}
          onPointerMove={pointerMoveAnnotationDragAction} 
          onPointerUp={onPointerUp}
          onPointerDown={onPointerDown}
          onDoubleClick={deselectModule}
        >

          {/* <div id="sceneBackground"></div> */}

          {/* {getUser()?.username === "ovl_Shreedhar"?
              <div className="bottom-middle hide-on-measurement ion-padding hide-on-rotation hide-in-elevation-view hide-in-isolation-view hide-on-multiselect hide-on-dragging hide-on-wall-focus" style={{zIndex:101}}>
                <ModesSelector
                />
              </div>
          :null} */}

     
        <SceneBackgroundCanvasUI/>

      {/* <div className="hide-in-desktop bottom-middle padding0 full-width zindex30" style={{bottom:"-12px"}}>
        <div className="full-width center-aligned-row display-none"  id="menuCloseButtonMobile">
          <i className='fas fa-grip-lines color-dark font-large'  onPointerDown={()=>disableConfigureMode()}></i>
        </div>
      </div> */}

       

      <div id="moduelsDragIcon" onTouchEnd={pointerUpDragIcon} onPointerUp={pointerUpDragIcon} onPointerDown={pointerDownDragIcon} onTouchStart={pointerDownDragIcon}  className={`top-left zindex30 white-card display-none no-shadow zindex100 center-aligned-column bg-color- pointer padding0 ${props.currProduct?.category_name !== "Sofa"?" visibility-hidden":""}`} style={{width:"30px",height:"30px",transition:"none",background:"rgba(255,255,255,0.6)"}}> <i className="fas fa-arrows-alt pointer-none font-medium color-dark"></i> </div>

        <div className='top-left zindex100 full-height-width display-none background-black-fade' id="landscapeWarningWrapper">
          <div className='full-height-width center-aligned-column'>
            <div className='display-flex-row no-wrap'>
              <div className='color-white font-normal heading1 border padding10' onClick={enableBrowserFullScreen}>Click to full Screen</div>
              <div className='color-white marginleft5 center-aligned-column'>or</div>
              <div className='color-white font-normal marginleft5 center-aligned-column heading3'>Rotate to potrait mode</div>
            </div>
          </div>
        </div>

       <RightBottomActions
          modulesConfigurtion={props.modulesConfigurtion}
          currProduct={props.currProduct}
          isFloorplanMode={props.isFloorplanMode}
          isViewProductMode={props.isViewProductMode}
          isSpacePlannerMode={props.isSpacePlannerMode}
          isElevateMode={props.isElevateMode}
        />


      <div className='deselect-button top-right padding10 visibility-hidden zindex30'>
      <Button2
          configObj={{id:""}}
          action={deselectModule}
          label={"Deselect"}
          fontAwesomeIcon={"fas fa-times color-danger"}
          iconPos="left"
          classNames={"shadow- button-rounded bg-color-danger-outline"}
        />
      </div>

      <div className='show-on-sprites visibility-hidden top-left zindex30'>
        <div className='display-flex-row no-wrap'>
          <Button2
            configObj={{id:""}}
            action={()=>{sprites.disable()}}
            label={"Exit Mode"}
            fontAwesomeIcon={"fas fa-chevron-left color-black"}
            iconPos="left"
            classNames={"shadow-medium no-border bg-color-black button-small"}
          />

          <Button2
            configObj={{id:""}}
            action={()=>{sprites.resetColor()}}
            label={"Reset"}
            fontAwesomeIcon={"fas fa-undo color-danger"}
            iconPos="left"
            classNames={"shadow-medium no-border button-small"}
          />

        </div>
      </div>

      
      

      <Toast/>

      <div className="top-left action-link-container hide-on-select hide-on-sprites">
          <div className='display-flex-row no-wrap'>
            {props.modulesConfigurtion.allowSpacePlanner && !props.isFloorplanMode?
            <SpacePlannerToggle
              isSpacePlannerMode={props.isSpacePlannerMode}
              setIsSpacePlannerMode={props.setIsSpacePlannerMode}
              action={toggleSpacePlannerMode}
            />
            :null}           
          </div>
      </div>

      {props.isFloorplanMode || props.isSpacePlannerMode?
        <Floorplan
          floorplan={floorplanner}
          isFloorplanMode={props.isFloorplanMode}
          enableFloorplanMode={enableFloorplanMode}
          disableFloorplanMode={disableFloorplanMode}
          moduleName="customizein3d"
        />
      :
        <TopRightActions
          modulesConfigurtion={props.modulesConfigurtion}
          isSpacePlannerMode={props.isSpacePlannerMode}
          isFloorplanMode={props.isFloorplanMode}
          isViewProductMode={props.isViewProductMode}
          isElevateMode={props.isElevateMode}
        />
      }

      <LeftBottomActions
          modulesConfigurtion={props.modulesConfigurtion}
          isSpacePlannerMode={props.isSpacePlannerMode}
          isFloorplanMode={props.isFloorplanMode}
          isElevateMode={props.isElevateMode}
          isViewProductMode={props.isViewProductMode}
      />

      <RoomLayout
        configObj={applicationConfig}
      />
{/* 
      {getUser()?.username === "DemoMaster"?
        <WebXrUI/>
      :null} */}
      {!isTouchDevice()?
      <div id="backgroundHintText" className="bottom-middle padding10 display-none">
        <div className="heading4 color-black text-center">Use the right mouse button to pan and the left mouse button to rotate the view.</div>
      </div>
      :null}


      


      <div className="bottom-middle">
        <div id="productRotationRangeSlider" className="product-rotation-slider modules-rotation-slider padding0">
          <RangeSlider
            id="rangeSlider"
            action={rotationRangeSliderHandle}
            pointerUp={endRotation}
            pointerDown={startRotation}
          />
        </div>
        {(props.modulesConfigurtion.allowSpacePlanner || props.modulesConfigurtion.allowFinish || props.modulesConfigurtion.allowAddons) ?
          <ConfigureModeToggle
          configObj={applicationConfig}
          heading="Customize"
          desktopModeAction={showActionTabs}
          />
          :null
        }
        </div>
      </div>
      </>
    );
  };
  
  export default Canvas;

 