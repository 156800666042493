import React, { useEffect, useState } from "react";
import { applicationConfig, getObjectByParameter } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
import { getFormattedPrice } from "../../../../../utils/products/productviewer_shopify.js";
import { configuration } from "../../../../../utils/customizein3d/modules/customizein3d";

 
const PricingDetails: React.FC<{
    cartItems:any
}> = (props) => {

    const[totalMRP,setTotalMrp] = useState(-STORE.currDiscount)

    useEffect(()=>{

        if(configuration){
            let modulesList = configuration?.modules
            for (const item of modulesList) {
                setTotalMrp(prevValue=>{
                    return prevValue + Number(item.price)
                })
            }
        }

        
    },[props.cartItems])
    
    function PricingDetails(){
        return(
            <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table">
                <thead >
                  <tr >
                    <th className="bg-color-primary color-white">Image</th>
                    <th className="bg-color-primary color-white">Product Name</th>
                    <th className="bg-color-primary color-white">Category</th>
                    <th className="bg-color-primary color-white">Quality</th>
                    <th className="bg-color-primary color-white">Price</th>
                  </tr>
                </thead>
                    <tbody>
                        {props.cartItems?.map((item:any,j:number)=>{
                            let configuration = item?.configuration
                            configuration = JSON.parse(configuration)
                            let product = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",item.product_id)
                            return(
                            <tr key={j}>
                                <td className="image">
                                    <ProductImage product={product}/>
                                </td>
                                <td className="">
                                    {STORE.getProductName(product)}
                                </td>
                                <td className="">
                                    {product?.category_name}
                                </td>
                                <td className="store-color-primary heading3">{configuration.quality}</td>
                                <td className="rupee-sign">{getFormattedPrice(totalMRP)}</td>
                            </tr>
                            )
                        })}

                        <tr>
                            <td className="text-start price-heading" colSpan={4}>Sub total</td>
                            <td className="rupee-sign">{getFormattedPrice(totalMRP)}</td>
                        </tr>

                        <tr>
                            <td className="text-start price-heading" colSpan={4}>Discount</td>
                            <td className=" rupee-sign">{STORE.currDiscount}</td>
                        </tr>

                        <tr>
                            <td className="text-start price-heading" colSpan={4}>Grand total</td>
                            <td className=" rupee-sign">{getFormattedPrice(totalMRP)}</td>
                        </tr>
                
                    </tbody>
                </table>
            </div>
        )
    }
 
 
  return (
    <PricingDetails/>
  );
};

export default PricingDetails;
