import $ from "jquery"
import AwsConfig from "../../aws-sdk/AwsConfig"
import { applicationConfig, waitFor } from "../../methods"
import { cameraForSettingPosition, cameraSettingViewData, updateCameraSettingViewData } from "../area3dmodel/floorplan/createRender"
import { cameraFor2dRender, createRenderCameraForProduct, saveRenderCameraFor2D } from "../area3dmodel/floorplan/render2d"
import { updateSceneCameraAspectRatio } from "../controls"
import { ARConfig, generateModel } from "../webxr"
import { closeMenuHandleLandscape, disableConfigureMode, openGallery } from "./UI_methods"
import { IS_SPACE_PLANNER_MODE, camera, canvasDimensions, composer, configuration, disableSpacePlanner, group, labelRenderer, renderer } from "./customizein3d"
import { closeControlsPanelAndHideProducts } from "../area3dmodel/UI_methods"
import { uploadGeneratedModelToS3 } from "../../../services/api"
import { addItemToLocalStorage } from "../../cache/localstorage"
import { STORE } from "../../store/storeConfiguration"
import { showComponentLoader } from "../../UI_methods/global"
import { deselectModule } from "./raycasting"


export var isTakeRenderMode = false


export async function enableTakeRenderMode() {
    // disableConfigureMode()
    await waitFor(500)
    // closeMenuHandleLandscape()
    isTakeRenderMode = true
    applicationConfig.functions.customizein3d.setIsTakeRenderMode(true)
    updateCameraSettingViewData("2d")
    if(IS_SPACE_PLANNER_MODE){
    await disableSpacePlanner()
    }
    updateSettingPositionCameraView()
    // enableInteriorControls()
    cameraForSettingPosition.zoom = cameraSettingViewData.width * 100
    createRenderCameraForProduct()
    updateRenderCameraValues()
    $(".hide-in-take-render").addClass("visibility-hidden")
    $(".show-in-take-render").removeClass("visibility-hidden")
  }

  export function disableTakeRenderMode() {
    // setIsCameraSettingMode(false)
    isTakeRenderMode = false
    applicationConfig.functions.customizein3d.setIsTakeRenderMode(false)
    resetRenderer()
    // $("#cameraViewFrame").removeClass("--is-active")
    // hideComponentLoader("canvasLoader")
    // undo.updateMode("360")
    $(".hide-in-take-render").removeClass("visibility-hidden")
    $(".show-in-take-render").addClass("visibility-hidden")
  }

  export function updateSettingPositionCameraView() {
    cameraForSettingPosition.position.set(group.position.x,group.position.y + 25,group.position.z)
    cameraForSettingPosition.lookAt(group.position.x,group.position.y,group.position.z)
    cameraForSettingPosition.updateProjectionMatrix()
    
  }

  function resetRenderer() {
    renderer.setViewport( 0, 0, canvasDimensions.width, canvasDimensions.height );
    renderer.setScissor( 0, 0, canvasDimensions.width, canvasDimensions.height );
    renderer.setScissorTest( true );
    updateSceneCameraAspectRatio(camera,canvasDimensions.width,canvasDimensions.height,1)
    composer.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
  }
  
  export function updateFov(event:any,fov:any = null) {
    if(!fov){
        fov = event.target.value
    }
    camera.fov = fov
    camera.updateProjectionMatrix()
    cameraFor2dRender.updateFov(Number(fov))
    // projectConfiguration.updateCameraTransform(applicationConfig.areaName,camera)
    $("#rangeSliderFovVal").text(String(camera.fov))
  }

  export function updateNearValue(event:any,near:any = null) {
    if(!near){
        near = (event.target.value / 100)
    }
    camera.near = near
    camera.updateProjectionMatrix()
    cameraFor2dRender.updateNear(near)
    // projectConfiguration.updateCameraNear(applicationConfig.areaName,camera)
    $("#rangeSliderNearVal").text(String(camera.near))
  }
  
  export async function createRenderConfigurator(debugOptions:any,callBack:(val:boolean)=>void){
    applicationConfig.functions.customizein3d.refreshGallery()
    configuration.isCreatingRender = true
    configuration.setIsProductRender(true)
    let camerasList = []
    camerasList = [cameraFor2dRender]
    await applicationConfig.awsConfig.removeAllMessages()
    await saveRenderCameraFor2D(cameraFor2dRender,camera)
    await waitFor(500)
    disableTakeRenderMode()
    openGallery()
    let product = configuration.product
    let clientName = applicationConfig.getClientName()
    let modules = configuration.modules.concat(configuration.addons)
    await generateModel(product,configuration,modules,false,debugOptions)

    await uploadRenderModelToS3(configuration,cameraFor2dRender)
    
    configuration.isCreatingRender = false
    applicationConfig.functions.customizein3d.refreshGallery()
  }


  export async function uploadRenderModelToS3(configuration:any,cameraFor2dRender:any,defaultHash:any = null,isUploadDefaultModel:boolean = false){
    let product = configuration.product
    let clientName = applicationConfig.getClientName()
  
    const timestamp = Number(new Date())
    const requestId = `${clientName}${timestamp}`
    const configId = configuration.getConfigId(configuration.product.productName)
    
    let modules = configuration.modules.concat(configuration.addons)

    let configHash = defaultHash || configuration.configHash


    let arConfig = new ARConfig(product,requestId,configId,configuration.configName,configHash,modules,configuration.isProductRender,"CreateModel.py",{},cameraFor2dRender)
    arConfig.isProductRender = false
    arConfig.configId = String(configuration.configId)
    arConfig.configName = String("Default_"+configuration.configId)
    arConfig.clientName = clientName
    arConfig.ClientName = clientName
    arConfig.isUploadDefaultModel = isUploadDefaultModel
    return uploadGeneratedModelToS3(arConfig)
    // .then(async uploadRenderData=>{
    //   let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/models/${clientName}/${encodeURIComponent(arConfig.category)}/${encodeURIComponent(arConfig.subCategory)}/${encodeURIComponent(arConfig.productName)}/${arConfig.configHash}/${arConfig.configId}.glb`
    //   console.log(url)
    // }).catch(err=>{
    // })
  }



  function updateRenderCameraValues() {
    cameraFor2dRender.fov = camera.fov
    cameraFor2dRender.near = camera.near
  }


  export async function gotoProjectsForTakeRender(sceneName:string){
    return new Promise(async (resolve,reject)=>{
      showComponentLoader("canvasLoader")
      deselectModule()
      let product = configuration.product
      let modules = configuration.modules.concat(configuration.addons)
      await generateModel(product,configuration,modules,true,null).then(async (url:any)=>{
        addItemToLocalStorage("currProject",getProjectDetails(sceneName))
        const parts = url.split('/');
        const configHash = parts[parts.length - 2];  
        const configId = parts[parts.length - 1].split('.')[0];  
        // window.location.href = `/customize/${STORE?.currProduct?.product_name}/${configHash}/${configId}`
        resolve(`/customize/${sceneName}/${STORE?.currProduct?.product_name}/${configHash}/${configId}`)
      }).catch(err=>{
        console.log(err)
      })
    })
    
   
  }

  function getProjectDetails(sceneName:string){
    return {
      client_id:9,
      client_mobile_number:"7223901017",
      client_name: applicationConfig.clientName,
      developer: "",
      location: "Delhi",
      project_id: 465,
      project_name: sceneName,
      project_type: "",
      status: 1
    }
  }