import $ from "jquery";
import { Box3, BoxHelper, Raycaster, Vector2 } from "three";
import { Vector3 } from "three/src/math/Vector3";
import { addClassInElement, hideComponentLoader, removeClassFromElement, showToast } from "../../UI_methods/global";
import { isTouchDevice } from "../../customizer/helper";
import { applicationConfig, getMaterialTypesFromPartName, logger, stringWithoutWhiteSpace, waitFor } from "../../methods";
import { updateActionPanelInformation } from "../UImethods";
import { hideRotationSlider, resetAnnotationWrapperPosition } from "../area3dmodel/UI_methods";
import { addModuleToConfiguration, attachModules, detachModule, updateGroupCenter } from "../common";
import { dropMaterial, getClickedObject, getMeshOfSameMaterialTypes } from "../raycasting";
import { hideActionPanel, hideModuleAnnotation, hideResizerWindow } from "./UI_methods";
import { IS_SPACE_PLANNER_MODE, MODULE_NAME, SPACE_PLANNER_IMAGES_GROUP_NAME, addAddonToConfiguration, addonsGroup, camera, composer, configObj, configuration, delete3dObject, dimensions, disablelCustomizationMode, element, enableCustomizationMode, getSelectedObjectType, group, hideObjectActionPanel, postAddingActions, renderer, scene, setModulePosition, spacePlannerImagesGroup, sprites, undo } from "./customizein3d";
import { disableDragging, makeObjectDraggable } from "./dragControls";
import { addImageToScene, getParent, getPartNameFromMeshName, removeObjectFromScene, updatePositionOfAddon } from "./helper";
import { closeWardrobeModulePanel, openWardrobeModulePanel, resetWardrobeParents, updateBaseToggleText, updateLoftToggleText, updateLoftsList } from "./wardrobe";
import { STORE } from "../../store/storeConfiguration";
import { getOriginalPartName } from "../area3dmodel/helper";
import { isSwingStandAttach } from "./swing";

var posStartX = 0
var posEndX = 0

var posStartY = 0
var posEndY = 0
export var boxHelper = null
export var CURR_SELECTED_MESHES = []
export var CURR_SELECTED_MODULE:any = null
var axis = new Vector3(0,1,0)

export var isMultipleSelectionMode = false
export var raycaster = new Raycaster();
var mouseVector = new Vector3();
var boundingBox = new Box3()


export var isParentMeshData = {
  originalParent:null,
  mesh:null
}
export var rendererDimensions = new Vector2()



  export async function applyRaycasting(event:any,selectionType:string="single") {
    const x = event.pageX || event.targetTouches[0]?.pageX 
    const y = event.pageY || event.targetTouches[0]?.pageY

    let delta = 0

    if(isTouchDevice()){
      delta = 0.03
    }

    if(!$("#unitOptionsWindow").hasClass("display-none")){
      return
    }

    if((Math.abs(x - posStartX) <= delta || Math.abs(y - posStartY) <= delta)){
      let intersectsArray = getIntersectArray(x,y,scene)
      let clickedObj = getClickedObject(intersectsArray)


      if(!clickedObj){
        // deselectModule()
        return
      }
      if(isMultipleSelectionMode){
        multipleSelection(clickedObj)
        return
      }
      //If object is already selected 
      if(CURR_SELECTED_MODULE?.uuid === getParent(clickedObj?.object)?.uuid && !IS_SPACE_PLANNER_MODE){
        selectedModelAction(clickedObj,selectionType)
        return
      }
      let object = getParent(clickedObj?.object) 
      if(clickedObj?.object?.isSprite){
          object = clickedObj?.object
      }

      if(configuration.isViewProductMode && !clickedObj?.object?.isSprite && clickedObj.object){
        object = getAddedProduct(clickedObj?.object)
      }

      if(object){
        if(CURR_SELECTED_MODULE){
          disableDragging()
          composer.outlinePass.selectedObjects = []
          CURR_SELECTED_MESHES =  []
          await waitFor(50)
        }
        CURR_SELECTED_MODULE = object
        rayCastingActionForModule(object)
        return
      }

      if(!isMultipleSelectionMode){
        deselectModule()
      }
    }

  }

  export function getDetectedObject(event:any) {
      const x = event.pageX || event.targetTouches[0].pageX 
      const y = event.pageY || event.targetTouches[0].pageY

      let intersectsArray = getIntersectArray(x,y,addonsGroup)
      let clickedObj = getClickedObject(intersectsArray)
      if(clickedObj){
        // return  getParent(clickedObj?.object) 
        // if(clickedObj?.object?.isSprite){
        //   object = clickedObj?.object
        // }
        return getAddedProduct(clickedObj?.object)
      }
      
  }

  function multipleSelection(clickedObj:any){
    let index = -1
    //Get the index of already present mesh 
    if(CURR_SELECTED_MESHES.length){
      for (let i = 0; i < CURR_SELECTED_MESHES.length; i++) {
        const currMesh = CURR_SELECTED_MESHES[i];
        if(currMesh.uuid === clickedObj.object.uuid){
          index = i
        }
      }
    }
    //Delete if already present in the list
    if(index !== -1){
      CURR_SELECTED_MESHES = [...CURR_SELECTED_MESHES.slice(0,index),...CURR_SELECTED_MESHES.slice(index+1)] 
    }else{
      // clickedObj.object.renderOrder = -1;
      // clickedObj.object.material.transparent = false;
      let object = clickedObj?.object
      if(object?.isMesh && object?.parent?.isMesh && STORE.getCurrCategory() === "Wardrobe"){
        // isParentMeshData.originalParent = CURR_SELECTED_MODULE.parent
        // isParentMeshData.mesh = CURR_SELECTED_MODULE
        let group = getParent(object)
        if(group){
          object.userData.originalParent = object.parent
          detachModule(object)
          attachModules([object],group)
        }
        
      }

      CURR_SELECTED_MESHES =  [clickedObj?.object,...CURR_SELECTED_MESHES]
    }
    composer.outlinePass.selectedObjects = CURR_SELECTED_MESHES
    if(STORE.getCurrCategory() === "Cot"){
      filterMaterialsForCustomization()
    }
  }


  function filterMaterialsForCustomization(){
    let allMaterials = STORE.data.materialsForCustomization
    let materialTypes = []
    for (const mesh of CURR_SELECTED_MESHES) {
      let currMesh = mesh.name
      currMesh = currMesh.replace("_","")
      let object = getParent(mesh)
      if(object){
        let categoryName = object.userData.configuration.categoryName
        let partName = getPartNameFromMeshName(currMesh,categoryName)
        materialTypes.push(...getMaterialTypesFromPartName(partName,applicationConfig?.data?.objectMaterialTypes,categoryName))
      } 
    }
    let filteredMaterials = allMaterials.filter((currMaterial) => materialTypes.includes(currMaterial.material_type))
    applicationConfig.functions.customizein3d.setTextures(filteredMaterials)
  }

  function selectedModelAction(clickedObj:any,selectionType:string) {
    if(selectionType === "all"){
      selectFirstMesh()
      return
    }

    let object = clickedObj?.object

    if(object?.parent?.isMesh && STORE.getCurrCategory() === "Wardrobe"){
      object.userData.originalParent = object.parent
      detachModule(object)
      attachModules([object],CURR_SELECTED_MODULE)
    }
    updateSwingFinishPanel(getOriginalPartName(STORE.getCurrCategory(),object.name))
    composer.outlinePass.selectedObjects = [object]
    CURR_SELECTED_MESHES =  [object]

    hideActionPanel()
  }


  function updateSwingFinishPanel(partName:string){
    if(partName === "SeatCushion" || partName === "BackCushion"){
      partName = "Upholstery"
    }
    try {
      let parentContainer = $(".part-list-container")
      parentContainer.children().each(function() {
          let currPartName = $(this).data("part-name") 
          if (partName.toLowerCase().includes(currPartName.toLowerCase())) {
              $(this).click()
          }
      });
    } catch (error) {
      console.log(error);
    }
  }

  function getIntersectArray(x:any,y:any,groupForIntercasting:any = group) {
    let intersectsArray = []
    intersectsArray = getIntersectsObjects(x, y,mouseVector,raycaster,groupForIntercasting)
    if(IS_SPACE_PLANNER_MODE && !intersectsArray.length){
      intersectsArray = getIntersectsObjects(x, y,mouseVector,raycaster,spacePlannerImagesGroup)
    }
    return intersectsArray
  }

  export function pointerDownRaycasting(event:any) {
    posStartX = event.pageX || event.targetTouches[0].pageX 
    posStartY =  event.pageY || event.targetTouches[0].pageY
  }

  export function selectFirstMesh() {
    let categoryName = stringWithoutWhiteSpace(configuration.product.categoryName) 
    let allMeshes = CURR_SELECTED_MODULE.children
    if(categoryName === "Wardrobe"){
      allMeshes = []
      CURR_SELECTED_MODULE?.traverse(object=>{
        if(object.isMesh){
          allMeshes.push(object)
        }
      })
      allMeshes = getMeshOfSameMaterialTypes(allMeshes,{name:"Base"},configObj?.data.objectMaterialTypes,categoryName)
    }else{
      allMeshes = getMeshOfSameMaterialTypes(allMeshes,{name:"Base"},configObj?.data.objectMaterialTypes,categoryName)
    }
    // composer.outlinePass.selectedObjects = allMeshes
    CURR_SELECTED_MESHES = allMeshes
  }

  export function resetCurrSelectedMeshes() {
    CURR_SELECTED_MESHES =  []
  }
  export function setCurrSelectedMeshes(meshes:any) {
    CURR_SELECTED_MESHES =  meshes
  }

  export function getAddedProduct(object:any) {
    if(!object){
      return null
    }
    if(object.parent?.name?.toLowerCase().includes("3dmodules")){
      return object
    }
    return getAddedProduct(object.parent)
  }
  
  export function rayCastingActionForModule(object:any){

    if(object.parent?.name === SPACE_PLANNER_IMAGES_GROUP_NAME){
      selectModule(false)
      $(".module-name-heading").text(CURR_SELECTED_MODULE?.name)
      $("#productRotateAnnotation").removeClass("display-none")
      $("#productRotationRangeSlider").removeClass("--is-active")
      return
    }
    
    if(object?.userData.configuration){
      selectModule()
      return
    }
   
    deselectModule()
  }

  export function selectModule(isObject3D:boolean = true) {

    isParentMeshData.originalParent = null
    isParentMeshData.mesh = null
    
    if(isObject3D){
      selectFirstMesh()
      let objectForBox = STORE.getCurrCategory() === "Swing" && isSwingStandAttach(CURR_SELECTED_MODULE)?group:CURR_SELECTED_MODULE
      addBoxHelper(objectForBox)
    }
    enableCustomizationMode()
    updateActionPanelInformation(CURR_SELECTED_MODULE,boundingBox)
    // showActionPanel()
    makeObjectDraggable(CURR_SELECTED_MODULE)
    dimensions.updateCurrObject(CURR_SELECTED_MODULE)
    if(dimensions.isDimensionsVisible){
      dimensions.updateDimensions()
    }
    if(isTouchDevice() || true){
      $(".hide-on-select").addClass("visibility-hidden")
      $(".deselect-button").removeClass("visibility-hidden")
    }
    
    if(STORE?.currProduct?.category_name === "Wardrobe"){
      updateActionPanelInformation(CURR_SELECTED_MODULE,boundingBox)
      updateLoftToggleText(CURR_SELECTED_MODULE)
      updateBaseToggleText(CURR_SELECTED_MODULE)
    }
    return
    resetAnnotationWrapperPosition()
    $("#rotateModules").addClass("display-none")
  }

  export function deselectModule() {
    try{
        hideActionPanel()
        disablelCustomizationMode()
        hideRotationSlider()
        disableDragging()
        hideResizerWindow()
        $("#rotateModules").removeClass("display-none")
        $("#objectActionsContainer").removeClass("--is-active")
        composer.outlinePass.selectedObjects = []
        if(!sprites.isEnabled){
          $(".hide-on-select").removeClass("visibility-hidden")
        }
        $(".deselect-button").addClass("visibility-hidden")
        dimensions.updateCurrObject(group)
        closeWardrobeModulePanel()
        disableMultiselectionMode()

        setCurrSelectedMeshes([])

        // if(isParentMeshData.originalParent){
        //   let object = isParentMeshData.mesh
        //   detachModule(object)
        //   attachModules([object],isParentMeshData.originalParent)
        // }

        if(STORE.getCurrCategory() === "Wardrobe"){
          resetWardrobeParents()
        }
        hideModuleAnnotation()
    }catch{
      console.log("eror")
    }
    
  }


  export function resetCurrSelectedModule() {
    CURR_SELECTED_MODULE = null
  }

  export function setCurrSelectedModule(object:any) {
    CURR_SELECTED_MODULE = object
  }


  export function addBoxHelper(object:any,parent:any=null) {
    removeBoxHelper()
    boxHelper = new BoxHelper( object, 0x0058a3)
    if(parent){
      parent.add(boxHelper)
    }
    scene.add(boxHelper)
    return boxHelper
  }

  export function removeBoxHelper() {
    scene?.remove(boxHelper)
    boxHelper = null
  }

  export function updateBoxHelper() {
    boxHelper?.update()
  }


  export function sceneHoverInteraction(x:any,y:any) {
    let intersectsArray = getIntersectsObjects(x, y,mouseVector,raycaster,group);
    let clickedObj = getClickedObject(intersectsArray)
    composer.outlinePass.selectedObjects = []
    if(clickedObj?.object){
      composer.outlinePass.selectedObjects = [clickedObj?.object]
    }
  }


  export function toggleMultiselect() {
    if(isMultipleSelectionMode){
      isMultipleSelectionMode = false
      $("#toggleMultiselect").find(".state-text").text("off")
      $("#toggleMultiselect").removeClass("--is-active")
      showToast("Multiselection off",2000)
    }else{
      isMultipleSelectionMode = true
      enableCustomizationMode()
      $("#toggleMultiselect").find(".state-text").text("on")
      $("#toggleMultiselect").addClass("--is-active")
      showToast("Multiselection on",2000)
      if(!CURR_SELECTED_MODULE){
        hideModuleAnnotation()
      }
    }
    // deselectModule()
  }

  export function disableMultiselectionMode() {
    if(isMultipleSelectionMode){
      isMultipleSelectionMode = false
      $("#toggleMultiselect").find(".state-text").text("off")
      $("#toggleMultiselect").removeClass("--is-active")
      showToast("Multiselection off",2000)
    }
  }

export function deleteObjectFromScene(scene:any,spacePlannerImagesGroup:any,SELECTED_OBJECT_OUTLINE_NAME:string,object:any = null) {

    if(!object){
    object = CURR_SELECTED_MODULE
    }
    disableDragging()
    //If object is 3D Object
    if(!object)return
    let objectType = getSelectedObjectType(object)
    if(object.userData.configuration){
      // group.rotation.set(0,0,0,"XYZ")
      delete3dObject(object)
      hideObjectActionPanel()
      disablelCustomizationMode()
      // logger?.info("customizein3d","del-m")
      if(dimensions.isDimensionsVisible){
        dimensions.updateDimensions()
      }
    }
    if(object.isSprite){
      removeObjectFromScene(spacePlannerImagesGroup,object)
      showToast("Removed " + object?.name,2000)
      hideObjectActionPanel()
      disablelCustomizationMode()
      // logger?.info("customizein3d","del-img")
      return
    }
  }


  export async function duplicateObject(scene:any,configuration:any,configObj:any,GROUND_WIDTH:any,GROUND_HEIGHT:any,groundGroup:any,spacePlannerImagesGroup:any,textureLoader:any,controls:any,camera:any,renderer:any,SELECTED_OBJECT_OUTLINE_NAME:string,dragControlsImages:any) {
    if(!CURR_SELECTED_MODULE)return
    let module = CURR_SELECTED_MODULE.userData.configuration
    let moduleType = module.moduleType || module.module_type
    let object = CURR_SELECTED_MODULE.clone()
    let subCategoryName = configuration.product.subCategoryName
    let categoryName = configuration.product.categoryName
    let isAddedAsAddon = module.isAddedAsAddon
    deselectModule()
    if(module){
      let objectType = getSelectedObjectType(object)
      if(objectType==="3dmodel"){
        if(isAddedAsAddon){
          updatePositionOfAddon(object,configuration)
          group.attach(object)
          postAddingActions(object)
          addAddonToConfiguration(module,object,configuration)
          if(categoryName === "Wardrobe"){
            updateLoftsList(object)
          }
          undo.add("duplicate",{addedModel:object})
        }else{
          let isAdded = await setModulePosition(group,object,module,configuration,subCategoryName)
          if(isAdded){
            group.attach(object)
            postAddingActions(object)
            addModuleToConfiguration(module,object,configuration)
            updateGroupCenter(group)
            if(categoryName === "Wardrobe"){
              updateLoftsList(object)
            }
            undo.add("duplicate",{addedModel:object})
          }
        }
        hideComponentLoader("changeFinishLoader")
        // logger?.info("customizein3d","dup-m")
        return
      }
      if(objectType==="image"){
        await addImageToScene(object?.name,configObj,GROUND_WIDTH,GROUND_HEIGHT,groundGroup,spacePlannerImagesGroup,textureLoader,dragControlsImages,controls,scene,camera,renderer,SELECTED_OBJECT_OUTLINE_NAME)
        hideComponentLoader("changeFinishLoader")
        // logger?.info("customizein3d","dup-img")
        return
      }
    }
    hideComponentLoader("changeFinishLoader")
  }

  
export function rotateObject(scene:any,boundingBox:any,SELECTED_OBJECT_OUTLINE_NAME:string,axis:any) {
    if(!CURR_SELECTED_MODULE)return
    if(CURR_SELECTED_MODULE.type==="Object3D"){
      CURR_SELECTED_MODULE.rotateOnAxis(axis,-Math.PI/2)
    }
    if(CURR_SELECTED_MODULE.type==="Sprite"){
      CURR_SELECTED_MODULE.material.rotation += -Math.PI / 2
      CURR_SELECTED_MODULE.rotateOnAxis(axis,-Math.PI/2)
    }
    // logger?.info("customizein3d","rot-m")
    // createBoxBelowModel(scene,boundingBox,CURR_SELECTED_MODULE,SELECTED_OBJECT_OUTLINE_NAME)
  }



  export function getIntersectsObjects(x:number, y:number,mouseVector:any,raycaster:any,objectsList:any = scene) {
  
    y = y - element.getBoundingClientRect().top + window.scrollY
    x = x - element.getBoundingClientRect().left + window.scrollX
    renderer.getSize(rendererDimensions)
  
    x = (x / rendererDimensions.x) * 2 - 1
    y = -(y / rendererDimensions.y) * 2 + 1
  
    mouseVector?.set(x, y, 0.5)
    raycaster?.setFromCamera(mouseVector, camera)
    return raycaster?.intersectObject(objectsList, true).filter(object=>!object.object.type.toLocaleLowerCase().includes("line"))
  }
  

  export function getIntersectsObjectsForBackgroundSprite(x, y, mouseVector, raycaster, objectsList = scene) {
    y = y - element.getBoundingClientRect().top + window.scrollY;
    x = x - element.getBoundingClientRect().left + window.scrollX;
    renderer.getSize(rendererDimensions);
  
    x = (x / rendererDimensions.x) * 2 - 1;
    y = -(y / rendererDimensions.y) * 2 + 1;
  
    mouseVector?.set(x, y, 0.5);
    raycaster?.setFromCamera(mouseVector, camera);
    let intersects = raycaster?.intersectObject(objectsList, true);
  
    // Filter out lines and non-visible parts of sprites
    return intersects.filter(intersect => {
      const { object, point } = intersect;
  
      // Check if object is a sprite
      if (object.type.toLocaleLowerCase().includes("sprite")) {
        const texture = object.material.map;
        if (!texture) return false; // No texture, cannot check transparency
  
        // Calculate UV coordinates
        const localPoint = object.worldToLocal(point.clone());
        const uv = new Vector2(
          0.5 + localPoint.x / object.scale.x,
          0.5 + localPoint.y / object.scale.y
        );
  
        // Get texture data
        const textureWidth = texture.image.width;
        const textureHeight = texture.image.height;
        const pixelX = Math.floor(uv.x * textureWidth);
        const pixelY = Math.floor(uv.y * textureHeight);
  
        // Create a canvas to extract the texture data
        const canvas = document.createElement('canvas');
        canvas.width = textureWidth;
        canvas.height = textureHeight;
        const context = canvas.getContext('2d');
        context.drawImage(texture.image, 0, 0, textureWidth, textureHeight);
  
        // Get pixel data
        const imageData = context.getImageData(pixelX, pixelY, 1, 1).data;
  
        // Check alpha value
        return imageData[3] > 0; // Alpha value > 0 means the pixel is not fully transparent
      }
  
      // Keep other objects except lines
      return !object.type.toLocaleLowerCase().includes("line");
    });
  }
  

  
//For mobile view // All images
export function addDragEventListener(){


  let allProducts = document.querySelectorAll(".finish-drag-icon")

  // let addImageIcons = document.querySelectorAll(".product-icon-mobile")
  for (let i = 0; i < allProducts.length; i++) {
    allProducts[i].addEventListener("touchstart",startDragging,false)
    allProducts[i].addEventListener("touchmove",startMoving,false)
    allProducts[i].addEventListener("touchend",endDragging,false)
  }
}

function startDragging(event:any) {
  try {
    $("#draggableImg").fadeIn(0)
    removeClassFromElement("materialsWrapper","overflow-y-scroll")
    let touchLocation = event.targetTouches[0]
    let src = $(event.target).parents(".finish-image-container").find("img").attr("src")
    $("#draggableImg").css("left",touchLocation.pageX + 'px')
    $("#draggableImg").css("top",touchLocation.pageY + 'px')
    $("#draggableImg").attr("src",src)
  } catch (error) {
    $("#draggableImg").fadeOut(0)
  }
  
}

function startMoving(event:any) {
  try {
    let touchLocation = event.targetTouches[0]
    $("#draggableImg").css("left",touchLocation.pageX + 'px')
    $("#draggableImg").css("top",touchLocation.pageY + 'px')
  } catch (error) {
    $("#draggableImg").fadeOut(0)
  }
  
}

function endDragging(event:any) {
  try {
    $("#draggableImg").fadeOut(0)
    addClassInElement("materialsWrapper","overflow-y-scroll")
    let texture={
      companyName:$(event.target).parents(".finish-image-container").attr("data-company-name"),
      collectionName:$(event.target).parents(".finish-image-container").attr("data-collection-name"),
      materialCode: $(event.target).parents(".finish-image-container").attr("data-material-code"),
      materialType: $(event.target).parents(".finish-image-container").attr("data-material-type")
    }
    dropMaterial(event.changedTouches[0].clientX,event.changedTouches[0].clientY,texture,configuration,element,camera,scene,"customizein3d",configuration.product.productName,MODULE_NAME)
  } catch (error) {
    $("#draggableImg").fadeOut(0)
  }
  
}

  

