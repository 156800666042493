import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { updateProductTransform } from "../../../../../../services/api";
import { showAddProducts } from "../../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { endRotation, onDblClick, onMouseMove, onPointerDown, onPointerUp, rotationRangeSliderHandle, startRotation, updateHeightUsingTruck } from "../../../../../../utils/customizein3d/area3dmodel/actions";
import { area3dModelInit, customizerConfig, deselectCanvas, deselectProduct, dimensions, disableClickHint, drop, enableFloorplanMode, floorplanner, projectConfiguration, traceFloorplan, updateAreaCustomizer } from "../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { allowDrop, configureProduct, confirmAddProduct } from "../../../../../../utils/customizein3d/area3dmodel/dragging";
import { disabeSetWallProductPositionMode } from "../../../../../../utils/customizein3d/area3dmodel/walls";
// import { addArea3dModelToScene, area3dModelInit, loadModuleToTheScene } from "../../../../../../utils/customizein3d/area3dModel";
import { hideComponentLoader } from "../../../../../../utils/UI_methods/global";
import { disableIsolationView } from "../../../../../../utils/customizein3d/area3dmodel/isolationView";
import { deleteSelectedProducts, disableMultiselectMode } from "../../../../../../utils/customizein3d/area3dmodel/multiselect";
import { customizeProduct, disableProductConfigureMode, enableProductConfigureMode, pointerMoveAnnotationDragAction } from "../../../../../../utils/customizein3d/area3dmodel/productConfigure";
import { pointerDownDragIcon, pointerUpDragIcon } from "../../../../../../utils/customizein3d/modules/annotations";
import { ProjectConfiguration } from "../../../../../../utils/customizer/ProjectConfiguration";
import { applicationConfig } from "../../../../../../utils/methods";
import Floorplan from "../../../../../Floorplan/Floorplan";
import KitchenPlanner from "../../../../../common/modules/KitchenPlanner/KitchenPlanner";
import UnitSelector from "../../../../../common/modules/customizein3d/common/UnitSelector";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../../../common/ui-components/buttons/Button2";
import CircleRangeSlider from "../../../../../common/ui-components/input/CircleRangeSlider";
import RangeSlider from "../../../../../common/ui-components/input/RangeSlider";
import Toast from "../../../../../common/ui-components/notification/Toast";
import "../../../../../style/admin/customizein3d.css";
import Compass from "../common/Compass";
import EditFloorplanOptions from "../common/EditFloorplanOptions";
import Annotations from "./Annotations";
import AreasList from "./AreasList";
import BottomRightActions from "./BottomRightActions";
import ElevationView from "./ElevationView";
import FinalizeRender from "./FinalizeRender";
import InteriorView from "./InteriorView";
import ModesSelector from "./ModesSelector";
import ProductActions from "./ProdcutActions";
import ProductConfigure from "./ProductCongure";
import TopMiddle from "./TopMiddle";
import AreaDetailsInterface from "./floorplan/AreaDetailsInterface";
import CreateRender from "./floorplan/CreateRender";
 



const Area3DScene: React.FC<{
  configObj:any
  currentConfigObject:any
  areaName:string
  projectConfig:ProjectConfiguration
  isProductConfigureMode:boolean
  isKitchenPlannerMode:boolean
  isInteriorVisualization:boolean
  isProductRenderMode:boolean
}> = (props) => {

  const[showDeselect,setShowDeselect]:any = useState(false)
  const[isSpacePlannerMode,setIsSpacePlannerMode]:any = useState(false)

  
  const[isFloorPlanMode,setIsFloorPlanMode] = useState(false)
  const[isFinalizeMode,setIsFinalizeMode] = useState(false)

  const[isTraceFloorplanMode,setIsTraceFloorplanMode] = useState(false)

  useEffect(()=>{
    if(props.currentConfigObject && props.projectConfig){
      // init({})
      hideComponentLoader("initialLoaderId")
      setTimeout(() => {
        area3dModelInit(props.currentConfigObject,props.projectConfig)
      }, 500);
    
      props.configObj.setFunctionRef("customizer",{setIsFloorPlanMode:setIsFloorPlanMode})   
      props.configObj.setFunctionRef("customizer",{setIsTraceFloorplanMode:setIsTraceFloorplanMode})   
      props.configObj.setFunctionRef("customizer",{setIsFinalizeMode:setIsFinalizeMode})   
      props.configObj.setFunctionRef("customizer",{setIsSpacePlannerMode:setIsSpacePlannerMode})   
    }
  },[props.currentConfigObject,props.projectConfig])

  useEffect(()=>{
    // if(isFloorPlanMode){
    //   $(".header-middle-content").css("visibility","hidden")
    // }else{
    //   $(".header-middle-content").css("visibility","visible")
    // }
    customizerConfig?.setIsFloorplanMode(isFloorPlanMode)
    applicationConfig.functions.customizer.setIsRefreshHeaderMiddle(Number(new Date()))
  },[isFloorPlanMode,isFinalizeMode,props.isInteriorVisualization])




 
  return (
    <React.Fragment>
        
       
      {props.isKitchenPlannerMode?
        <KitchenPlanner
        />
      :null}

      {props.isProductConfigureMode?
        <ProductConfigure
          isSpacePlannerMode = {isSpacePlannerMode}
          setIsSpacePlannerMode={setIsSpacePlannerMode}
        />
      :null}
 

      <Loader
          id="setCameraHeightLoader"
          className="fade-background-loader"
          loaderType="dots"
          data={{isShowMessage:false}}
        />

      <EditFloorplanOptions/>

      {!isSpacePlannerMode && !isFinalizeMode && !props.isInteriorVisualization && !isTraceFloorplanMode? 
        <Compass/>
      :null}
      

     

      <div className="top-left full-width overflow-hidden pointer-none" style={{background:"transparent"}}>
        <Loader
          id="addProductLoader"
          className="fade-bac"
          loaderType="topProgressBar"
          data={{isShowMessage:false}}
        />
      </div>

      <div className="bottom-right display-none zindex30 ion-margin-bottom" style={{marginBottom:"100px"}}>
        <Button2
          configObj={props.configObj}
          action={()=>updateProductTransform(props.areaName)}
          label={"Update tranform"}
          fontAwesomeIcon={"fas fa-save color-danger"}
          iconPos="left"
          classNames={"shadow-light"}
        />
      </div>
     

     
     <div className="full-height-width modular-product-actions display-none">
        <div className="tour-information middle center-aligned-column">
              <div className="tour-button center-aligned-row">
                  <div className="color-white click-effect font-medium zindex30 border" onClick={confirmAddProduct}>Add product</div>
                  <div className="color-white click-effect bg-color-primary  font-medium marginleft5 zindex30 border" onClick={configureProduct}>Configure product</div>
              </div>
          </div>   
     </div>

     <Annotations/>
   


      {!isFloorPlanMode && !isFinalizeMode && !props.isInteriorVisualization && !isTraceFloorplanMode? 
       <div className="bottom-middle hide-on-measurement ion-padding hide-on-rotation ion-margin-bottom hide-in-elevation-view hide-in-isolation-view hide-on-multiselect hide-on-dragging hide-on-wall-focus" style={{zIndex:101}}>
        <ModesSelector
          isProductConfigureMode={props.isProductConfigureMode}
        />
       
      </div>
      :null}

     


      <div className="bottom-middle display-none hide-on-measurement hide-on-wall-focus zindex100 hide-on-dragging product-rotation-handler" style={{bottom:"3.5rem"}}>
        <div id="" className="product-rotation-slider display-none">
          <RangeSlider
            id="rangeSlider"
            action={rotationRangeSliderHandle}
            pointerUp={endRotation}
            pointerDown={startRotation}
          />
        </div>
      </div>

  



     

   
      
        <div className="position-relative full-height-width">
        <Loader
          className="fade-background-loader"
          id="changeFinishLoader"
          data={{}}
          loaderType="dots"
        />

        <Loader
          className="fade-background-loader"
          id="saveConfigLoader"
          data={{}}
          loaderType="dotsText"
        />

        <Loader
          className="fade-background-loader"
          id="loadShellProductsLoader"
          data={{}}
          loaderType="dots"
        />
        {!isTraceFloorplanMode?
          <BottomRightActions
            isSpacePlannerMode = {isSpacePlannerMode}
            isKitchenPlannerMode={props.isKitchenPlannerMode}
          />
        :null}
        

        

        <ProductActions
          configObj={props.configObj}
        />

      
        {/* <TopRightActions/> */}
       
      
        <div className="position-relative background-white full-width"
          id="area3DSceneWrapper"
          onDragOver={allowDrop}
          onDrop={drop}
          onPointerMove={onMouseMove}
          onPointerDown={onPointerDown}
          onPointerUp={onPointerUp}
          onDoubleClick={onDblClick}
        >

        {isTraceFloorplanMode || (isFloorPlanMode && projectConfiguration.isFloorplanCreatedFromShape)?
          <Floorplan
            floorplan={floorplanner}
            isFloorplanMode={isFloorPlanMode}
            enableFloorplanMode={traceFloorplan.enableTraceFloorplan}
            disableFloorplanMode={traceFloorplan.disableTraceFloorplan}
            moduleName="customizer"
          />
        :
          null
        }

        <div id="moduelsDragIcon" onPointerMove={pointerMoveAnnotationDragAction} onPointerDown={pointerDownDragIcon} onPointerUp={pointerUpDragIcon} className="top-left zindex30 white-card display-none visibility-hidden no-shadow zindex100 center-aligned-column bg-color- pointer padding0" style={{width:"25px",height:"25px",transition:"none",background:"rgba(255,255,255,0.6)",transformOrigin:"center"}}> <i className="fas fa-arrows-alt pointer-none font-medium color-dark"></i> </div>

          <div id="productRotationRangeSlider" className="product-rotation-slider  display-none top-left padding0 pointer-none">
            <CircleRangeSlider
              id="area3DSceneWrapper"
              onRotate={rotationRangeSliderHandle}
              pointerUp={endRotation}
              pointerDown={startRotation}
            />
          </div>

          {isFloorPlanMode && !isTraceFloorplanMode?
          <AreaDetailsInterface
            isFinalizeMode={isFinalizeMode}
            isFloorPlanMode={isFloorPlanMode}
          />
          :null}

          {isFinalizeMode && !isTraceFloorplanMode?
          <CreateRender
            isFinalizeMode={isFinalizeMode}
            isFloorPlanMode={isFloorPlanMode}
          />
          :null}
       
          <div className="top-left full-height-width zindex30 click-hint-wrapper" onClick={disableClickHint} id="clickHintWrapper">
            <div id="clickHint" className="top-left">
              <div className="font-large text-center color-white click-hint-text">Click on any part to start customize</div>
              <img src="assets/gif/clickhint.gif" width={50} height={50} alt="" />
            </div>
            <div className="top-right zindex30" onClick={disableClickHint}>
                <IonIcon className="font-ex-large color-white" icon={close} />
            </div>
          </div>
        </div>
      
    

        <TopMiddle
            configObj={props.configObj}
            currentConfigObject={props.currentConfigObject}
            showDeselect={showDeselect}
            deselectCanvas={deselectCanvas}
          />
     

          <Toast/>

          {isFinalizeMode || props.isInteriorVisualization?
            <div id="rangeSliderForInteriorView" className="rig initial-hide " style={{position:"absolute",right:"-15%",top:"49%",transform:"translate(-50%,-50%)",zIndex:1001}}>
              <div className="position-relative" style={{width:"320px"}}>
                <input type="range" className="range-slider camera-height-slider sm-range-slider" onChange={updateHeightUsingTruck} id="rangeSliderHeight" style={{transform:"rotate(-90deg)"}}/>
                <div className="movement-control padding5 bottom-left color-black label white-card no-shadow heading2 uppercase text-center" style={{bottom:"-200px",left:"42%"}}>(Height)</div>
              </div>
          </div>
          :null}



          {!isFloorPlanMode && !isFinalizeMode?
            <AreasList
              currentConfigObject={props.currentConfigObject}
            />
          :null}
          
             {/* <div className="bottom-left zindex30 padding10 pointer center-aligned-column" onClick={}>
              <div className="heading2 letter-spacing font-normal display-flex-row uppercase color-black"> 
                <i className="fas fa-plus color-primary  center-aligned-column"></i> <span className="hide-in-mobile marginleft5">Add products</span>
              </div>
            </div> */}

            <div className="zindex30 hide-on-wall-focus hide-in-elevation-view hide-in-isolation-view pointer top-right display-flex-column hide-in-kitchen-planner hide-on-multiselect font-medium" id="">
            {!isFloorPlanMode && !isFinalizeMode && !props.isInteriorVisualization && !isTraceFloorplanMode?
            <div className="display-flex-row">
              <div className="customization-cancel-button hide-on-rotation  zindex30">
              <Button2
                  configObj={props.configObj}
                  action={deselectProduct}
                  label={"Deselect"}
                  fontAwesomeIcon={"fas fa-times color-danger"}
                  iconPos="left"
                  classNames={"shadow-light bg-color-black"}
              />
              </div>

              <div className="display-none" id="exitRoomButton">
                <Button2
                  configObj={props.configObj}
                  action={()=>updateAreaCustomizer("all")}
                  label={"Exit room"}
                  fontAwesomeIcon={"fas fa-times color-white"}
                  iconPos="left"
                  classNames={"shadow-light marginleft5 bg-color-black"}
                />
              </div>

              <div className="add-products-button hide-on-rotation  hide-on-measurement hide-on-configure">
                <div className="display-flex-row no-wrap">
                  {isSpacePlannerMode?
                    <Button2
                      configObj={props.configObj}
                      action={enableFloorplanMode}
                      label={"Edit Floorplan"}
                      fontAwesomeIcon={"fas fa-edit color-primary"}
                      iconPos="left"
                      classNames={"shadow-light border-primary"}
                    />
                  :null}
                  
                  <Button2
                    configObj={props.configObj}
                    action={showAddProducts}
                    label={"Products"}
                    fontAwesomeIcon={"fas fa-plus-square color-white"}
                    iconPos="left"
                    classNames={"shadow-medium marginleft5 no-border bg-color-primary"}
                  />
                </div>
                
              </div>

              <div className="marginleft5">
                <Button2
                    configObj={props.configObj}
                    action={disableProductConfigureMode}
                    label={"Exit configure mode"}
                    fontAwesomeIcon={"fas fa-times color-"}
                    iconPos="left"
                    classNames={"shadow-light display-none exit-configure-mode-button bg-color-black"}
                />
              </div>
              
              
            </div>
              
              :null}
            </div>

            <div id="confirmAddProduct" className="zindex1 confirm-add-product pointer top-middle display-flex-column font-medium">
              {!isFloorPlanMode && !isFinalizeMode?
                <div className="background-white ion-padding white-card">
                <div className="display-flex-row marginBottom5">
                  <div className="heading1 font-small" id="currProductAddingArea"></div>
                </div>
                <div className="center-aligned-column">
                  <Button2
                    configObj={props.configObj}
                    action={confirmAddProduct}
                    label={"Confirm"}
                    fontAwesomeIcon={"fas fa-save color-primary"}
                    iconPos="left"
                    classNames={"shadow-light bg-color-primary"}
                  />
                </div>
              </div>
              :null}
            </div>
          
            {!isFloorPlanMode && !isFinalizeMode && !props.isKitchenPlannerMode && !isTraceFloorplanMode?
            <div className="display-flex-column pointer hide-on-measurement hide-on-rotation hide-in-elevation-view ion-margin hide-in-isolation-view hide-in-kitchen-planner bottom-right hide-on-multiselect hide-on-wall-focus">
              
              
              <FinalizeRender
                isProductConfigureMode={props.isProductConfigureMode}
                isProductRenderMode={props.isProductRenderMode}
              />
            </div>
            :null}

            {props.isInteriorVisualization && !isFinalizeMode?
             <InteriorView
             />
           :null}


              <div className="disable-close-wall-button --is-hide  zindex30 top-right">
                <Button2
                  configObj={props.configObj}
                  action={disabeSetWallProductPositionMode}
                  label={"Close"}
                  fontAwesomeIcon={"fas fa-times color-danger"}
                  iconPos="left"
                  classNames={"shadow-light bg-color-black"}
                />
              </div>

              <div className="zindex30 top-right show-on-multiselect display-none ion-margin-bottom">
                <Button2
                  configObj={props.configObj}
                  action={disableMultiselectMode}
                  label={"Disable multiselect"}
                  fontAwesomeIcon={"fas fa-times color-danger"}
                  iconPos="left"
                  classNames={"shadow-light bg-color-black"}
                />
              </div>

              <div className="zindex30 bottom-middle show-on-multiselect display-none ion-margin-bottom">
                <div className="white-card shadow-light padding10 center-aligned-row" style={{width:"auto"}}>
                  <Button2
                    configObj={props.configObj}
                    action={()=>{}}
                    label={"Arrange "}
                    fontAwesomeIcon={"fas fa-copy color-primary"}
                    iconPos="left"
                    classNames={"shadow-light bg-color"}
                  />

                  <Button2
                    configObj={props.configObj}
                    action={()=>{}}
                    label={"Group"}
                    fontAwesomeIcon={"fas fa-copy color-primary"}
                    iconPos="left"
                    classNames={"shadow-light bg-color"}
                  />

                  <Button2
                    configObj={props.configObj}
                    action={()=>{}}
                    label={"Duplicate"}
                    fontAwesomeIcon={"fas fa-copy color-primary"}
                    iconPos="left"
                    classNames={"shadow-light bg-color"}
                  />

                  <Button2
                    configObj={props.configObj}
                    action={deleteSelectedProducts}
                    label={"Delete items"}
                    fontAwesomeIcon={"fas fa-trash color-danger"}
                    iconPos="left"
                    classNames={"shadow-light bg-color marginleft10"}
                  />
                </div>
              </div>
              

              <div className="bottom-middle padding ion-margin-bottom" style={{zIndex:1001}}>
                <Button2
                    configObj={props.configObj}
                    action={()=>enableProductConfigureMode()}
                    label={"Configure product"}
                    fontAwesomeIcon={"fas fa-cog color-"}
                    iconPos="left"
                    classNames={"shadow-light display-none button-large product-configure-button bg-color-primary"}
                />
              </div>

              {props.isProductConfigureMode?
                <div className="bottom-middle padding ion-margin-bottom">
                <Button2
                    configObj={props.configObj}
                    action={customizeProduct}
                    label={"Customize"}
                    fontAwesomeIcon={"fas fa-cog color-"}
                    iconPos="left"
                    classNames={"shadow-light button-large display-none product-customize-button bg-color-primary"}
                />
                </div>
              :null}

              <div id="disableIsolationViewButton" className="display-none  zindex30 top-right">
                <Button2
                  configObj={props.configObj}
                  action={disableIsolationView}
                  label={"Exit View"}
                  fontAwesomeIcon={"fas fa-times color-danger"}
                  iconPos="left"
                  classNames={"shadow-light bg-color-black"}
                />
              </div>


              <ElevationView/>
 

          </div>

    </React.Fragment>
  )
};

export default Area3DScene;
