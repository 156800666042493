import $ from "jquery";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { createAndSaveRender, createShell, updateVisualizerConfig, uploadRenderToS3 } from "../../services/api";
import { hideCanvasLoader } from "../UI_methods/canvas";
import { hideComponentLoader, showComponentLoader, showGlobalToast, showToast, updateLoaderProgress } from "../UI_methods/global";
import { deleteFromCache, saveToCache } from "../cache/cache";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../cache/localstorage";
import { resetRenderTime, updateRenderTime } from "../customizein3d/area3dmodel/UI_methods";
import { IS_SPACE_PLANNER_MODE, composer, controls, customizerConfig, disableFinalizeMode, labelRenderer, perspectiveCamera, projectConfiguration, renderer, scene } from "../customizein3d/area3dmodel/area3dModel";
import { hideCameraPropertiesPanel } from "../customizein3d/area3dmodel/floorplan/floorplanUI";
import { cameraFor2dRender, isViewRenderMode, renderSettingConfig, resetCameraFor2dRender, saveRenderCameraFor2D, update2dRenderImageInCache } from "../customizein3d/area3dmodel/floorplan/render2d";
import { saveConfiguration } from "../customizein3d/area3dmodel/helper";
import { disableInteriorVirtualization, isInteriorVisualization } from "../customizein3d/area3dmodel/interior_visualization";
import { configuration } from "../customizein3d/modules/customizein3d";
import { applicationConfig, categoryPartTypeFilteredArray, compareArrays, disposeVariables, getCategoryFiltersMaterials, getCategoryName, getCurrentConfigurationFromClass, getCurrentConfigurationFromLocalStorage, getCurrentConfigurationKey, getFilteredArray, getMaterialTypes, getObjectByParameter, getPartIdFromName, getPartName, getProductName, getProductPartConfigKey, getRandomArray, getSavedConfigByName, getViewName, logger, setCurrentConfigurationInLocalStorage, stringWithoutWhiteSpace, waitFor } from "../methods";
import { customizerRenderConfig } from "../renders/renderConfig";
import { ProjectConfiguration } from "./ProjectConfiguration";
import { Quaternion, Vector3 } from "three";
 
var COLOUR_SWATCH_MATERIALS = ["Walls", "Curtain","Wall"];
var testAreaName = "BedRoom2"
var timeInterval = null
export var createRenderLogs = []
const exporter = new GLTFExporter();
let options = {
  trs:true,
  binary:true
}

export function getObjectUrlMapping(canvasImages: any) {
    const items: any = {};
    for (const objectType in canvasImages) {
      let object: any = canvasImages;
      let objects = object[objectType];
      for (const objectName in objects) {
        items[objectName] = objects[objectName];
      }
    }
    return items;
  }

  export function getCanvasElements(BASE_PATH:string,projectName:string, areaName:string,viewName:string,foregroundParts: Array<any>,backgroundParts: Array<string>,clientName:string,currentConfigObject:any) {
    var myObject: any = {};
    var foreground: any = {};
    var background: any = {};
    for (let partObject of foregroundParts){
        //Partname is uniue for every product parts
        let objectLabel = partObject.label
        let partname = partObject.partname 
        let productName = getProductName(partname)
        let categoryName = getCategoryName(partname)
        let key = getCurrentConfigurationKey(projectName,areaName)
        let savedConfig = getCurrentConfigurationFromClass(currentConfigObject,key)
        let configKey = getProductPartConfigKey(areaName,viewName,productName,objectLabel,categoryName)
        // let companyName = savedConfig[configKey].companyName
        let collectionName = savedConfig[configKey]?.collectionName
        let materialCode = savedConfig[configKey]?.materialCode
      let image = getForegroundImageUrl("",clientName,projectName,areaName,viewName,productName,collectionName,materialCode,objectLabel);
      foreground[partname] = image;
    }
    if(backgroundParts){
      for (let objectName of backgroundParts) {
        let image = getBackgroundurl("",clientName,projectName,areaName,viewName,objectName);
        background[objectName] = image;
      }
    }
    myObject.foreground = foreground;
    myObject.background = background;
    disposeVariables([foreground,background])
    return getObjectUrlMapping(myObject);
  }


  export function getConfiguration(configObj:any,products:any,foregroundParts:any,projectName:string,areaName:string,viewList:any,textures:any,objectMaterialTypes:any,customizableObjects:any,categoryMaterialTypes:Array<any>,savedConfiguration:Array<any>,viewName:string){
    let configurationObject:any = {}
    let key = getCurrentConfigurationKey(projectName,areaName)
    let defaultViewForReplicate = null
    let updatedTexture = []
    let selectedConfiguration:Array<any>
    
    if(getCurrentConfigurationFromLocalStorage(key)!){
      return getCurrentConfigurationFromLocalStorage(key)!  
    }
      if(savedConfiguration && savedConfiguration.length>0){
      // if(false){
      let configName = savedConfiguration[0].config_name
      // let viewName = savedConfiguration[0].view_name
      selectedConfiguration = getSavedConfigByName(savedConfiguration,configName)!

      let productsArray = products.filter((product:any)=>product.view_name===viewName)
      for (const product of productsArray) {
        let productParts = foregroundParts[product.category_name]
        productParts?.push("Other")
        if(!productParts?.length){
          continue
        }
        for (const partname in productParts) {
          let key = getProductPartConfigKey(areaName,viewName,product.product_name,productParts[partname],product.category_name)
          let partId = getPartIdFromName(productParts[partname],customizableObjects)
          let materialsDetails = getDetailsFromSavedConfig(selectedConfiguration,product.product_id,partId,productParts[partname],textures,objectMaterialTypes,categoryMaterialTypes,product.category_name)
          
          configurationObject[key]  = {
            materialId : materialsDetails.materialId,
            productId : product.product_id,
            partId:partId,
            companyName : materialsDetails.companyName,
            materialCode : materialsDetails.materialCode,
            colorOptionId:materialsDetails.colorOptionId,
            collectionName:materialsDetails.collectionName,
            materialType:materialsDetails.materialType,
            
          }
        }
      }   
      defaultViewForReplicate = selectedConfiguration[0].view_name
    }else{
        for (const viewName of viewList) {   
          let productsArray = products.filter((product:any)=>product.view_name===viewName)
          for (const product of productsArray) {
              //Fiter textures with product category
            updatedTexture = getCategoryFiltersMaterials(textures,configObj.data.projectMaterials,product.category_id)

            //If product has no 
            // console.log(product.category_name,updatedTexture)
            //If product has no parts
            let productParts = foregroundParts[product.category_name]
            productParts.push("Other")
            if(!productParts?.length){
              continue
            }
  
            let storeInfoForReplicate:any = {
              configuration:{},
              materialTypes:[]
            }
            //index will be used to store the value of no default type material
            let index = 0
  
            for (const partname in productParts) {
              let key = getProductPartConfigKey(areaName,viewName,product.product_name,productParts[partname],product.category_name)
              let partId = getPartIdFromName(productParts[partname],customizableObjects)
              let materialsDetails = getCompanyNameMaterialCode(updatedTexture,objectMaterialTypes,productParts[partname],configurationObject,areaName,viewName,product.product_name,categoryMaterialTypes,product.category_name)
              
              // Get material types and check if materials are same for the first part and its remaining part
              let materialTypes = getMaterialTypes(objectMaterialTypes,product.category_name,productParts[partname])
          
              if(materialTypes.length===0){
                index = index+1
              }
              if(parseInt(partname)===index){
                storeInfoForReplicate = {
                  configuration:materialsDetails,
                  materialTypes:materialTypes
                }
                configurationObject[key]  = {
                  materialId : materialsDetails.materialId,
                  productId : product.product_id,
                  partId:partId,
                  companyName : materialsDetails.companyName,
                  materialCode : materialsDetails.materialCode,
                  colorOptionId:materialsDetails.colorOptionId,
                  collectionName:materialsDetails.collectionName,
                  materialType:materialsDetails.materialType,

                }
              }else{
                //If materials are same return the previous config
                if(compareArrays(storeInfoForReplicate.materialTypes,materialTypes)){
                  configurationObject[key]  = {
                    materialId : storeInfoForReplicate.configuration.materialId,
                    productId : product.product_id,
                    partId:partId,
                    companyName : storeInfoForReplicate.configuration.companyName,
                    materialCode : storeInfoForReplicate.configuration.materialCode,
                    colorOptionId:storeInfoForReplicate.configuration.colorOptionId,
                    collectionName:storeInfoForReplicate.configuration.collectionName,
                    materialType:storeInfoForReplicate.configuration.materialType,
                  }
                }else{
                  //Else return random one
                  configurationObject[key]  = {
                    materialId : materialsDetails.materialId,
                    productId : product.product_id,
                    partId:partId,
                    companyName : materialsDetails.companyName,
                    materialCode : materialsDetails.materialCode,
                    colorOptionId:materialsDetails.colorOptionId,
                    collectionName:materialsDetails.collectionName,
                    materialType:materialsDetails.materialType,
                  }
                }
              }
            }
          }    
        }
      defaultViewForReplicate = viewName
    }
     
      //replicate config for every view
      let config = replicateCurrentConfig(configObj,configurationObject,viewList,products,foregroundParts,areaName,textures,objectMaterialTypes,categoryMaterialTypes,customizableObjects,defaultViewForReplicate)
      setCurrentConfigurationInLocalStorage(key,config)
      disposeVariables([updatedTexture,selectedConfiguration,configurationObject])
      return config  
    // }
  }


  function replicateCurrentConfig(configObj:any,configurationObject:any,viewList:any,products:any,foregroundParts:any,areaName:string,textures:Array<any>,objectMaterialTypes:Array<any>,categoryMaterialTypes:Array<any>,customizableObjects:Array<any>,defaultView:string){

    let productsArray = []
    let updatedTexture = []
    let productParts = []
    let materialTypes = []
    //Default config should be 2 in this case 
    for (const viewName of viewList) {   
      if(viewName===defaultView){
        continue
      }
      productsArray = products.filter((product:any)=>product.view_name===viewName)
      for (const product of productsArray) {
        updatedTexture = getCategoryFiltersMaterials(textures,configObj.data.projectMaterials,product.category_id)

        productParts = foregroundParts[product.category_name]
        productParts.push("Other")
        if(productParts?.length===0){
          continue
        }
        //Check if product exits in default view and does not exits in the curr view then continue
        //If product exits in curr view but does not exits in default view -> generate the random config
        let storeInfoForReplicate:any = {
          configuration:{},
          materialTypes:[]
        }
        //index will be used to store the value of no default type material
        let index = 0
        for (const partname in productParts) {
          let defaultCamKey = getProductPartConfigKey(areaName,defaultView,product.product_name,productParts[partname],product.category_name)
          //dont save for curr view if the product does not exits in the view 
          if(configurationObject[defaultCamKey]){
            let companyName = configurationObject[defaultCamKey].companyName
            let collectionName = configurationObject[defaultCamKey].collectionName
            let materialCode = configurationObject[defaultCamKey].materialCode
            let materialId = configurationObject[defaultCamKey].materialId
            let colorOptionId = configurationObject[defaultCamKey].colorOptionId
            let productId = configurationObject[defaultCamKey].productId
            let partId = configurationObject[defaultCamKey].partId
            let materialType = configurationObject[defaultCamKey].materialType
            let otherKey = getProductPartConfigKey(areaName,viewName,product.product_name,productParts[partname],product.category_name)
            configurationObject[otherKey] = {
              materialId : materialId,
              productId : productId,
              partId:partId,
              companyName : companyName,
              materialCode : materialCode,
              colorOptionId:colorOptionId,
              collectionName:collectionName,
              materialType:materialType
              

            }
            
          }else{
            //Generate the random configuration if the product exits in the view 
            let partId = getPartIdFromName(productParts[partname],customizableObjects)
            let otherKey = getProductPartConfigKey(areaName,viewName,product.product_name,productParts[partname],product.category_name)
            let materialsDetails = getCompanyNameMaterialCode(updatedTexture,objectMaterialTypes,productParts[partname],configurationObject,areaName,viewName,product.product_name,categoryMaterialTypes,product.category_name)
           
             // Get material types and check if materials are same for the first part and its remaining part
             materialTypes = getMaterialTypes(objectMaterialTypes,product.category_name,productParts[partname])
          
             if(materialTypes.length===0){
               index = index+1
             }
             if(parseInt(partname)===index){
               storeInfoForReplicate = {
                 configuration:materialsDetails,
                 materialTypes:materialTypes
               }
               configurationObject[otherKey]  = {
                 materialId : materialsDetails.materialId,
                 productId : product.product_id,
                 partId:partId,
                 companyName : materialsDetails.companyName,
                 materialCode : materialsDetails.materialCode,
                 colorOptionId:materialsDetails.colorOptionId,
                 collectionName:materialsDetails.collectionName,
                 materialType:materialsDetails.materialType
               }
             }else{
               //If materials are same return the previous config
               if(compareArrays(storeInfoForReplicate.materialTypes,materialTypes)){
                 configurationObject[otherKey]  = {
                   materialId : storeInfoForReplicate.configuration.materialId,
                   productId : product.product_id,
                   partId:partId,
                   companyName : storeInfoForReplicate.configuration.companyName,
                   materialCode : storeInfoForReplicate.configuration.materialCode,
                   colorOptionId:storeInfoForReplicate.configuration.colorOptionId,
                   collectionName:storeInfoForReplicate.configuration.collectionName,
                   materialType:storeInfoForReplicate.configuration.materialType,
                 }
               }else{
                 //Else return random one
                 configurationObject[otherKey]  = {
                   materialId : materialsDetails.materialId,
                   productId : product.product_id,
                   partId:partId,
                   companyName : materialsDetails.companyName,
                   materialCode : materialsDetails.materialCode,
                   colorOptionId:materialsDetails.colorOptionId,
                   collectionName:materialsDetails.collectionName,
                   materialType:materialsDetails.materialType,
                 }
               }
             }
          }
       
        }
      }    
    }
    disposeVariables([productsArray,updatedTexture,productParts,materialTypes])
    return configurationObject
  }

  export function updateProductNameWithVariant(products:Array<any>) {
    for (const product of products) {
      let variantSuffix = "_" + product.product_variant
      if(!product.product_name.includes(variantSuffix)){
        if(product.product_variant){
          product.product_name = product.product_name + variantSuffix
        }
      }
    }
    return products
  }


  export function getProductConfig(configuration:any,category:string,configObj:any,loadedProductParts:Array<any>) {
    let configArray = []
    for (const key in configuration) {
      let viewName = getViewName(key)
      //If calling from walkthrough there will be no viewname and loaded product parts
      if(key.includes(category) && (viewName===configObj.viewName || true) && (loadedProductParts.includes(key) || !loadedProductParts.length)){
        configArray.push({[key]:configuration[key]})
      }
    }
    return configArray
  }


  export function showProductDesc(event:any) {
    $(".product-config-tooltip").removeClass("--is-active")
    $(event.target).siblings(".product-config-tooltip").addClass("--is-active")
  }
  
  export function hideProductConfigTooltip() {
    $(".product-config-tooltip").removeClass("--is-active")
  }
  

  function getCompanyNameMaterialCode(textures:any,objectMaterialTypes:any,partName:string,configurationObject:any,areaName:string,viewName:string,productName:string,categoryMaterialTypes:Array<any>,categoryName:string){
    let updatedTextures = categoryPartTypeFilteredArray(textures,objectMaterialTypes,partName,categoryName)
    if(updatedTextures.length==0){
      return {companyName:"Default",materialCode:"Default",collectionName:"Default",materialType:"Default",materialId:0,colorOptionId:0}
    }
    let randomObject = getRandomArray(updatedTextures)
    disposeVariables([updatedTextures])
    return {companyName:randomObject.company_name,collectionName:randomObject.collection_name,materialCode:randomObject.material_code,materialType:randomObject.material_type,materialId:randomObject.id,colorOptionId:0}
  }

  function getDetailsFromSavedConfig(configuration:Array<any>,productId:number,partId:number,partName:string,textures:Array<any>,objectMaterialTypes:Array<any>,categoryMaterialTypes:Array<any>,categoryName:string){
    let updatedArray = configuration.filter((config:any)=>config.customizable_object_id===partId&&config.product_id===productId)
    let myObj = updatedArray[0]
    if(COLOUR_SWATCH_MATERIALS.includes(partName)){
      return {companyName:"Default",materialCode:"Default",collectionName:"Default",materialId:0,materialType:"Default",colorOptionId:myObj.color_option_id}
    }

    let updatedTextures = categoryPartTypeFilteredArray(textures,objectMaterialTypes,partName,categoryName)
    // console.log(textures)
    if(updatedTextures.length==0){
      return {companyName:"Default",materialCode:"Default",collectionName:"Default",materialType:"Default",materialId:0,colorOptionId:0}
    }
    disposeVariables([updatedTextures,updatedArray])
    if(myObj){
      return {companyName:myObj.company_name,collectionName:myObj.collection_name,materialCode:myObj.material_code,materialId:myObj.material_id,materialType:myObj.material_type,colorOptionId:myObj.color_option_id}
    }else{
      return {companyName:"Default",materialCode:"Default",collectionName:"Default",materialId:0,materialType:"Default",colorOptionId:0}
    }
  }



  export function getAreaViewList(areaName:string,products:any){
    products =  products.filter((product:any)=>product.area_name===areaName)
    let views = getViewsArray(products)
    return {[areaName]:views}
  }

  export function getAreaViewProductList(viewsList:Array<string>,products:any,areaName:string){
    let viewProductsMapping = {}
    for (const viewName of viewsList) {
      let viewProducts = products.filter((product:any)=>product.view_name===viewName)
      let key = `${areaName}.${viewName}`
      viewProductsMapping[key] = viewProducts
    }
    return viewProductsMapping
  }

  export function getViewsArray(products:Array<any>){
    let views:any = new Set()
    products.forEach(product=>{
        views.add(product.view_name)
    })
    return Array.from(views)
}


export function toggleControlsOverlay(currStep:string) {
  if(currStep === "Finalize"){
    $("#controlsOverlay").addClass("--is-active")
  }else{
    $("#controlsOverlay").removeClass("--is-active")

  }
}

 export function getForegroundImageUrl(BASE_PATH:string,clientName: string,projectName: string,areaName:string,viewName:string,productName:string,collectionName: string,materialCode: string,objectLabel: string) {
  return `sprites/${clientName}/${projectName}/${areaName}_${viewName}/Foreground/${stringWithoutWhiteSpace(productName)}/${areaName}_${viewName}_${stringWithoutWhiteSpace(productName)}_${collectionName}_${materialCode}/${areaName}_${viewName}_${stringWithoutWhiteSpace(productName)}_${objectLabel}_${collectionName}_${materialCode}0000.png`;
}
export function getTarFileUrl(prefixUrl:string) {
  let index = prefixUrl.lastIndexOf("/") 
  let fileName = prefixUrl.substring(index)
  return `${prefixUrl}${fileName}.tar`;
}


export function getUrlPrefix(url:string) {
  let index = url.lastIndexOf("/") 
  return url.substring(0,index)
}
 
  export function getBackgroundurl(BASE_PATH:string,clientName: string,projectName: string,areaName:string,viewName:string,objectName:string) {
      return `sprites/${clientName}/${projectName}/${areaName}_${viewName}/Background/${objectName}/${areaName}_${viewName}_${objectName}0000.png`;
  }

  export function getBackgroundTarUrl(BASE_PATH:string,clientName: string,projectName: string,areaName:string,viewName:string,objectName:string) {
    return `sprites/${clientName}/${projectName}/${areaName}_${viewName}/Background/${objectName}/${objectName}.tar.gz`;
}

export   function getFloorOptionUrl(BASE_PATH:string,clientName:string,projectName:string,areaName:string,viewName:string,materialCode:string) {
    return `./sprites/${clientName}/${projectName}/${areaName}/${areaName}${viewName}/Background/FloorOptions/${materialCode}/Floor_${areaName}_${materialCode}_0000.png`;
  }

  function getRenderMinMax(split:number) {
    let renders = []
    for (let i = 0; i <= split; i++) {
      renders.push(i/split)
    }
    return renders
  }

  export function checkIFAllViewConfirmed(configObj:any,currentConfigObject:any){

    let viewsList = currentConfigObject.areaViewList[currentConfigObject.areaName]
    let confirmedViews = 0
    for (const viewName of viewsList) {
      let config = configObj?.data.customizerSavedConfigs?.filter(config=>config.project_id===currentConfigObject.projectId
        && config.area_id===currentConfigObject.areaId && config.view_name===viewName) || []
      if(config.length){
        confirmedViews++ 
      }
    }
    if(viewsList.length === confirmedViews){
      return true
    }
    return false
  }

  export function getConfirmedViewsList(configObj:any,currentConfigObject:any) {
    let viewsList = currentConfigObject.areaViewList[currentConfigObject.areaName]
    let confirmedViewsList = []

    //If No config in the database return first view as confirmed
    let areaConfiguration = configObj?.data.customizerSavedConfigs?.filter(config=>config.project_id===currentConfigObject.projectId
      && config.area_id===currentConfigObject.areaId) || []


    if(!areaConfiguration || !areaConfiguration?.length){
      return [viewsList[0]]
    }

    //Else return confirmed views list 

    return viewsList
    for (const viewName of viewsList) {
      let config = configObj?.data.customizerSavedConfigs?.filter(config=>config.project_id===currentConfigObject.projectId
        && config.area_id===currentConfigObject.areaId && config.view_name===viewName) || []
      if(config.length){
        confirmedViewsList.push(viewName) 
      }
    }
    return confirmedViewsList
  }

  export function openReviewWindow() {
    $("#customizeReviewWrapper").addClass("--is-active")
  }

  
  export function closeReviewWindow() {
    $("#customizeReviewWrapper").removeClass("--is-active")
  }


  async function preCreateRenderActions() {
    // $("#viewRendersButton")?.addClass("pulse-box")
    // $("#renderTime").removeClass("--is-hide")
    if(projectConfiguration.isProductRenderMode){
      showComponentLoader("canvasLoader")
      return
    }
    $(".anchor-icon-container").removeClass("pulse-anchor")
    timeInterval = setInterval(updateRenderTime,1000)
    createRenderLogs = []
    // let renders = getItemFromLocalStorage("renders")
    // renders[applicationConfig.projectId].isCreatingRender = true
    // addItemToLocalStorage("renders",renders)

    projectConfiguration.setIsCreatingRender(true)
    // applicationConfig.functions.customizer?.setIsRendersOpen(true)
    // applicationConfig.functions.customizer?.disableCameraSettingsMode()
    if(isInteriorVisualization){
      disableInteriorVirtualization()
    }
    if(customizerConfig.currentRenderingType === "2d"){
      applicationConfig.functions.customizer?.setCurrSelectedAreaGallery(cameraFor2dRender.areaName)
      closeCreateRenderMode()
    }
    if(customizerConfig.currentRenderingType === "3d"){
      closeCreateRenderMode()
    }
    applicationConfig.functions.customizer?.updateRenderTab(customizerConfig.currentRenderingType)
    applicationConfig.functions.customizer?.isCreatingRenderHeader(true)
    applicationConfig.functions.customizer?.setRefreshRenders(Number(new Date()))
    hideCameraPropertiesPanel()
    projectConfiguration.updateLocalStorage()
    $("#galleryButton").click()
  }

  async function  postCreateRenderActions(isSuccess:boolean = true) {
    $("#renderTime").addClass("--is-hide")
    clearInterval(timeInterval)
    resetRenderTime()
    if(projectConfiguration.isProductRenderMode){
      // window.location.href = "/customizein3d"
      showGlobalToast("Render created successfully")
      window.parent.document.getElementById('galleryButtonConfigurator').click();
      window.parent.document.getElementById('exitRenderModeButton').click();
      return 
    }
    projectConfiguration.setIsCreatingRender(false)
    projectConfiguration.setIsRenderCreated(true)
    applicationConfig.functions.customizer?.setIsRenderComplete(true)
    if(applicationConfig.functions.customizer?.setIsCreatingRender){
      applicationConfig.functions.customizer?.setIsCreatingRender(false)
    }
    applicationConfig.functions.customizer?.isCreatingRenderHeader(false)
    if(isSuccess){
      showToast("Walkthrough updated",2000)

      customizerConfig.setRenderEndTime(Number(new Date()))

      if(cameraFor2dRender && customizerConfig.currentRenderingType === "2d"){
        applicationConfig.functions.customizer?.setCurrSelectedAreaGallery(cameraFor2dRender.areaName)
        cameraFor2dRender.setIsRendered(true)

        //Dont append if camera id already exists 
        let existedCamera = getObjectByParameter(projectConfiguration.cameras,"cameraId",cameraFor2dRender.cameraId) 
        if(existedCamera){
          projectConfiguration.delete2dCamera(existedCamera)
        }


        cameraFor2dRender.setRenderTime(customizerConfig.renderStartTime,customizerConfig.renderEndTime)
        
        projectConfiguration.appendCamera(cameraFor2dRender)
        if(isViewRenderMode){
          await update2dRenderImageInCache(cameraFor2dRender)
        }
        resetCameraFor2dRender()
      }
      applicationConfig.functions.customizer?.refreshGallery(Number(new Date()))

    }
    projectConfiguration.updateLocalStorage()
  }

  function closeCreateRenderMode() {
    controls.reset()
    composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    disableFinalizeMode()
  }

  function addRenderLog(msg:string) {
    let time = new Date()
    createRenderLogs.push(time + msg)
    if(applicationConfig.functions.customizer?.setLogs){
      applicationConfig.functions.customizer?.setLogs([...createRenderLogs])
    }
  }

  export async function createRender(currentConfigObject:any,debugOptions:any,callBack:(val:boolean)=>void){
    showComponentLoader("changeFinishLoader")

    // let areasList = projectConfiguration?.areasList
    let camerasList = []
  
    customizerConfig.setRenderStartTime(Number(new Date()))


    if(customizerConfig.currentRenderingType === "2d"){
      camerasList = [cameraFor2dRender]
    }else{
      camerasList = getFilteredArray(projectConfiguration.cameras,"renderType","3d")
    }

    addRenderLog("Start creating render")
    await applicationConfig.awsConfig.removeAllMessages()
    await saveRenderCameraFor2D(cameraFor2dRender,perspectiveCamera).then(async (data:any)=>{
      // console.log(data)
      hideComponentLoader("changeFinishLoader")
      preCreateRenderActions()
      
      await generateShell().then(async (data)=>{
        projectConfiguration.setAreasIsChangedConfig(false)
        // applicationConfig.awsConfig.removeAllMessages()
        let timestamp = Number(new Date())
        let requestId = `${applicationConfig?.clientName}${timestamp}`
        let splitLimit = debugOptions.noOfSplit || 4
        // console.log(debugOptions)
        // return
        addRenderLog("Creating Split renders")
        // console.log("Creating Split renders")
        await createSplitRenders(camerasList,debugOptions,requestId)
        // console.log("Created split renders")
        await applicationConfig.awsConfig.pollForMessages(requestId,splitLimit,camerasList.length*splitLimit)
        // console.log("Removed All")
        await applicationConfig.awsConfig.removeAllMessages()
        addRenderLog("Created split renders")
        await waitFor(500)
        // console.log(debugOptions.noOfSamples)
        if(debugOptions.noOfSamples > 5){
          await waitFor(15000)
        }
        await mergeSplitRenders(camerasList,debugOptions,requestId)
        console.log("end merge")
        await waitFor(500)
        if(debugOptions.noOfSamples > 5){
          await waitFor(3000)
        }
        await updateRenderImages(camerasList,debugOptions,callBack)
      }).catch(err=>{
        postCreateRenderActions(false)
        hideComponentLoader("changeFinishLoader")
        showToast("Error in genrating shell",2000,"error")
        console.log(err)
      })
    }).catch(err=>{
      console.log(err)
      postCreateRenderActions(false)
      hideComponentLoader("changeFinishLoader")
      showToast("Error in saving camera",2000,"error")
    })
      return
      
    // }
  }



  export async function createSplitRenders(camerasList:any,debugOptions:any,requestId:string){
    let loaderId = "mainLoader"
    let splitLimit = debugOptions.noOfSplit || 8
    let renderMinMax = getRenderMinMax(splitLimit)
    let render_min_x = renderMinMax[0]
    let promises = []


    for (let index = 0; index < camerasList.length; index++) {
        await waitFor(100)
        // const areaName = camerasList[index].areaName
        for (let i = 0; i < renderMinMax.length-1; i++) {
          render_min_x = renderMinMax[i]
          const render_max_x = renderMinMax[i+1];
          let cameraObj = camerasList[index]
          let config = null
          let currentConfiguration = projectConfiguration
          if(window.location.pathname.includes("product")){
              currentConfiguration = new ProjectConfiguration(applicationConfig.clientId,applicationConfig?.clientName,0,configuration.product.productName)
          }
          config = new customizerRenderConfig(currentConfiguration,cameraObj)
          config.renderConfig.isMerge = false
          console.log(config)
          // config.renderConfig.setDimensions(Number(debugOptions.resolution.x),Number(debugOptions.resolution.y))
          config.renderConfig.setDimensions(renderSettingConfig.getResolutionX(),renderSettingConfig.getResolutionY())
          config.renderConfig.setPercentageResolution(Number(debugOptions.percentageResolution))
          config.renderConfig.setNoOfSamples(Number(debugOptions.noOfSamples))
          config.renderConfig.setRenderCount(i+1)
          config.renderConfig.setRenderMinMax(render_min_x,render_max_x)
          config.setCreateBlend(false)
          config.renderConfig.setRequestId(requestId)
          config.renderConfig.setSplitLimit(splitLimit)
          config.isProductRenderMode = projectConfiguration.isProductRenderMode
          // console.log(config.renderConfig.rendering.resolution,cameraFor2dRender.cropPoints)
          // config.setCameras(getFilteredArray(projectConfiguration.cameras,"areaName",areaName) )
          const promise = await createAndSaveRender(config,i*50,i)
          promises.push(promise)
          // console.log(config)
        }
      }
  
      return Promise.all(promises).then(async res=>{
        // console.log(res)
        // await waitFor(5000)
        // await moveSplitRendersToDestination(parameters).then(async data=>{
         
        // }).catch(err=>{
        //   logger.error("customizer","createrender",`Error in creating render`)
        //   errorCreateRenderAction(err,loaderId)
        //   return
        // })
        await waitFor(1000)
        // resolve("completed")
      }).catch(err=>{
        console.log(err)
        projectConfiguration.setIsCreatingRender(false)
        logger.error("customizer","createrender",`Error in creating render`)
        addRenderLog("Error in creating split renders")
        errorCreateRenderAction(err,loaderId)
        postCreateRenderActions(false)
        // reject(err)
      })

  }


  export async function mergeSplitRenders(camerasList:any,debugOptions:any,requestId:string) {
    let loaderId = "mainLoader"
    let splitLimit = debugOptions.noOfSplit || 4
    let timestamp = Number(new Date())

    updateLoaderProgress(loaderId,0,0,"Processing Render..")

    return new Promise(async (resolve:any,reject:any)=>{
      for (let index = 0; index < camerasList.length; index++) {
        let areaName = camerasList[index].areaName
        requestId = `${applicationConfig?.clientName}${areaName}${timestamp}`
        let camera = camerasList[index]
        let config = null
        let currentConfiguration = projectConfiguration
        if(window.location.pathname.includes("product")){
            currentConfiguration = new ProjectConfiguration(applicationConfig.clientId,applicationConfig?.clientName,0,configuration.product.productName)
        }
        config = new customizerRenderConfig(currentConfiguration,camera)
        config.renderConfig.isMerge = true
        config.renderConfig.setRequestId(requestId)
        config.renderConfig.setDimensions(renderSettingConfig.getResolutionX(),renderSettingConfig.getResolutionY())
        config.renderConfig.setPercentageResolution(Number(debugOptions.percentageResolution))
        config.renderConfig.setNoOfSamples(Number(debugOptions.noOfSamples))
        config.renderConfig.setRenderCount(1)
        config.renderConfig.setIsMergeTrue()
        config.renderConfig.setSplitLimit(splitLimit)
        config.isProductRenderMode = projectConfiguration.isProductRenderMode
        // console.log("Merge:",config)
        config.setCreateBlend(false)
        await createAndSaveRender(config,1000,0).then(async data=>{
          updateLoaderProgress(loaderId,0,0,"Updating walkthrough..")
          addRenderLog("Start merging " + areaName)
          await applicationConfig.awsConfig.pollForMessages(requestId,1)
          await waitFor(1000)
          addRenderLog("End merging " + areaName)
          // console.log(data)
          // Fetch the updated render from s3
          if(index === camerasList.length - 1){
            resolve("Completed")
          }
          
        }).catch(err=>{
          projectConfiguration.setIsCreatingRender(false)
          logger.error("customizer","createrender",`Error in creating render`)
          console.log("Error in merging images" + err)
          addRenderLog("Error in merging images" + err)
          if(index === camerasList.length - 1){
            errorCreateRenderAction(err,loaderId)
            postCreateRenderActions(false)
            reject("Completed")
          }
        })
      }
    })

   
    
  }


  async function updateRenderImages(camerasList:Array<any>,debugOptions:any,callBack:(val:boolean)=>void){
    addRenderLog("Start update render images")
    let loaderId = "mainLoader"
    for (let index = 0; index < camerasList.length; index++) {
      let cameraObj = camerasList[index]
      let currentConfiguration = projectConfiguration
      if(window.location.pathname.includes("product")){
          currentConfiguration = new ProjectConfiguration(applicationConfig.clientId,applicationConfig?.clientName,0,configuration.product.productName)
      }
      let config = new customizerRenderConfig(currentConfiguration,cameraObj)
      let splitLimit = debugOptions.noOfSplit || 8
      config.renderConfig.setSplitLimit(splitLimit)
      // config.setConfigName(projectConfiguration.configName)
      await uploadRenderToS3(config).then(async uploadRenderData=>{
        if(uploadRenderData.data.error){
          // console.log(uploadRenderData)
          addRenderLog("Error in upload render " + uploadRenderData.data.error)
          errorCreateRenderAction("Error uplaoding render",loaderId)
          postCreateRenderActions(false)
          return
        }
        // Fetch the updated render from s3
        await waitFor(200)
        if(customizerConfig.currentRenderingType === "3d"){
          await updateArea360Image(config)
          setRenderTrueLocalStorage()
        }
        await waitFor(200)
        addRenderLog("Uploaded image for " + cameraObj.areaName)
        if(index === camerasList.length - 1){
          projectConfiguration.setIsRenderCreated(true)
          addRenderLog("Completed")
          postCreateRenderActions()
          await saveConfiguration(true,projectConfiguration)
          callBack(false)
        }
        // callBack()

        logger?.info("customizer","createrender",`Created render successfully`)

      }).catch(err=>{
        console.log(err)
        addRenderLog("Error in upload render " + err)
        errorCreateRenderAction(err,loaderId)
        postCreateRenderActions(false)
        logger.error("customizer","createrender",`Error in creating render`)
      })
    }
  }


  export async function updateConfiguration(isCreatedRender:boolean) {
    let configName = projectConfiguration.configName
    let isUpdate = true

    // let savedConfigFromDatabase = getFilteredArray(applicationConfig?.data?.customizerSavedConfigs,"config_name",configName)
    // if(savedConfigFromDatabase){
    //   isUpdate = false
    // }
    // isUpdate,isCreatedRender
    saveConfiguration(isUpdate,projectConfiguration)
  }

  export function saveDefaltConfiguration() {
    saveConfiguration(false,projectConfiguration)
  }

  function setRenderTrueLocalStorage() {
    let renders:any = getItemFromLocalStorage("renders")
    if(!renders){
      renders = {}
    }
    if(!renders[applicationConfig.projectId]){
      renders[applicationConfig.projectId] = {}
    }
    let renderAreas = {}
    projectConfiguration?.areasList.forEach(area=>{
      renderAreas[area.area_name] = false
    })
    renders[applicationConfig.projectId] = {
      isRenderCompleted : true,
      isConfigChanged : true,
      renderAreas:renderAreas
    }
    addItemToLocalStorage("renders",renders)
  }

  export function resetAreaRenderFlag() {
    let renders:any = getItemFromLocalStorage("renders")
    if(!renders){
      renders = {}
    }
    if(!renders[applicationConfig.projectId]){
      renders[applicationConfig.projectId] = {}
    }
    let renderAreas = {}
    projectConfiguration?.areasList.forEach(area=>{
      renderAreas[area.area_name] = true
    })
    renders[applicationConfig.projectId] = {
      isRenderCompleted : false,
      isConfigChanged : true,
      renderAreas:renderAreas
    }
    addItemToLocalStorage("renders",renders)
  }

  function getConfigChangedAreas(areasList:any) {
      return projectConfiguration?.areasList.filter(area=>{
        // if(area.area_name !== "Other"){
          if(projectConfiguration.getAreaObject(area.area_name)?.isConfigChanged){
            console.log(area.area_name,projectConfiguration.getAreaObject(area.area_name)?.isConfigChanged)
            return area
          }
        // }
      })
    return areasList
  }

  function checkIfGenerateShell() {
    let isGenerateShell = false
    projectConfiguration?.areasList.forEach(area=>{
      if(projectConfiguration.getAreaObject(area.area_name).isConfigChanged){
        isGenerateShell = true
      }
    })
  return isGenerateShell
}


  export function setIsConfigChangedTrue() {
    let renders:any = getItemFromLocalStorage("renders")
    if(!renders){
      renders = {}
    }
    if(!renders[applicationConfig.projectId]){
      renders[applicationConfig.projectId] = {}
    }
    if(renders[applicationConfig.projectId]){
    renders[applicationConfig.projectId] = {
      isRenderCompleted : false,
      isConfigChanged : true,
      renderAreas:{...renders[applicationConfig.projectId].renderAreas,[applicationConfig.areaName]:true}
    }
    }
    addItemToLocalStorage("renders",renders)
  }


  function errorCreateRenderAction(err:string,loaderId:string){
    showToast(err,2000,"error")
    hideComponentLoader(loaderId)
    hideCanvasLoader()
  }


  export function generateShell() {
   
    let timestamp = Number(new Date())
    let requestId = `${applicationConfig?.clientName}${timestamp}`
    return new Promise(async (resolve:any,reject:any)=>{
        // if(!getConfigChangedAreas(projectConfiguration?.areasList)?.length){
        //   console.log("Skip creating shell")
        //   resolve("Done")
        //   return
        // }
        let config = null
        let currentConfiguration = projectConfiguration
        if(window.location.pathname.includes("product")){
            currentConfiguration = new ProjectConfiguration(applicationConfig.clientId,applicationConfig?.clientName,0,configuration.product.productName)
        }
        config = new customizerRenderConfig(currentConfiguration,null)
        config.addAllAreasConfiguration(projectConfiguration)
        config.renderConfig.setRequestId(requestId)
        config.isProductRenderMode = projectConfiguration.isProductRenderMode
        config.setCreateBlend(true)
        // config = updateConfigurationWithWorldTransform(config)
        // await waitFor(200)
        console.log(config)
        await createShell(config).then(async (data)=>{
          await applicationConfig.awsConfig.pollForMessages(requestId,1)
          // console.log("Blend file created successfully",data)
          addRenderLog("Shell created successfully")
          resolve(data)
        }).catch(err=>{
          projectConfiguration.setIsCreatingRender(false)
          console.log("Error in creating blend file",err)
          addRenderLog("Error in creating shell " + err)
          reject(err)
        })
    })
  }


  function updateConfigurationWithWorldTransform(config:any) {
    let projectConfig = config.projectRenderConfig.projectConfiguration
    for (const areaName in projectConfig) {
      let areaConfiguration = projectConfig[areaName].areaConfiguration
      for (const originalProductName in areaConfiguration) {
        let productInstances = areaConfiguration[originalProductName]
        for (let productInstanceKey in productInstances) {
          let transform = areaConfiguration[originalProductName][productInstanceKey].transform 
          areaConfiguration[originalProductName][productInstanceKey].transform = {...transform}
          let object = scene.children.find(currChild => currChild.userData.productInstanceName && currChild.userData.productInstanceName ===  productInstanceKey)
          if(object){
            // console.log(productInstanceKey,object)
            var target = new Vector3()
            let rotation = new Quaternion()
            object.getWorldPosition(target)
            object.getWorldQuaternion(rotation)
            transform.rotation = {
              _x:rotation.x,
              _y:rotation.y,
              _z:rotation.z,
              _w:rotation.x,
            }
            transform.position = target
          }
        }
      }
    }
    return config
  }

  async function updateArea360Image(configuration:any) {
    let key = `360Images/${applicationConfig?.clientName}/${projectConfiguration.projectName}/${projectConfiguration.configName}_${projectConfiguration.configId}/${configuration.projectRenderConfig.cameras[0].areaName}0000.png`
    await deleteFromCache(key).then(data=>{
      hideComponentLoader("canvasLoader")
      // console.log(data)
      return data
    }).catch(err=>{
      console.log(err)
      hideComponentLoader("canvasLoader")
      return err
    })

    return
    
    await applicationConfig.awsConfig.getObject(key).then(async (data:any)=>{
      let bufferView = data.Body;
      let buffer = new Uint8Array(bufferView).buffer;
    
    }).catch((error:any)=>{
      console.log(error)
      hideComponentLoader("canvasLoader")
      return error
    })
  }

  export function getFormattedRenderConfig(currentConfigObject:any,materials:Array<any>,projectName:string,areaName:string){
    return getRenderConfig3D(projectName,areaName,materials)
  }

  function getRenderConfig2D(projectName:string,areaName:string,materials:any) {
    let key = getCurrentConfigurationKey(projectName,areaName)
    let configuration = getCurrentConfigurationFromLocalStorage(key)
    let filteredConfig:any = {}
    for (const config in configuration) {
      // if(config.includes(viewName)){
        filteredConfig[config] = configuration[config]
      // }
    }

    configuration = filteredConfig
  
    let formatedConfiguration:any = []

    for (const configkey in configuration) {
      let productName = getProductName(configkey)
      let productCategory = getCategoryName(configkey)
      let partName = getPartName(configkey)
      let currObj = configuration[configkey]

      //Add other materials information
      let materialId = currObj.materialId
      let materialDetails = null

      if(materialId){
        materialDetails = getObjectByParameter(materials,"id",Number(materialId))
      }


      let configObj:any = {
        collection_name:currObj.collectionName,
        company_name:currObj.companyName,
        material_type:currObj.materialType || "Default",
        hex_code:'',
        material_code:currObj.materialCode,
        part_name:partName,
        product_name:productName,
        category_name:productCategory,
        uv_scale:materialDetails?.uv_scale || 0,
        roughness:materialDetails?.roughness || 0,
        metalness:materialDetails?.metalness || 0,
        specular:materialDetails?.specular || 0,
        clearcoat:materialDetails?.clearcoat || 0,
        sheen:materialDetails?.sheen || 0,
        transmission:materialDetails?.transmission || 0,
        normal_strength:materialDetails?.normal_strength || 1,
      }
    
      formatedConfiguration.push(configObj)
    }

    disposeVariables([configuration,filteredConfig,configuration])
    return formatedConfiguration
  }

  function getRenderConfig3D(projectName:string,areaName:string,materials:any) {
    let configuration = projectConfiguration.projectConfiguration
    configuration = configuration[areaName].areaConfiguration
  
    let formatedConfiguration:any = []

    for (const productName in configuration) {
      let productConfig = configuration[productName]


      let finishConfig = productConfig.configuration

      let configObj = {
        configuration:[],
        transform:productConfig.transform,
        enabled:productConfig.enabled,
        product_name:productName,
        category_name:productConfig.categoryName,
        sub_category_name:productConfig.subCategoryName,
      }

      let partsConfig = []

      for(let partName in finishConfig){
        let currObj = finishConfig[partName]
        //Add other materials information
        let materialId = currObj.materialId
        let materialDetails = null

        if(materialId){
          materialDetails = getObjectByParameter(materials,"id",Number(materialId))
        }
        partsConfig.push({
          collection_name:currObj.collectionName,
          company_name:currObj.companyName,
          material_type:currObj.materialType || "Default",
          hex_code:'',
          material_code:currObj.materialCode,
          part_name:partName,
          uv_scale:materialDetails?.uv_scale || 0,
          roughness:materialDetails?.roughness || 0,
          metalness:materialDetails?.metalness || 0,
          specular:materialDetails?.specular || 0,
          clearcoat:materialDetails?.clearcoat || 0,
          sheen:materialDetails?.sheen || 0,
          transmission:materialDetails?.transmission || 0,
          normal_strength:materialDetails?.normal_strength || 1,
        })
      }
      configObj.configuration = partsConfig
      formatedConfiguration.push(configObj)
    }

    disposeVariables([configuration])
    return formatedConfiguration
  }



  
  export function updateConfigInDatabse(configObj:any,currentConfigObject:any,callBack:()=>void){
    showComponentLoader("canvasLoader")
    updateLoaderProgress("canvasLoader",1,1,"Saving")
    let timestamp = Number(new Date()); // current time as number
    let configName = currentConfigObject?.currConfigName
    if(!configName){
      configName = timestamp
    }

    let key = getCurrentConfigurationKey(currentConfigObject.projectName,currentConfigObject.areaName)
    let configuration = getCurrentConfigurationFromClass(currentConfigObject,key)


    let filteredConfig:any = {}
    for (const config in configuration) {
      if(config.includes(currentConfigObject?.viewName)){
        filteredConfig[config] = configuration[config]
      }
    }

    let data = {
      clientId:configObj.clientId,
      configName:configName,
      projectId:currentConfigObject?.projectId,
      areaId:currentConfigObject?.areaId,
      viewId:currentConfigObject?.viewId,
      configuration:filteredConfig,
    }

  updateVisualizerConfig(data)
    .then((response:any) => {
      // console.log(response)
      hideComponentLoader("canvasLoader")
      currentConfigObject?.setCurrentConfigName(configName)
      if(response.data.error){
        showToast(response.data.message,2000,"error")
        return
      }
      callBack()
      // setCheckSavedConfig(!checkSavedConfig)
    })
    .catch((error:any) => {
      console.log(error)
      hideComponentLoader("canvasLoader")
    });
  }

  export function getLatestConfigurationFromDatabase(projectId:number = applicationConfig.projectId) {
    let savedConfigFromDatabase = applicationConfig?.data?.customizerSavedConfigs
    if(savedConfigFromDatabase){
      savedConfigFromDatabase = savedConfigFromDatabase.filter(config=>config.client_id == applicationConfig.clientId && config.project_id == projectId)
      if(savedConfigFromDatabase.length){
        return savedConfigFromDatabase[savedConfigFromDatabase.length-1]
      }
    }
    return null
  }

  export function getProjectSavedConfigs(projectId:number = applicationConfig.projectId) {
    let savedConfigFromDatabase = applicationConfig?.data?.customizerSavedConfigs
    if(savedConfigFromDatabase){
      return savedConfigFromDatabase.filter(config=>config.client_id == applicationConfig.clientId && config.project_id == projectId)
    }
    return []
  }

  export function get2dRendersList(projectId:number = applicationConfig.projectId) {
    let savedConfigFromDatabase = applicationConfig?.data?.customizerSavedConfigs
    if(savedConfigFromDatabase){
      savedConfigFromDatabase = savedConfigFromDatabase.filter(config=>config.client_id == applicationConfig.clientId && config.project_id == projectId)
      if(savedConfigFromDatabase.length){
        return savedConfigFromDatabase
      }
    }
    return []
  }


export function downloadScene(model:any = null) {
  let sceneToBeExport = model || scene
  showComponentLoader("changeFinishLoader")
  exporter.parse(
    sceneToBeExport,
      // called when the gltf has been generated
      function ( gltf:any ) {
  
          // const output = JSON.stringify(gltf, null, 2);
          saveArrayBuffer(gltf, 'NewDemo.glb');
          hideComponentLoader("changeFinishLoader")

      },
      // called when there is an error in the generation
      options
  ); 
}

export function save(blob, filename) {
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export function saveString(text:any,filename:any) {
  save(new Blob([text], { type: 'text/plain' }), filename);
}


export function saveArrayBuffer(buffer:any, filename:string) {
  save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
}

export function getGlbFileFromArrayBuffer(group:any) {
  showComponentLoader("changeFinishLoader")
  return new Promise((resolve,reject)=>{
    exporter.parse(group,
      // called when the gltf has been generated
      function ( gltf:any ) {
          // const output = JSON.stringify(gltf, null, 2);
          let blob = new Blob([gltf], { type: 'application/octet-stream' })
          hideComponentLoader("changeFinishLoader")
          resolve(new File([blob],"group.glb"))
      },
      options
  ); 
  })
}

export function getGlbFromArrayBuffer(group:any) {
  showComponentLoader("changeFinishLoader")
  return new Promise((resolve,reject)=>{
    exporter.parse(group,
      // called when the gltf has been generated
      function ( gltf:any ) {
          // const output = JSON.stringify(gltf, null, 2);
          let blob = new Blob([gltf], { type: 'application/octet-stream' })
          hideComponentLoader("changeFinishLoader")
          resolve(new File([blob],"group.glb"))
      },
      options
  ); 
  })
}




export function getGltfFromCamera(currCamera:any) {
  return new Promise((resolve,reject)=>{
      exporter.parse(
          currCamera,
          async function ( gltf:any ) {
              const output = JSON.stringify(gltf, null, 2);
              resolve(output)
          },
          {
            binary:false 
          }
      );
  })
}