import $ from "jquery";
import React, { useEffect, useState } from "react";
import { addActiveClassOnMaterial, displayThumbnails, hideComponentLoader, removeActiveClassFromAllTextures, removeClassFromElement, showComponentLoader } from "../../../../utils/UI_methods/global";
import { createBounceEffectEvents } from "../../../../utils/UI_methods/ui_effects";
import AwsConfig from "../../../../utils/aws-sdk/AwsConfig";
import { untarAndSaveToCache } from "../../../../utils/cache/cache";
import { updateDeviceElementsVisibility } from "../../../../utils/customizein3d/common";
import { dragStart, getValuesFromMaterialType } from "../../../../utils/customizein3d/modules/helper";
import { addDragEventListener } from "../../../../utils/customizein3d/modules/raycasting";
import { isTouchDevice } from "../../../../utils/customizer/helper";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getObjectByParameter, getRandomString, waitFor } from "../../../../utils/methods";
import Button2 from "../../ui-components/buttons/Button2";
import DropDown from "../../ui-components/filters/DropDown";
import MaterialSearch from "../../ui-components/search/MaterialSearch";
import Loader from "../main-ui/Loader";
import Modal2 from "../Modals/Modal";
import FinishDetails from "../../../admin/products/FinishDetails";
import { STORE } from "../../../../utils/store/storeConfiguration";
  
  const Materials: React.FC<{
    materials:any
    action:(event:any)=>void
    moduleName:string
  }> = (props) => {
  
  
    let awsConfig = new AwsConfig()
  
    const[textureLoaderId] = useState("texturesLoader")
  
    const[materialTypesMaterials,setMaterialTypesMaterials]:any = useState([])
    const[materials,setMaterials]:any = useState([])
    const[isSearchMode,setIsSearchMode]:any = useState(false)
    const[collections,setCollections]:any = useState([])
    const[companies,setCompanies]:any = useState([])
    const[currSelectedMaterialType,setCurrSelectedMaterialType] = useState<string>("")
    const[currSelectedCompany,setCurrSelectedCompany] = useState<string>("")
    const[currSelectedCollection,setCurrCollectionName] = useState<string>("")
    const[refresh,setRefresh] = useState(Number(new Date()))
    const [isOpenFinishDetails,setisOpenFinishDetails] = useState<any>(null);
    const [currFinish,setCurrFinish] = useState<any>(null);
  
    // useEffect(()=>{
    //   addEventListenerOnImages(".finish-image")
    // },[materialTypesMaterials])
  
    useEffect(()=>{
      if(props.materials){
        let allMaterialTypes:any = getArrayOfDistinctValues(props.materials,"material_type")
        // allMaterialTypes.splice(allMaterialTypes.indexOf("Default"),1)
        setCurrSelectedMaterialType(allMaterialTypes[0])
      }
  
    },[props.materials])

    useEffect(()=>{
      if(materials.length){
        setTimeout(() => {
          addDragEventListener()
          updateDeviceElementsVisibility()
        }, 500);
      }
      removeActiveClassFromAll()
    },[materials])
  
  
  
    //Untar file for all collections
    useEffect(()=>{
      if(currSelectedMaterialType?.length){
        // let filteredMaterials = getFilteredArray(props.materials,"material_type",currSelectedMaterialType)
        setMaterialTypesMaterials(props.materials)
        let companyNames:Array<any> = getArrayOfDistinctValues(props.materials,"company_name")
        setCompanies(companyNames)
        if(companyNames.includes("Mittal")){
          setCurrSelectedCompany("Mittal")
        }else{
          setCurrSelectedCompany(companyNames[0])
        }
        removeActiveClassFromAll()
      }
    },[currSelectedMaterialType,refresh,props.materials])

    function getDefaultCollection(allMaterials:any,allCollections:any) {
      let result = allCollections[0]
      if(applicationConfig.clientName === "DashSquare"){
        let material = getObjectByParameter(allMaterials,"material_type","Leather")
        if(material){
          result = material.collection_name
        }
      }
      return result
    }

    //SET collectiosn
    useEffect(()=>{
      if(currSelectedCompany?.length && materialTypesMaterials.length){
        let filteredArray = getFilteredArray(materialTypesMaterials,"company_name",currSelectedCompany)
        let allCollections = getArrayOfDistinctValues(filteredArray,"collection_name")
        setCollections(allCollections)
        let collectionName:any = getDefaultCollection(filteredArray,allCollections) || "All"
        setCurrCollectionName(collectionName)
        STORE.currSelectedCompany = currSelectedCompany
      }
    },[currSelectedCompany,materialTypesMaterials])

    //set materials and load thumbnails
    useEffect(()=>{
      if(currSelectedCompany != "" && currSelectedCollection!= "" && materialTypesMaterials.length){
        let array = materialTypesMaterials.filter(material=> material.company_name === currSelectedCompany && material.collection_name === currSelectedCollection)
        setMaterials(array)
      }
    },[currSelectedCompany,currSelectedCollection,materialTypesMaterials])

    useEffect(()=>{
      if(materials.length){
        // setTimeout(() => {
          loadAndDisplayThumbails()
        // }, 500);
      }
    },[materials])



    async function loadAndDisplayThumbails(){
      showComponentLoader(null,"materials-loader")
      let key = awsConfig.getTarFileKey("thumbnails",{companyName:currSelectedCompany,collectionName:currSelectedCollection})
      await untarAndSaveToCache(awsConfig,`thumbnails/OVL/${currSelectedCompany}/${currSelectedCollection}`,key)
      displayThumbnails(textureLoaderId,null)
      await waitFor(3000)
      hideComponentLoader(textureLoaderId)
    }
  
  
    function filterMaterialsWithMaterialType(value:string){
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setCurrSelectedMaterialType(value)
    }
 
  
    function filterMaterialsWithCollection(value:string){
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setCurrCollectionName(value)
    }
  
    function filterMaterialsWithCompany(value:string){
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setCurrSelectedCompany(value)
    }
  
  
    

    function removeActiveClassFromAll(){
      removeActiveClassFromAllTextures()
      $(".texture-label-container").removeClass("--is-active")
    }

    function activateSearchMode(materials:Array<any>,searchString:string) {
      removeClassFromElement("searchWrapperMaterial","--is-active")
      setMaterials(materials)
      setIsSearchMode(true)
    }

    function disableSearchMode() {
       setIsSearchMode(false)
       setMaterials(props.materials)
       setRefresh(Number(new Date()))
       $(".material-search-input").val("")
    }

   
    return (
      <React.Fragment>
  
      <div className="materials-filters-wrapper ">

      <Loader 
        className="fade-background-loader materials-loader"
        id={textureLoaderId}
        data={{}}
        loaderType="dots"
      />

        <Modal2
          id=""
          heading="Finish Details"
          isOpen={isOpenFinishDetails} 
          classNames={"small-x-modal"}
          onDismiss={setisOpenFinishDetails}
          Content={()=>(
            <FinishDetails
            configObj={applicationConfig}
            currfinish={currFinish}
          />
          )}
          FooterContent={()=>(
            <></>
          )}
        >
           
      </Modal2>

      <div id="searchWrapperMaterial" className="full-height-width  material-wrapper-mobile bg-color-white" style={{position:"fixed",zIndex:10005}}>
        <MaterialSearch
            configObj={applicationConfig}
            currSelectedMaterialType={currSelectedMaterialType}
            activateSearchMode={activateSearchMode}
            filterMaterialWithMaterialType={filterMaterialsWithMaterialType}
            filterMaterialWithCollection={filterMaterialsWithCollection}
            idSuffix={"materialsPanelForMobile"}
        />

        <div className="bottom-middle ion-margin-bottom">
          <Button2
            configObj={{id:""}}
            action={()=>removeClassFromElement("searchWrapperMaterial","--is-active")}
            label={"Close"}
            fontAwesomeIcon={"fas fa-close color-black"}
            iconPos="left"
            classNames={"shadow-light bg-color-light"}
          />
        </div>
      </div>
      

      {/* <div className="bottom-right zindex100 hide-in-desktop" style={{bottom:"80px",right:"20px"}}>
            <Button2
                configObj={{id:""}}
                action={()=>openMaterialSearchHandle()}
                label={""}
                fontAwesomeIcon={"fas fa-search font-medium color-danger"}
                iconPos="left"
                classNames={"button-rounded button-large bg-color-primary"}
            />
      </div> */}

      {/* <div className="fade-background-loader top-left zindex100 full-height-width display-none" id="materialsWrapperBlocker"></div> */}
  
              
              {!applicationConfig.isLiveClient?
              <DropDown
                classNames="hide-in-desktop display-none"
                columns={1}
                values={getArrayOfDistinctValues(props.materials,"material_type")}
                currSelectedValue={currSelectedMaterialType}
                action={filterMaterialsWithMaterialType}
                label="MaterialType"
              />
              :null}

              {currSelectedMaterialType !== "Metal"?
              <div className="product-material-dropdown-wrapper  no-wrap display-flex-row space-between" style={{margin:"0.5rem 0"}}>

                <DropDown
                classNames=""
                columns={1}
                values={companies}
                currSelectedValue={currSelectedCompany}
                action={filterMaterialsWithCompany}
                label="Company"
                />
                <DropDown
                classNames=""
                columns={1}
                values={collections}
                currSelectedValue={currSelectedCollection}
                action={filterMaterialsWithCollection}
                label="Collections"
                />
                </div>
              :
              <></>
              }
                
              
              
     

         
  

          <div className="full-width materials-search-wrapper">
            <MaterialSearch
              configObj={applicationConfig}
              currSelectedMaterialType={currSelectedMaterialType}
              activateSearchMode={activateSearchMode}
              filterMaterialWithMaterialType={filterMaterialsWithMaterialType}
              filterMaterialWithCollection={filterMaterialsWithCollection}
              idSuffix={"materialsPanel"+getRandomString(5)}
            />
          </div>

          {isSearchMode?
            <div className="marginTop5">
              <Button2
                configObj={{id:""}}
                action={disableSearchMode}
                label={"go back"}
                fontAwesomeIcon={"fas fa-chevron-left color-danger"}
                iconPos="left"
                classNames={"shadow-light bg-color-light button-small"}
              />
            </div>
          :null}
          
          
   
        </div>

        
  
        <div id="materialsWrapper" className="materials-wrapper overflow-y-scroll display-flex-row ion-padding-bottom">
  
          {materials?.map((material: any, index: number) => {
            // let materialUrl = getMaterialsImageUrl(props.configObj?.BASE_URL_MODEL_VIEWER,material.company_name,material.collection_name,material.material_code)
            let defaultValues = getValuesFromMaterialType(material.material_type)
            return (
                <div className={`texture-label-container material-container finish-image-container border ${material.material_code.toLowerCase().includes("roughness")?" display-none":""}`}
                data-company-name={material.company_name}
                data-collection-name={material.collection_name}
                data-material-id={material.id}
                data-material-code={material.material_code}
                data-material-type={material.material_type}
                data-uv-scale={material.uv_scale}
                data-roughness-value={material.roughness}
                data-metalness-value={defaultValues.metalnessValue}
                data-transmission-value={material.transmission}
                data-specular-value={material.specular}
                data-clearcoat-value={material.clearcoat}
                data-sheen-value={material.sheen}
                id={`Finish_${material.company_name}_${material.collection_name}_${material.material_code}`}
                data-object-type="foreground"
                onDragStart={dragStart}
                draggable="false"
                onClick={(event) => {
                  props.action(event)
                  addActiveClassOnMaterial(event)
                }} key={index}
                >
                  {/* for mobile drag finish */}
               

              <div className="texture">
                <div className="texture-name-mobile bottom-middle center-aligned-column">{material.material_code}</div>
                  <img
                    className="texture-img"
                  />
              </div>

              <div className="top-right hide-in-mobile pointer-all" style={{padding:"0.7rem",zIndex:1}}>
                  <div className="pointer-all" >
                    <i className="fas fa-info-circle font-small color-white" onClick={(event)=>{{
                    event.preventDefault()
                    event.stopPropagation()
                    setCurrFinish(material)
                    setisOpenFinishDetails(true)}}}></i>
                  </div>
              </div>

              {/* Drag icon for mobile */}
              {props.moduleName==="customizein3d" && !isTouchDevice()?
                <div className="top-right padding0 display-none pointer-all zindex30 hide-in-desktop touch-device-element" >
                  <div className="finish-drag-icon pointer-all">
                    <i className="fas fa-grip-vertical font-medium color-dark"></i>
                  </div>
                </div>
                :null}

              <div className="texture-details">
                <div className="detail heading text-ellipsis">{material.material_code}</div>
                <div className="detail">Company: {material.company_name}</div>
                <div className="detail">Collections: {material.collection_name}</div>
              </div>
            </div>       
            );
          })}
        </div>
  
      </React.Fragment>
    );
  };
  
  export default Materials;
  